import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import LockIcon from '@material-ui/icons/Lock';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import TextField from "@material-ui/core/TextField";
import DialogSettingsProduct from "./DialogSettingsProduct";
import EditProduct from "../../AdminScreens/AdminHome/Dialog/Dialog_CategoriesProducts/EditProduct";

const axios = require('axios');
const url = localStorage.getItem('url');

class ListProduct extends Component {

    state = {
        settingsProductOpen: false,
        product_amount: 0,
        product_id: 0,
        amount_enabled: false,
        product_name: "",
        searchValue: ""
    }

    render() {
        return (
            <div>
                <DialogSettingsProduct open={this.state.settingsProductOpen} onClose={this.handleOpen}
                                       product_name={this.state.product_name} product_id={this.state.product_id}
                                       onSave={this.saveProduct}
                                       product_amount={this.state.product_amount}
                                       amount_enabled={this.state.amount_enabled}
                                       />
                <Dialog PaperProps={{style: {overflowY: 'scroll'}}} fullScreen={true} open={this.props.open}
                        onClose={this.close}>
                    <AppBar position="static" style={{margin: 0}}>
                        <Toolbar>
                            <IconButton edge={"start"} aria-label="edit" onClick={this.close}>
                                <Close fontSize="small" className={"text-white"}/>
                            </IconButton>
                            <Typography variant="h6" align={"center"} style={{flexGrow: 1}}>
                                Produktverwaltung
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent style={{overflowY: 'scroll', marginTop: "0.5rem", padding: "0.5rem"}}>
                        <TextField
                            autoFocus={true}
                            onChange={(e) => {
                                this.setState({searchValue: e.target.value})
                            }}
                            className={"w-100"}
                        />
                        <div className={"d-flex justify-content-center"}>
                            <List component="nav" className={"w-100"}>
                                {this.props.products && this.props.products.filter(value => value.name.toLowerCase().includes(this.state.searchValue)).map((c, k) => (
                                    <ListItem key={k} className={"my-auto"} button>
                                        <ListItemText primary={c.name}/>
                                        <ListItemSecondaryAction>
                                            {
                                                /*
                                                      <TextField
                                                inputProps={{min: 0, style: {width: "5rem", textAlign: "center"}}}
                                                type={"number"} name={"inventory_" + k} id={"inventory_" + k} onChange={this.textChange}
                                                placeholder={c.stored_amount}/>
                                                 */
                                            }
                                            <IconButton className={"ml-1"} edge="end" aria-label="delete"
                                                        onClick={() => this.setProduct(c)}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton className={"ml-1"} edge="end" aria-label="delete"
                                                        onClick={() => this.disableProduct(c)}>
                                                {parseInt(c.isEnabled) === 1 ? <LockIcon/> : <CheckIcon/>}
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>))}
                            </List>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.close} color="primary">
                            Schließen
                        </Button>
                        <Button color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    close = () =>{
        this.setState({
            searchValue:""
        })
        this.props.onClose();
    }

    setProduct = (product) => {
        //console.log(product)
        this.setState({
            product_amount: product.inventory,
            product_name: product.name,
            product_id: product.id,
            amount_enabled: parseInt(product.inventory) !== -1
        })
        this.handleOpen();
    }
    handleOpen = () => {
        this.setState({
            settingsProductOpen: !this.state.settingsProductOpen
        })
    }
    textChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    disableProduct = (product) => {
        const params = new URLSearchParams();
        params.append('product_id', product.id);
        axios({
            method: 'post',
            url: url + '?action=switchProductStatus',
            data: params
        }).then((res) => {
            this.props.changeProduct();
        });
    }
    resetProductDataState = () => {
        this.setState({
            product_amount: null,
            product_name: null,
            product_id: null,
            amount_enabled: null
        })
    }
    saveProduct = (product_id, product_amount, prop_amount, enabled, prop_enabled) => {
        if (prop_enabled && enabled) {
            if (!product_amount) {
                product_amount = prop_amount
            }
        }
        if (enabled === null && prop_enabled === false) {
            product_amount = "-1"
        }
        if (enabled === false && prop_enabled === true) {
            product_amount = "-1"
        }
        if (enabled === false && prop_enabled === false) {
            product_amount = "-1"
        }
        //console.log(product_id, product_price, product_amount)
        this.changeProduct(product_id, product_amount);
        this.handleOpen();
    }
    changeProduct = (id, amount) => {
        const params = new URLSearchParams();
        params.append('product_id', id);
        params.append('stored_amount', amount);
        axios({
            method: 'post',
            url: url + '?action=changeInventory',
            data: params
        }).then((res) => {
            this.props.changeProduct();
            this.resetProductDataState();
        });
    }
}

export default ListProduct;