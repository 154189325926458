import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Redirect} from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import "./SetOrderFieldProduct.css"
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "react-select";
import {HuePicker, MaterialPicker} from 'react-color';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox} from "@material-ui/core";

let url;

class SetOrderFieldProduct extends Component {

    state = {
        waiter_name: "",
        redirect: false,
        row: null,
        col: null,
        grid: [],
        grid_data: [],
        products: [],
        isOpen: false,
        editRow: 0,
        editCol: 0,
        loading: false,
        name_button: "",
        createGridButtons: "",
        selected_product: "",
        products_id: null,
        redirectToHome: false,
        use_all: false,
        selectedColour: "#ffffff",
    }

    componentDidMount() {
        url = "http://192.168.1.115/Backend/";
        let waitername = JSON.parse(localStorage.getItem('user')).username;
        let help = ""
        if (waitername !== undefined) {
            help = JSON.parse(localStorage.getItem('user')).username;
        }
        let array = this.props.products;
        if (array === undefined) {
            array = [];
        } else {
            this.setState({products: array})
        }
        this.setState({
            products: array,
            selected_product: array[0],
        })
        let arr = this.props.orderfield;
        this.ifGridExists(arr);
        this.setState({
            grid_data: this.props.orderfield,
            waiter_name: help
        });
        if (arr.field && arr.field.length !== 0) {
            this.createTable(arr.columns, arr.rows);
        }
    }

    deleteSelect = (i) => {
        let temp = this.state.selected_product
        temp.splice(i, 1)
        this.setState({selected_product: temp})
    }

    displayProdSelection = () => {
        let arr = []
        let selprod = this.state.selected_product
        let len;
        if (!selprod) {
            len = 0
        } else {
            len = selprod.length
        }
        if (!(len > -1)) {
            len = 0
        }
        for (let i = 0; i < len; i++) {
            arr.push(
                <div key={i}>
                    <Row>
                        <Col xs={7}>
                            <Select
                                id={"product" + selprod[i].id}
                                name={"product" + selprod[i].id}
                                className={"basic-single"}
                                classNamePrefix="select"
                                onChange={this.SelectChange}
                                value={selprod[i]}
                                isSearchable={true}
                                options={this.props.products}
                            />
                        </Col>
                        <Col xs={3}>
                            <DeleteIcon onClick={() => this.deleteSelect(i)}/>
                        </Col>
                    </Row>
                </div>
            )
        }
        arr.push(
            <div>
                <Row>
                    <Col xs={7}>
                        <Select
                            id={"product" + -1}
                            name={"product" + -1}
                            className={"basic-single"}
                            classNamePrefix="select"
                            onChange={this.addItem}
                            isSearchable={true}
                            options={this.props.products}
                            value={""}
                        />
                    </Col>
                    <Col xs={3}>
                    </Col>
                </Row>
            </div>
        )

        return arr
    }

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        if (this.state.loading) {
            return (<Backdrop open={true} docked="false">
                <CircularProgress color="secondary"/>
                <h2 className={"text-center ml-3"}>Loading...</h2>
            </Backdrop>);
        }
        return (
            <div className={"w-100 h-100 container"}>
                {this.state.createGridButtons}
                <Dialog aria-labelledby="simple-dialog-title" fullWidth={true} maxWidth={'sm'} maxHeight={'md'}
                        open={this.state.isOpen}>
                    <DialogTitle id="simple-dialog-title">Produkt einem Bestellfeld zuweisen</DialogTitle>
                    <DialogContent className={"height-dialog"}>
                        <label htmlFor="row">Name des Buttons</label>
                        <input
                            id={'name_button'}
                            className={'form-control'}
                            type={'text'}
                            autoFocus={true}
                            placeholder={'Name des Buttons'}
                            value={this.state.name_button}
                            onChange={this.textChange}
                            name={'name_button'}
                        />
                        <FormControlLabel
                            className={"mt-2"}
                            control={<Checkbox checked={this.state.use_all} value={this.state.use_all} name={"use_all"}
                                               id={"use_all"}
                                               onChange={this.handleChangeUseAll}/>}
                            label="Menü deaktivieren"
                            labelPlacement="end"
                        />
                        <Row>
                            <Col className={"col-7"}>
                                <HuePicker
                                    color={this.state.selectedColour}
                                    className={"mt-2 mb-3"}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                            </Col>
                            <Col className={"col-5"}>
                                <MaterialPicker
                                    color={this.state.selectedColour}
                                    className={"mt-2 mb-3 ml-3"}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                            </Col>
                        </Row>
                        <label htmlFor="product">Produkt auswählen</label>
                        {this.displayProdSelection()}


                        <div className={"height_select"}>

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={this.onSave} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className={"justify-content-center w-100 p-1 align-items-center"}>
                    <Row>
                        <Col id={"table_grid"}>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                        <Col>
                        </Col>
                        <Col>
                            <Button variant="danger" className={"m2 float-right"} onClick={this.deleteTable}>Delete
                                Grid</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    handleChangeUseAll = (e) => {
        this.setState({[e.target.name]: e.target.checked})
    }
    handleChangeComplete = (color, event) => {
        this.setState({selectedColour: color.hex});
    };
    textChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    };
    getAllProducts = () => {
        this.setState({loading: true})
        const axios = require('axios');

        axios({
            method: 'post',
            url: url + '?action=getAllProducts'
        }).then((res) => {
            let arr = res.data;
            if (arr === undefined) {
                arr = [];
            } else {
                this.setState({products: arr})
            }
            this.setState({
                products: arr,
                selected_product: arr[0],
                loading: false
            })
            this.getGrid();
        })
    }
    openDialog = (col, row) => {
        let help = this.searchInGridDataForEntry(col, row);
        let p_ids = [];
        let fieldname;
        let hex_code;
        if (help !== null) {
            p_ids = help.products;
            fieldname = help.fieldname;
            hex_code = help.hex_code;
        } else {
            p_ids = [];
            fieldname = "";
            hex_code = "#ffffff";
        }
        let selected_prd = []
        if (p_ids) {
            for (let i = 0; i < p_ids.length; i++) {
                selected_prd.push(this.findSelectedProductsById(p_ids[i].id))
            }
        }
        let use_all = false;
        if (help.use_all) {
            if (parseInt(help.use_all) === 1) {
                use_all = true;
            }
        }


        this.setState({
            isOpen: true,
            products_id: p_ids,
            selectedColour: hex_code,
            name_button: fieldname,
            use_all: use_all,
            editRow: parseInt(row),
            editCol: parseInt(col),
            selected_product: selected_prd
        })
    }
    findSelectedProductsById = (p_id) => {
        let products = this.state.products;
        for (let i = 0; i < products.length; i++) {
            if (parseInt(products[i].id) === parseInt(p_id)) {
                return products[i]
            }
        }
    }
    ifGridExists = (grid = null) => {
        let help;
        if (grid === null || !grid.field) {
            help = (
                <div className={"container text-center w-100"}>
                    <h3 className={"mb-5"}>Anzahl der Spalten und Reihen</h3>
                    <Row className={"justify-content-center align-items-center"}>
                        <Col>
                            <label htmlFor="col">Spalte</label>
                            <input
                                min="0"
                                id={'col'}
                                className={'form-control'}
                                type={'number'}
                                autoFocus={true}
                                placeholder={'Anzahl der Spalten'}
                                value={this.state.col}
                                onChange={this.textChange}
                                name={'col'}
                            />
                        </Col>
                        <Col>
                            <label htmlFor="row">Reihe</label>
                            <input
                                min="0"
                                id={'row'}
                                className={'form-control'}
                                type={'number'}
                                placeholder={'Anzahl der Reihen'}
                                value={this.state.row}
                                onChange={this.textChange}
                                name={'row'}
                            />
                        </Col>
                        <Col>
                            <button onClick={() => this.createTable(this.state.col, this.state.row, false, true)}>Feld
                                erstellen
                            </button>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            help = ""
        }
        this.setState({
            createGridButtons: help
        })
    }
    closeDialog = () => {
        this.setState({
            isOpen: false,
            editRow: 0,
            editCol: 0,
            products_id: null,
            use_all: false,
            selectedColour: "#ffffff",
            name_button: "",
            selected_product: []
        })
    }

    addItem = (item, e) => {
        let temp = this.state.selected_product
        if (temp.find(e => isSelected(e, item)) === undefined) {
            temp.push(item)
            this.setState({selected_product: temp});
        }

        function isSelected(product, item) {
            return product.id === item.id;
        }
    }

    SelectChange = (item, e) => {
        let ind = e.name.split("product")[1]
        let temp = this.state.selected_product
        if (parseInt(ind) !== -1) {
            ind = temp.findIndex(x => x.id === ind)
        } else {
            ind = temp.length + 1
        }

        if (temp.find(e => isSelected(e, item)) === undefined) {
            if (temp.length < ind) {
                temp.push(item)
            } else {
                temp[ind] = item
            }
            this.setState({selected_product: temp});
        }

        function isSelected(product, item) {
            return product.id === item.id;
        }

    }
    onSave = () => {
        let rows = document.getElementsByTagName("table")[0].rows;
        let row = rows[this.state.editRow];
        let cell = row.cells[this.state.editCol];
        cell.innerHtml = "";
        if (cell.childNodes.length !== 0) {
            cell.removeChild(cell.childNodes[0]);
        }
        let text = document.createTextNode(this.state.name_button);
        cell.appendChild(text);
        cell.style.backgroundColor = this.state.selectedColour
        if (this.state.selected_product.length >= 0) {
            let temp = []
            this.state.selected_product.forEach(item => temp.push(item.id));
            cell.id = JSON.stringify(temp)
        }
        this.saveGridInData(this.state.editCol, this.state.editRow)
        this.closeDialog();
        this.saveGrid();
    }
    saveGridAndBackHome = () => {
        this.saveGrid();
        this.backHome();
    }
    saveGrid = () => {
        let rows = document.getElementsByTagName("table")[0].rows;
        let rowsLength = rows.length;
        let grid = [];
        for (let i = 0; i < rowsLength; i++) {
            for (let j = 0; j < rows[i].cells.length; j++) {
                let col = rows[i].cells[j]
                let arr = this.state.grid_data;
                let col_id = [];
                if (col.id && col.id !== "[]") {
                    let temp = JSON.parse(col.id);
                    temp.forEach(tes => col_id.push(parseInt(getId(tes))))
                }
                let hex_code = "";
                if (col.style.backgroundColor) {
                    let r = col.style.backgroundColor.split("(")[1].split(", ")[0]
                    let g = col.style.backgroundColor.split("(")[1].split(", ")[1]
                    let b = col.style.backgroundColor.split("(")[1].split(", ")[2].split(")")[0]
                    hex_code = fullColorHex(r, g, b)
                } else {
                    hex_code = fullColorHex(255, 255, 255)
                }
                let useall = 0;
                if (arr) {
                    if (arr.field) {
                        if (arr.field.length > 0) {
                            let index = arr.field.findIndex(field => checkRowCol(i, j, field))
                            useall = arr.field[index].use_all
                        }
                    }
                }

                let help = {
                    "row": i,
                    "col": j,
                    "use_all": useall,
                    "product_ids": col_id,
                    "hex_code": hex_code,
                    "fieldname": col.textContent
                }
                grid.push(help)
            }
        }

        this.sendGrid(grid);

        function rgbToHex(rgb) {
            let hex = Number(rgb).toString(16);
            if (hex.length < 2) {
                hex = "0" + hex;
            }
            return hex;
        }

        function getId(item) {
            if (item.hasOwnProperty('id')) {
                return item.id;
            } else {
                return item;
            }
        }

        function checkRowCol(row, col, field) {
            return parseInt(field.row) === parseInt(row) && parseInt(field.column) === parseInt(col)
        }

        function fullColorHex(r, g, b) {
            let red = rgbToHex(r);
            let green = rgbToHex(g);
            let blue = rgbToHex(b);
            return "#" + red + green + blue;
        }
    }
    getGrid = () => {
        const axios = require('axios');
        axios({
            method: 'post',
            url: url + '?action=getOrderField',
        }).then((res) => {
        });
    }
    searchInGridDataForEntry = (col, row) => {
        let arr = this.state.grid_data;
        if (arr.length === 0 || (arr.rows === 0 && arr.columns === 0)) {
            return null;
        }
        for (let i = 0; i < arr.field.length; i++) {
            if (parseInt(arr.field[i].row) === parseInt(row) && parseInt(arr.field[i].column) === parseInt(col)) {
                return arr.field[i];
            }
        }
    }
    saveGridInData = (col, row) => {
        let arr = this.state.grid_data;
        //console.log("before", arr)
        let use_all = 0;
        let id = arr.field.findIndex(field => checkRowCol(row, col, field))
        arr.field[id].fieldname = this.state.name_button;

        if (this.state.selected_product.length > 0) {
            let temp = []
            this.state.selected_product.forEach(item => temp.push(item))
            arr.field[id].products = temp;
        } else {
            arr.field[id].products = null;
        }
        if (this.state.use_all) {
            use_all = 1;
        }
        arr.field[id].use_all = use_all;
        arr.field[id].hex_code = this.state.selectedColour;
        this.setState({
            grid_data: arr,
            use_all: false
        })

        function checkRowCol(row, col, field) {
            return parseInt(field.row) === parseInt(row) && parseInt(field.column) === parseInt(col)
        }

        //console.log("after", arr)
    }
    deleteTable = () => {
        let oldtable = document.getElementById("table_grid_layout");
        if (oldtable) {
            oldtable.remove()
        }
        this.setState({row: null, col: null, grid: [], grid_data: []})
        this.ifGridExists();
        //this.createTable(3, 3, false)
    }
    createTable = (col, row, withData = true, firstTime = false) => {
        let that = this;
        let div = document.getElementById("table_grid");
        let oldtable = document.getElementById("table_grid_layout");
        if (oldtable) {

            oldtable.remove()
        }
        if (div) {
            let lengthCol = col;
            let lengthRow = row;
            let table = document.createElement("table");
            table.setAttribute("class", "w-100");
            table.setAttribute("id", "table_grid_layout")
            for (let i = 0; i < lengthRow; i++) {
                let tr = document.createElement("tr");
                for (let j = 0; j < lengthCol; j++) {
                    let td = document.createElement("td");
                    td.onclick = () => that.openDialog(j, i);
                    tr.appendChild(td)

                }
                table.append(tr);
            }
            div.appendChild(table);
            if (withData) {
                this.fillGrid()
            }
            if (firstTime) {
                this.saveGrid();
            }
        }
        let grid_data = []
        if (this.state.grid_data.length > 0) {
            grid_data = this.state.grid_data;
        } else {
            grid_data = this.props.orderfield;
        }

        if (grid_data.rows === 0 && grid_data.columns === 0) {
            this.setState({
                redirectToHome: false,
                loading: true
            })
            this.saveGrid();
            this.setState({
                redirectToHome: false,
                loading: false,
                col: null,
                row: null
            })
            this.getGrid();
        }
    }
    fillGrid = () => {
        let data = []
        if (this.state.grid_data.length > 0) {
            data = this.state.grid_data;
        } else {
            data = this.props.orderfield;
        }
        let arr = document.getElementById("table_grid_layout");
        if (arr && arr.childNodes[0] && data.field) {
            for (let i = 0; i < data.field.length; i++) {
                let row = data.field[i].row;
                let col = data.field[i].column;
                if (arr.childNodes[row] && arr.childNodes[row].childNodes[col]) {
                    let td = arr.childNodes[row].childNodes[col];
                    if (data.field[i].fieldname !== null) {
                        let text = document.createTextNode(data.field[i].fieldname);
                        td.appendChild(text);

                    }
                    td.style.backgroundColor = data.field[i].hex_code;
                    if (data.field[i].products !== null || true) {
                        let ids = JSON.stringify(data.field[i].products)
                        td.id = ids
                    }
                }
            }
        }
    }

    sendGrid = (grid) => {
        const axios = require('axios');
        const params = new URLSearchParams();
        params.append('event_id', this.props.event.id)
        params.append('orderfield', JSON.stringify(grid));

        axios({
            method: 'post',
            url: url + '?action=setOrderField',
            data: params
        }).then((res) => {
            this.setState({
                grid_data: res.data
            })
            if (this.state.redirectToHome) {
                this.backHome();
            }
        })

    }

    backHome = () => {
        this.setState({
            redirectToLink: "/loggedin/Home/",
            redirect: true
        })
    }
}

export default SetOrderFieldProduct;