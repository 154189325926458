import React, {Component} from 'react';
import {CCol, CRow} from "@coreui/react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddProductToEvent from "../../Dialog/Dialog_CategoriesProducts/AddProductToEvent";
import EditProduct from "../../Dialog/Dialog_CategoriesProducts/EditProduct";
import {Visibility} from "@material-ui/icons";
import AddSubcategorieToCategorie from "../../Dialog/Dialog_CategoriesProducts/AddSubcategorieToCategorie";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Redirect} from "react-router-dom";
import EditSubcategorie from "../../Dialog/Dialog_CategoriesProducts/EditSubcategorie";
import Product from "../../Dialog/Dialog_CategoriesProducts/Product";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ConfirmDialog from "../../Dialog/Dialogs_user/ConfirmDialog";

let url;
const axios = require('axios');

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{position: 'absolute', top: 0, width: '100%'}}>
                <LinearProgress/>
            </div>
        </GridOverlay>
    );
}

class AddProductsToCategories extends Component {
    state = {
        redirect: false,
        redirectToLink: "",
        openAddProduct: false,
        openEditProduct: false,
        sub_selection: [],
        products: [],
        indexOfSub: 0,
        event: [],
        categories: [],
        selection: [],
        category: undefined,
        subcategory: [],
        loading: false,
        openAddCategories: false,
        openEditCategories: false,
        ip_address: "",
        printer_description: "",
        sub_name: "",
        sub_id: 0,
        product_name: "",
        product_amount: 9999,
        product_id: 0,
        product_price: 0,
        openProduct: false,
        extras: [],
        product: [],
        selectionModel: [],
        search_field_product: "",
        search_field_subCat: "",
        deleteSub_confirm: false,
        deleteProduct_confirm: false
    }

    componentDidMount() {
        url = localStorage.getItem('url');
        if (this.props.match.params.id) {
            this.getEventData(this.props.match.params.id);

        }
    }

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        return (
            <div>
                <AppBar position="static" className={"mb-3"} style={{margin: 0}}>
                    <Toolbar>
                        <IconButton edge={"start"} aria-label="edit" onClick={this.backHandler}>
                            <ArrowBackIcon fontSize={"large"} className={"text-white"}/>
                        </IconButton>
                        <Typography variant="h6" align={"center"} style={{flexGrow: 1}}>
                            Kategorienverwaltung
                        </Typography>
                    </Toolbar>
                </AppBar>
                <AddSubcategorieToCategorie open={this.state.openAddCategories} onSave={this.saveCategorie}
                                            onClose={this.handleAddCategoriesDialog}/>
                <EditSubcategorie subcategorie_name={this.state.sub_name} open={this.state.openEditCategories}
                                  onSave={this.saveEditCategorie} ip_address={this.state.ip_address}
                                  subcategorie_id={this.state.sub_id}
                                  printer_description={this.state.printer_description}
                                  onClose={this.handleEditCategoriesDialog}/>
                <EditProduct product_name={this.state.product_name} product_id={this.state.product_id}
                             product_amount={this.state.product_amount} product_price={this.state.product_price}
                             open={this.state.openEditProduct} onClose={this.handleEditProduct}
                             onSave={this.editProduct}/>
                <AddProductToEvent open={this.state.openAddProduct} onSave={this.saveProduct}
                                   onClose={this.handleAddDialog}/>
                <ConfirmDialog
                    title="Unterkategorie löschen?"
                    open={this.state.deleteSub_confirm}
                    setOpen={this.handleOpenSub}
                    onConfirm={this.deleteSub}
                    text={"Wollen sie wirklich diese Unterkategorie löschen?"}
                />
                <ConfirmDialog
                    title="Produkt löschen?"
                    open={this.state.deleteProduct_confirm}
                    setOpen={this.handleOpenProduct}
                    onConfirm={this.deleteProduct}
                    text={"Wollen sie wirklich dieses Produkt löschen?"}
                />
                {this.state.openProduct ?
                    <Product open={this.state.openProduct} onClose={this.openProduct} product={this.state.product}
                             extras={this.state.extras} event_id={this.state.event.id}
                             selectionModel={this.state.selectionModel} getExtra={this.getExtras}/> : null}


                {this.state.category ? (<div className={"m-3"}>
                        <CRow className={"d-flex align-items-start w-100"}>
                            <CCol>
                                <CCol>
                                    <div className="card">
                                        <div className="card-body">
                                            <div><TextField className={"m-1 w-100"} id="id" label="ID"
                                                            value={this.state.category.category.id}
                                                            disabled={true}
                                                            variant="outlined"/>
                                                <TextField className={"m-1 w-100"} id="categories_description"
                                                           value={this.state.category.category.description}
                                                           disabled={true}
                                                           label="Kategorie"
                                                           variant="outlined"/>
                                                <TextField className={"m-1 w-100"} id="event_description"
                                                           value={this.state.event.description}
                                                           disabled={true}
                                                           label="Event"
                                                           variant="outlined"/>
                                            </div>
                                        </div>
                                    </div>
                                </CCol>
                                <CCol>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className={"mb-5 pb-3 pt-3 no_select"}>
                                                <div style={{
                                                    padding: "0.2rem",
                                                    display: 'flex'
                                                }}>
                                                    <div style={{flexGrow: 1}}>
                                                        <CRow className={"ml-2"}>
                                                            <h2>Unterkategorien</h2>
                                                            <div className={"ml-2"}>
                                                                <IconButton aria-label="edit"
                                                                            onClick={this.handleAddCategoriesDialog}>
                                                                    <AddIcon className={"text-body"}/>
                                                                </IconButton>
                                                            </div>
                                                            <CCol style={{overflow: "hidden"}}>
                                                                <div style={{float: "right"}}>
                                                                    <TextField
                                                                        className={"mb-2 mt-2"}
                                                                        variant={"outlined"}
                                                                        id={"search_field_subCat"}
                                                                        name={"search_field_subCat"}
                                                                        value={this.state.search_field_subCat}
                                                                        onChange={this.textChange}
                                                                        label="Search"
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <SearchIcon/>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </div>
                                                            </CCol>
                                                        </CRow>
                                                        <DataGrid
                                                            rows={this.state.subcategories}
                                                            style={{overflow: "scroll"}}
                                                            columnBuffer={10}
                                                            density={"standard"}
                                                            scrollbarSize={10}
                                                            autoHeight={true}
                                                            filterModel={{
                                                                items: [
                                                                    {
                                                                        columnField: 'description',
                                                                        operatorValue: 'contains',
                                                                        value: this.state.search_field_subCat
                                                                    },
                                                                ],
                                                            }}
                                                            onRowSelected={(newSelection) => {
                                                                this.setState({
                                                                    sub_selection: newSelection
                                                                });
                                                            }}
                                                            columns={
                                                                [
                                                                    {
                                                                        field: 'id',
                                                                        headerName: 'ID',
                                                                        width: 70,
                                                                        headerAlign: "center",
                                                                    },
                                                                    {
                                                                        field: 'description',
                                                                        renderCell: (params) => (
                                                                            <Tooltip title={params.row.description}>
                                                                                <span
                                                                                    className="table-cell-trucate">{params.row.description}</span>
                                                                            </Tooltip>
                                                                        ),
                                                                        headerName: 'Name',
                                                                        width: 250,
                                                                        headerAlign: "center",
                                                                    },
                                                                    {
                                                                        field: 'string',
                                                                        width: 200,
                                                                        headerAlign: "center",
                                                                        align: "center",
                                                                        headerName: 'Löschen/Editieren',
                                                                        filterable: false,
                                                                        renderCell: () => (
                                                                            <div>
                                                                                <IconButton aria-label="edit"
                                                                                            onClick={this.setProductsBySub}>
                                                                                    <Visibility fontSize="small"
                                                                                                className={"text-body"}/>
                                                                                </IconButton>
                                                                                <IconButton aria-label="edit"
                                                                                            onClick={() => this.setToEditSubCategorie(this.state.sub_selection.data)}>
                                                                                    <EditIcon fontSize="small"
                                                                                              className={"text-body"}/>
                                                                                </IconButton>
                                                                                <IconButton aria-label="delete"
                                                                                            onClick={() => this.setOpenConfirmSub(this.state.sub_selection.data)}>
                                                                                    <DeleteIcon fontSize="small"
                                                                                                className={"text-body"}/>
                                                                                </IconButton>
                                                                            </div>
                                                                        ),
                                                                    },
                                                                ]
                                                            }
                                                            pageSize={5}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CCol>
                            </CCol>
                            <CCol sm={8}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className={"mb-5 pb-3 pt-3 no_select"}>
                                            <div style={{
                                                padding: "0.2rem",
                                                display: 'flex'
                                            }}>
                                                <div style={{flexGrow: 1}}>
                                                    <CRow className={"ml-2"}>
                                                        <h2>Produkte
                                                            von {this.state.subcategories.length !== 0 ? this.state.subcategories[this.state.indexOfSub].description : ""}</h2>
                                                        <div className={"ml-2"}>
                                                            <IconButton aria-label="edit"
                                                                        onClick={this.handleAddDialog}>
                                                                <AddIcon className={"text-body"}/>
                                                            </IconButton>
                                                        </div>
                                                        <CCol style={{overflow: "hidden"}}>
                                                            <div style={{float: "right"}}>
                                                                <TextField
                                                                    className={"mb-2 mt-2"}
                                                                    variant={"outlined"}
                                                                    id={"search_field_product"}
                                                                    name={"search_field_product"}
                                                                    value={this.state.search_field_product}
                                                                    onChange={this.textChange}
                                                                    label="Search"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <SearchIcon/>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </div>
                                                        </CCol>
                                                    </CRow>
                                                    <DataGrid
                                                        rows={this.state.products}
                                                        style={{overflow: "scroll"}}
                                                        columnBuffer={10}
                                                        density={"standard"}
                                                        scrollbarSize={10}
                                                        components={{
                                                            LoadingOverlay: CustomLoadingOverlay,
                                                        }}
                                                        autoHeight={true}
                                                        filterModel={{
                                                            items: [
                                                                {
                                                                    columnField: 'name',
                                                                    operatorValue: 'contains',
                                                                    value: this.state.search_field_product
                                                                },
                                                            ],
                                                        }}
                                                        loading={this.state.loading}
                                                        onRowSelected={(newSelection) => {
                                                            this.setState({
                                                                selection: newSelection
                                                            });
                                                        }}
                                                        columns={
                                                            [
                                                                {
                                                                    field: 'id',
                                                                    headerName: 'ID',
                                                                    width: 70,
                                                                    headerAlign: "center",
                                                                },
                                                                {
                                                                    field: 'name',
                                                                    renderCell: (params) => (
                                                                        <Tooltip title={params.row.name}>
                                                                            <span
                                                                                className="table-cell-trucate">{params.row.name}</span>
                                                                        </Tooltip>
                                                                    ),
                                                                    headerName: 'Name',
                                                                    width: 150,
                                                                    headerAlign: "center",
                                                                },
                                                                {
                                                                    field: 'string',
                                                                    width: 200,
                                                                    headerAlign: "center",
                                                                    align: "center",
                                                                    headerName: 'Löschen/Editieren',
                                                                    filterable: false,
                                                                    renderCell: () => (
                                                                        <div>
                                                                            <IconButton aria-label="edit"
                                                                                        onClick={this.openProduct}>
                                                                                <Visibility fontSize="small"
                                                                                            className={"text-body"}/>
                                                                            </IconButton>
                                                                            <IconButton aria-label="edit"
                                                                                        onClick={() => this.setProductForEditProduct(this.state.selection.data)}>
                                                                                <EditIcon fontSize="small"
                                                                                          className={"text-body"}/>
                                                                            </IconButton>
                                                                            <IconButton aria-label="delete"
                                                                                        onClick={() => this.setOpenConfirmProduct(this.state.selection.data)}>
                                                                                <DeleteIcon fontSize="small"
                                                                                            className={"text-body"}/>
                                                                            </IconButton>
                                                                        </div>
                                                                    ),
                                                                },
                                                            ]
                                                        }
                                                        pageSize={10}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                    </div>
                ) : null}
            </div>

        );
    }

    textChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    setOpenConfirmSub = (data) => {
        this.setState({
            sub_id: data.id
        })
        this.handleOpenSub();
    }
    setOpenConfirmProduct = (data) => {
        this.setState({
            product_id: data.id,
        })
        this.handleOpenProduct();
    }
    handleOpenSub = () => {
        this.setState({
            deleteSub_confirm: !this.state.deleteSub_confirm
        })
    }
    handleOpenProduct = () => {
        this.setState({
            deleteProduct_confirm: !this.state.deleteProduct_confirm
        })
    }
    deleteSub = () => {
        const params = new URLSearchParams();
        params.append("subcategory_id", this.state.sub_id);

        axios({
            method: 'post',
            url: url + '?action=deleteSubcategories',
            data: params
        }).then((res) => {
            this.getEventData(this.props.match.params.id);
        });
    }
    deleteProduct = () => {
        const params = new URLSearchParams();
        params.append("product_id", this.state.product_id);

        axios({
            method: 'post',
            url: url + '?action=deleteProduct',
            data: params
        }).then((res) => {
            this.getEventData(this.props.match.params.id);
        });
    }
    getExtras = (id) => {
        const
            params = new URLSearchParams();
        params.append(
            'event_id',
            id ? id : this.state.event.id
        )
        ;
        axios({
                method: 'post',
                url: url + '?action=getAllExtrasByEvent',
                data: params
            }
        ).then((res) => {
            this.setState({
                extras: res.data
            })
        });
    }

    backHandler = () => {
        // console.log(this.state.event.id)
        this.setState({
            redirectToLink: "/loggedin/AdminHome/Event/" + this.state.event.id,
            redirect: true
        })
    }
    setProductsBySub = () => {
        this.setState({
            loading: true
        })
        this.getProductsBySub()
        this.setState({
            loading: false
        })
    }
    setProductForEditProduct = (data) => {
        this.setState({
            product_name: data.name,
            product_amount: data.stored_amount,
            product_id: data.id,
            product_price: data.price
        })
        this.handleEditProduct();
    }
    handleEditProduct = () => {
        this.setState({
            openEditProduct: !this.state.openEditProduct
        })
    }
    editProduct = (id, name, prop_name, price, prop_price, amount, prop_amount, bol) => {
        if (!name) {
            name = prop_name
        }
        if (!price) {
            price = prop_price
        }
        if (!amount) {
            amount = prop_amount
        }
        if (!bol) {
            amount = "-1"
        }
        // console.log("edit product", id + " " + name + " " + price + " " + amount)
        const params = new URLSearchParams();
        params.append('event_id', this.state.event.id);
        params.append('product_id', id);
        params.append('name', name);
        params.append('price', price);
        params.append('storedAmount', amount);
        params.append("isEnabled", "1")
        axios({
            method: 'post',
            url: url + '?action=editProduct',
            data: params
        }).then((res) => {
            this.reloadData(this.props.match.params.id);
            this.handleEditProduct();
            //console.log(res.data)
        });

    }
    getProductsBySub = () => {
        let categories = this.state.categories;
        let data = categories.subcategories.find(cat => cat.subcategory.id === this.state.sub_selection.data.id);
        let index = categories.subcategories.findIndex(cat => cat.subcategory.id === this.state.sub_selection.data.id);
        let products = []
        if (data.products) {
            products = data.products;
        }
        this.setState({
            products: products,
            subcategory: data.subcategory,
            indexOfSub: index
        })
    }
    saveCategorie = (name, ip, printer_desc) => {
        const params = new URLSearchParams();
        params.append('event_id', this.state.event.id);
        params.append('categories_id', this.props.match.params.id);
        params.append('description', name);
        params.append('ipAddress', ip);
        params.append('printer_description', printer_desc);
        axios({
            method: 'post',
            url: url + '?action=addSubcategoriesAndPrinter',
            data: params
        }).then((res) => {
            this.reloadData(this.props.match.params.id);
            this.handleAddCategoriesDialog();
            // console.log(res.data)
        });
    }
    saveEditCategorie = (id, name, propname, ip_adress, prop_ip, desc, prop_desc) => {
        if (!name) {
            name = propname
        }
        if (!ip_adress) {
            ip_adress = prop_ip
        }
        if (!desc) {
            desc = prop_desc
        }
        // console.log(id, name, ip_adress, desc)

        const params = new URLSearchParams();
        params.append('subcategory_id', id)
        params.append("description", name);
        params.append("categories_id", this.state.categories.id)
        params.append("ipAddress", ip_adress);
        params.append("printer_description", desc);
        params.append("event_id", this.state.event.id)
        axios({
            method: 'post',
            url: url + '?action=editSubcategories',
            data: params
        }).then((res) => {
            this.reloadData(this.props.match.params.id);
            // console.log(res.data)
        });

    }


    saveProduct = (product_name, product_price, amount_enabled, product_amount) => {
        const params = new URLSearchParams();
        params.append('event_id', this.state.event.id)
        params.append("isEnabled", "1");
        params.append("name", product_name);
        params.append("price", product_price);
        if (amount_enabled) {
            params.append("storedAmount", product_amount);
        } else {
            params.append("storedAmount", "-1");
        }
        params.append("sizesId", "1");
        params.append("subcategoriesId", this.state.subcategory.id);

        axios({
            method: 'post',
            url: url + '?action=addProduct',
            data: params
        }).then((res) => {
            this.reloadData(this.props.match.params.id);
            this.handleAddDialog();
            // console.log(res.data)
        });
    }
    handleAddCategoriesDialog = () => {
        this.setState({
            openAddCategories: !this.state.openAddCategories
        })
    }
    handleEditCategoriesDialog = () => {
        this.setState({
            openEditCategories: !this.state.openEditCategories
        })
    }

    handleAddDialog = () => {
        this.setState({
            openAddProduct: !this.state.openAddProduct
        })
    }
    setToEditSubCategorie = (data) => {
        // console.log(data)
        this.setState({
            ip_address: data.printer.ip_address,
            printer_description: data.printer.description,
            sub_name: data.description,
            sub_id: data.id
        })
        this.handleEditCategoriesDialog();
    }


    setPageValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    reloadData = (id) => {
        const params = new URLSearchParams();
        params.append("category_id", id);

        axios({
            method: 'post',
            url: url + '?action=getCategoriesByIdWithSubcategoriesAndProductsAndPrinters',
            data: params
        }).then((res) => {
            //console.log(res.data)
            let products = []
            let sub = [];
            if (res.data.category.subcategories[this.state.indexOfSub].products) {
                products = res.data.category.subcategories[this.state.indexOfSub].products;
            }
            if (res.data.category.subcategories[this.state.indexOfSub].subcategory) {
                sub = res.data.category.subcategories[this.state.indexOfSub].subcategory
            }
            this.setState({
                category: res.data,
                event: res.data.event,
                categories: res.data.category,
                subcategories: res.data.subcategories,
                products: products,
                subcategory: sub,
            })
        });
    }
    getEventData = (id) => {
        const params = new URLSearchParams();
        params.append("category_id", id);

        axios({
            method: 'post',
            url: url + '?action=getCategoriesByIdWithSubcategoriesAndProductsAndPrinters',
            data: params
        }).then((res) => {
            //console.log(res.data)
            if (res.data.subcategories) {
                let products = []
                let sub = [];
                if (res.data.category.subcategories[this.state.indexOfSub].products) {
                    products = res.data.category.subcategories[this.state.indexOfSub].products;
                }
                if (res.data.category.subcategories[this.state.indexOfSub].subcategory) {
                    sub = res.data.category.subcategories[this.state.indexOfSub].subcategory
                }
                this.setState({
                    category: res.data,
                    event: res.data.event,
                    categories: res.data.category,
                    subcategories: res.data.subcategories,
                    products: products,
                    subcategory: sub,
                })
            } else {
                this.setState({
                    category: res.data,
                    event: res.data.event,
                    categories: res.data.category,
                    subcategories: [],
                    products: [],
                    subcategory: []
                })
            }
            this.getExtras(res.data.event.id)
        });


    }
    openProduct = () => {
        const params = new URLSearchParams();
        params.append('productId', this.state.selection.data.id);
        axios({
            method: 'post',
            url: url + '?action=getExtraIdByProduct',
            data: params
        }).then((res) => {
            let arr = [];
            //console.log(res.data)

            if (res.data) {
                res.data.forEach(item => arr.push(parseInt(item)))
            }

            this.setState({
                openProduct: !this.state.openProduct,
                product: this.state.selection.data,
                selectionModel: arr,
            })

        });
    }
    refreshProduct = (product) => {
        const params = new URLSearchParams();
        params.append('productId', product.id);
        axios({
            method: 'post',
            url: url + '?action=getExtraIdByProduct',
            data: params
        }).then((res) => {
            let arr = [];

            if (res.data) {
                res.data.forEach(item => arr.push(parseInt(item)))
            }
            this.setState({
                product: product,
                selectionModel: arr,
            })

        });
    }
}

export default AddProductsToCategories;