import React, {Component} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog, Divider, IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

class DialogWaiterDetailed extends Component {
    state = {
        expanded: ""
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.open}
                        onClose={this.props.onClose}
                        maxWidth={"xs"}
                        fullWidth={true}
                >
                    <DialogTitle id="simple-dialog-title">Kellnerdetails</DialogTitle>
                    <DialogContent>
                        {this.props.waiterData && this.props.waiterData.length !== 0 ? (
                                <div>
                                    <Accordion disabled={true} style={{backgroundColor: "#fff"}}>
                                        <AccordionSummary
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"
                                            style={{opacity: 1}}
                                        >
                                            <Typography
                                                style={{color: "#000"}}>Einahmen: {this.props.waiterData.totalIncome + " €"}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Einahmen: 120€
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion disabled={true} style={{backgroundColor: "#fff"}}>
                                        <AccordionSummary
                                            aria-controls="panel4a-content"
                                            id="panel4a-header"
                                            style={{opacity: 1}}
                                        >
                                            <Typography
                                                style={{color: "#000"}}>Bestellungen: {this.props.waiterData.user_orders}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                Bestellungen: 120
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={this.state.expanded === 'panel1'}
                                               onChange={() => this.handleChange('panel1')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{opacity: 1}}
                                        >
                                            <Typography style={{color: "#000"}}>Tische</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                this.props.detailedWaiterTables &&
                                                <List subheader={<li/>} style={{width: "100%"}}
                                                      className={"padding_toScroll"}>
                                                    <Divider/>
                                                    {
                                                        this.props.detailedWaiterTables.map((c, k) => (
                                                            <div key={k}>
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={c ? "Tischnr. " + c.number : ""}
                                                                    />
                                                                    <div style={{position: "absolute", right: 0}}>
                                                                        <Typography>{c ? c.amount + " Bestellungen" : ""}</Typography>
                                                                    </div>
                                                                </ListItem>
                                                                <Divider/>
                                                            </div>
                                                        ))
                                                    }
                                                </List>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={this.state.expanded === 'panel2'}
                                               onChange={() => this.handleChange('panel2')}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            style={{opacity: 1}}
                                        >
                                            <Typography style={{color: "#000"}}>Produkte</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                this.props.detailedWaiterProducts &&
                                                <List subheader={<li/>} style={{width: "100%"}}
                                                      className={"padding_toScroll"}>
                                                    <Divider/>
                                                    {
                                                        this.props.detailedWaiterProducts.map((c, k) => (
                                                            <div key={k}>
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={c ? c.product.name : ""}
                                                                    />
                                                                    <ListItemSecondaryAction>
                                                                        <Typography>{c ? c.amount + " Stk." : ""}</Typography>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                                <Divider/>
                                                            </div>
                                                        ))
                                                    }
                                                </List>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )
                            : <CircularProgress color="secondary"/>

                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.setState({
            expanded: ""
        }, this.props.onClose());
    }

    handleChange = (panel) => {
        if (panel === this.state.expanded) {
            panel = ""
        }
        this.setState({
            expanded: panel
        })
    };
}

export default DialogWaiterDetailed;