import React, {Component} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chart from 'chart.js';
import "chartjs-plugin-labels"
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "../eventAnalysis.css"
import {CardHeader} from "@material-ui/core";
import {CCol, CRow, CWidgetDropdown, CWidgetIcon} from "@coreui/react";
import ChartLineSimple from "../../../charts/ChartLineSimple";
import CIcon from "@coreui/icons-react";
import {CChartBar} from "@coreui/react-chartjs";


Chart.plugins.unregister(ChartDataLabels);

class HomeEvent extends Component {
    render() {

        if (!this.props.show) {
            return (<div/>);
        }
        return (
            <div>
                <div className={"pb-2 m-1 mr-3 ml-3 mt-3 "}>
                    <Card className={"card_border m-05  m-1"}>
                        <CardHeader className={"pb-0 ml-2"} title={"Kellner Übersicht"}/>
                        <CardContent className={"pt-0 pb-0"}>
                            <CRow className={"m-1"}>
                                {this.props.data.map((c, k) => (
                                    <CCol key={k} sm>
                                        <CWidgetDropdown
                                            className={"bg_lightblue"}
                                            header={(c.orderamount).toString()}
                                            style={{"fontSize": "1rem"}}
                                            text={(c.user.name).toString()}
                                            onClick={() => this.props.openDetailedWaiter(c.user.id)}
                                            footerSlot={
                                                <ChartLineSimple
                                                    className="mt-3"
                                                    style={{height: '70px'}}
                                                    backgroundColor="rgba(255,255,255,.2)"
                                                    dataPoints={this.generate7RandomNumbers()}
                                                    options={{
                                                        elements: {line: {borderWidth: 2.5}},
                                                        tooltips: {enabled: false},
                                                        hover: {mode: null}
                                                    }}
                                                />
                                            }
                                        >
                                        </CWidgetDropdown>
                                    </CCol>
                                ))}
                            </CRow>
                        </CardContent>
                    </Card>
                </div>
                <CRow className={"m-1 mt-2"}>
                    <CCol sm>
                        <Card className={"card_border m-05 m-1"}>
                            <CardHeader className={"pb-0 ml-4"} title={"Bestellten Produkte"}/>
                            <CardContent className={"pt-0"}>
                                <CChartBar
                                    datasets={this.props.data_graph_product.datasets}
                                    labels={this.props.data_graph_product.labels}
                                    options={this.props.options_graph_product}/>
                            </CardContent>
                        </Card>
                    </CCol>
                    <CCol sm>
                        <Card className={"card_border m-05 m-1"}>
                            <CardHeader className={"pb-0 ml-4"} title={"Bestellungen pro Tag"}/>
                            <CardContent className={"pt-0"}>
                                <CChartBar datasets={this.props.data_graph_order.datasets}
                                           labels={this.props.data_graph_order.labels}
                                           options={this.props.options_graph_product}/>
                            </CardContent>
                        </Card>
                    </CCol>
                </CRow>
                <CRow className={"m-1 mt-2 mt-7"}>
                    <CCol sm className={"rounded_own"}>
                        <CWidgetIcon style={{fontSize: "2rem"}} className={"pr-2 "}
                                     text={"" + this.props.comp_income + "€"}
                                     header="Gesamteinnahmen durch Bestellungen"
                                     color="primary"
                                     iconPadding={false}>
                            <CIcon width={30}/>
                        </CWidgetIcon>
                    </CCol>
                    <CCol sm>
                        <CWidgetIcon style={{fontSize: "2rem"}} className={"pr-2 "}
                                     text={"" + this.props.amount_orders} header="Anzahl der Bestellungen"
                                     color={"success"}
                                     iconPadding={false}>
                            <CIcon width={30}/>
                        </CWidgetIcon>
                    </CCol>
                    <CCol sm>

                        <CWidgetIcon style={{fontSize: "2rem"}} className={"pr-2 "}
                                     text={"" + this.props.amount_products} header="Anzahl der bestellten Produkte"
                                     color="info"
                                     iconPadding={false}>
                            <CIcon width={30}/>
                        </CWidgetIcon>
                    </CCol>
                </CRow>
            </div>
        );
    }

    generate7RandomNumbers = () => {
        let arr = [];
        for (let i = 0; i < 7; i++) {
            arr.push(Math.random());
        }
        return arr;
    }

}

export default HomeEvent;