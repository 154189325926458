import React, {Component} from 'react';

class Products extends Component {
    render() {
        if (!this.props.open) {
            return (<div/>)
        }
        return (
            <div className={"w-100 h-100 container"}>
                <div className={"mb-5 pl-2 ml-5 pb-3 pt-3 w-100 no_select"}>
                    <h1>Produkte</h1>
                </div>
            </div>
        );
    }
}

export default Products;