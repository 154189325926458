import React, {Component} from 'react';
import {Dialog as Dialog_mat} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

class DialogDisplayDetailData extends Component {
    render() {
        if (this.props.length !== 0 && this.props.detailedInfo.length !== 0) {
            return (
                <div>
                    <Dialog_mat aria-labelledby="simple-dialog-title" maxheight={'md'}
                                open={this.props.open} onClose={this.props.onClose}>
                        <DialogTitle id="simple-dialog-title">{"Detallierte Ansicht der Gesamteinnahmen"}</DialogTitle>
                        <DialogContent className={"height-dialog"}>
                            <div className={"w-100 text-center d-flex justify-content-center"}>
                                <List>
                                    {this.props.detailedInfo.map((c, k) => (
                                        <ListItem>
                                            <ListItemText
                                                primary={c.product.name + ": " + c.price + "€"}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.props.onClose} color="primary">
                                Schließen
                            </Button>
                        </DialogActions>
                    </Dialog_mat>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default DialogDisplayDetailData;