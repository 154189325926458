import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogContentText} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


class DialogNewPassword extends Component {
    state = {
        openSnackbar: false,
    }

    render() {
        return (<div>
                <Dialog open={this.props.open} onClose={this.props.onClose}>
                    <DialogTitle id="simple-dialog-title">Neues Passwort</DialogTitle>
                    <DialogContentText>
                        <CopyToClipboard text={this.props.newPassword}
                                         onCopy={() => this.setState({openSnackbar: true})}>
                            <TextField inputProps={{style: {textAlign: 'center'}}} disabled={true}
                                       placeholder={"Neues Password"} name={"newPassword"} id={"newPassword"}
                                       value={this.props.newPassword}/>
                        </CopyToClipboard>
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar open={this.state.openSnackbar} autoHideDuration={3000} onClose={this.handleClose}>
                    <Alert variant="filled" onClose={this.handleClose} severity="success">
                        Kopiert!!
                    </Alert>
                </Snackbar>
            </div>

        );
    }

    handleClose = () => {
        this.setState({
            openSnackbar: false,
        })
    }
}


DialogNewPassword.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    newPassword: PropTypes.string.isRequired,
};

export default DialogNewPassword;