import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import {Close} from '@material-ui/icons';
import {DataGrid} from "@material-ui/data-grid";
import AddIcon from '@material-ui/icons/Add';
import Typography from "@material-ui/core/Typography";
import {CCol, CRow} from "@coreui/react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import AddExtra from "./AddExtra";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../Dialogs_user/ConfirmDialog";

let url = "http://192.168.1.115/Backend/";
const axios = require('axios');

class Product extends Component {
    state = {
        selection: null,
        openExtra: false,
        search_field: "",
        dialog_extras_confirm: false,
        extra_id: 0,
        selectionExtra: null
    }

    componentDidMount() {
        this.setState({
            selection: this.formatSelection(this.props.selectionModel)
        })
    }

    formatSelection = (selecIds) => {
        let arr = []
        for (let i = 0; i < selecIds.length; i++) {
            arr.push(String(selecIds[i]))
        }
        return arr
    }


    updateSelection = (newSelection) => {

        this.setState({selection: newSelection.selectionModel});
    }

    render() {
        if (this.state.selection !== null) {
            return (
                <div>
                    <AddExtra open={this.state.openExtra} onSave={this.onSaveAddExtra} onClose={this.handleAddExtra}/>
                    <ConfirmDialog
                        title="Extra löschen?"
                        open={this.state.dialog_extras_confirm}
                        setOpen={this.handleConfirmExtras}
                        onConfirm={this.deleteExtra}
                        text={"Wollen sie wirklich dieses Extra löschen?"}
                    />
                    <Dialog PaperProps={{style: {overflowY: 'scroll'}}} fullScreen={true} open={this.props.open}>
                        <AppBar position="static" style={{margin: 0}}>
                            <Toolbar>
                                <IconButton edge={"start"} aria-label="edit" onClick={this.getSelectedExtras}>
                                    <Close fontSize="small"/>
                                </IconButton>
                                <Typography variant="h6" align={"center"} style={{flexGrow: 1}}>
                                    Produktverwaltung
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent style={{overflowY: 'scroll', marginTop: "1rem", padding: "0.5rem"}}>
                            <div>
                                <CRow className={"d-flex align-items-start w-100"}>
                                    <CCol>
                                        <div className="card">
                                            <div className="card-body">
                                                {this.getProductData()}
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm={8}>
                                        <div className="card ">
                                            <div className="card-body">
                                                <div style={{
                                                    padding: "0.2rem",
                                                    display: 'flex'
                                                }}>
                                                    <div style={{flexGrow: 1}}>
                                                        <CRow className={"ml-2"}>
                                                            <h2>Extras</h2>
                                                            <div className={"ml-2"}>
                                                                <IconButton aria-label="edit"
                                                                            onClick={this.handleAddExtra}>
                                                                    <AddIcon className={"text-body"}/>
                                                                </IconButton>
                                                            </div>
                                                            <CCol style={{overflow: "hidden"}}>
                                                                <div style={{float: "right"}}>
                                                                    <TextField
                                                                        className={"mb-2 mt-2"}
                                                                        variant={"outlined"}
                                                                        id={"search_field"}
                                                                        name={"search_field"}
                                                                        value={this.state.search_field}
                                                                        onChange={this.textChange}
                                                                        label="Search"
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <SearchIcon/>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </div>
                                                            </CCol>
                                                        </CRow>
                                                        <DataGrid
                                                            rows={this.props.extras}
                                                            style={{overflow: "scroll"}}
                                                            columnBuffer={10}
                                                            density={"compact"}
                                                            scrollbarSize={10}
                                                            autoHeight={true}
                                                            selectionModel={this.state.selection}
                                                            checkboxSelection={true}
                                                            onRowSelected={(newSelection) => {
                                                                this.setState({
                                                                    selectionExtra: newSelection
                                                                });
                                                            }}
                                                            filterModel={{
                                                                items: [
                                                                    {
                                                                        columnField: 'description',
                                                                        operatorValue: 'contains',
                                                                        value: this.state.search_field
                                                                    },
                                                                ],
                                                            }}
                                                            onSelectionModelChange={this.updateSelection}
                                                            columns={
                                                                [
                                                                    {
                                                                        field: 'id',
                                                                        headerName: 'ID',
                                                                        width: 70,
                                                                        headerAlign: "center",
                                                                        filterable: true,
                                                                    },
                                                                    {
                                                                        field: 'description',
                                                                        headerName: 'Name',
                                                                        width: 160,
                                                                        renderCell: (params) =>  (
                                                                            <Tooltip title={params.row.description} >
                                                                                <span className="table-cell-trucate">{params.row.description}</span>
                                                                            </Tooltip>
                                                                        ),
                                                                        headerAlign: "center",
                                                                        filterable: true,
                                                                    },
                                                                    {
                                                                        field: 'price',
                                                                        headerName: 'Preis',
                                                                        width: 130,
                                                                        align: "center",
                                                                        valueFormatter: ({value}) => value + "€",
                                                                        headerAlign: "center",
                                                                        filterable: true,
                                                                    },
                                                                    {
                                                                        field: 'string',
                                                                        width: 130,
                                                                        headerAlign: "center",
                                                                        align: "center",
                                                                        headerName: 'Löschen/Editieren',
                                                                        filterable: false,
                                                                        renderCell: () => (<div>
                                                                            <IconButton aria-label="delete"
                                                                                        onClick={() => this.setOpenConfirm(this.state.selectionExtra.data)}>
                                                                                <DeleteIcon fontSize="small"
                                                                                            className={"text-body"}/>
                                                                            </IconButton>
                                                                        </div>)
                                                                    }
                                                                ]
                                                            }
                                                            pageSize={17}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                </CRow>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.props.onClose} color="primary">
                                Schließen
                            </Button>
                            <Button onClick={this.getSelectedExtras} color="primary">
                                Speichern
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        } else {
            return (<div/>)
        }
    }

    textChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    setOpenConfirm = (data) => {
        this.setState({
            extra_id: data.id
        })
        this.handleConfirmExtras();
    }
    handleConfirmExtras = () => {
        this.setState({
            dialog_extras_confirm: !this.state.dialog_extras_confirm,
        })
    }
    deleteExtra = () => {
        const params = new URLSearchParams();
        params.append("extra_id", this.state.extra_id);

        axios({
            method: 'post',
            url: url + '?action=deleteExtras',
            data: params
        }).then((res) => {
            this.props.getExtra()
        });
    }

    getProductData = () => {
        let product = this.props.product;
        if (product) {
            return (<div>
                    <TextField className={"m-1 w-100"} id="id" label="ID"
                               value={product.id}
                               disabled={true}
                               variant="outlined"/>
                    <TextField className={"m-1 w-100"} id="Name" label="Name"
                               value={product.name}
                               disabled={true}
                               variant="outlined"/>
                    <TextField className={"m-1 w-100"} id="Preis"
                               value={product.price + " €"}
                               disabled={true}
                               label="Preis"
                               variant="outlined"/>
                    <TextField className={"m-1 w-100"} id="Lagerbestand"
                               value={product.stored_amount !== "-1" ? product.stored_amount : "Kein Lagerbestand gesetzt"}
                               disabled={true}
                               label="Lagerbestand"
                               variant="outlined"/>
                </div>

            )
        }
    }
    onSaveAddExtra = (categorie_name, price) => {

        const params = new URLSearchParams();
        params.append('description', categorie_name);
        params.append('isEnabled', 1);
        params.append('price', price);
        params.append('event_id', this.props.event_id);

        axios({
            method: 'post',
            url: url + '?action=addExtras',
            data: params
        }).then((res) => {
            this.props.getExtra();
        });

    }
    handleAddExtra = () => {
        this.setState({openExtra: !this.state.openExtra})
    }

    getSelectedExtras = () => {
        if (this.state.selection) {
            if (this.state.selection.length !== 0) {
                this.addExtrasToProduct();
                this.props.onClose();
            } else {
                this.props.onClose();
            }
        } else {
            this.props.onClose();
        }
    }


    addExtrasToProduct = () => {

        const params = new URLSearchParams();
        params.append('productId', this.props.product.id);
        params.append('extraIds', JSON.stringify(this.state.selection));
        axios({
            method: 'post',
            url: url + '?action=addExtrasToProduct',
            data: params
        }).then((res) => {
        });
    }

}

export default Product;