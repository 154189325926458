import React, {Component} from 'react';
import {Dialog as Dialog_mat, Radio, RadioGroup, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class DialogCopyEvent extends Component {
    state = {
        types: [
            {id: 0, name: "Kategorien", checked: true},
            {id: 1, name: "Drucker", checked: true},
            {id: 2, name: "Rollen", checked: true},
            {id: 3, name: "Unterkategorien", checked: true},
            {id: 4, name: "Größen", checked: true},
            {id: 5, name: "Extras", checked: true},
            {id: 6, name: "Nutzer", checked: true},
            {id: 7, name: "Produkte", checked: true},
            {id: 8, name: "Bestellfeld", checked: true},
            {id: 9, name: "Zustand", checked: true},
        ],
        step: 1,
        events: [],
        selected: "",
        newEventOpen: false,
        newEventName: ""
    }


    componentDidMount() {
        if (this.props.events.length > 0) {
            let temp = []
            this.props.events.forEach(item => temp.push({event: item, checked: false}))
            this.setState({events: temp, selected: this.props.dialogTitle})
        }
    }

    onChange = (i, e) => {
        let temp = this.state.types
        temp[i].checked = !this.state.types[i].checked
        this.setState({
            types: temp
        })
    }

    isEnabled = (event) => {
        return (event.event.id === this.props.dialogTitle.id)
    }

    render() {
        return (
            <div>
                {this.state.step === 1 ?
                    <Dialog_mat aria-labelledby="simple-dialog-title" maxheight={'md'}
                                open={this.props.open} onClose={this.props.onClose}>
                        <DialogTitle
                            id="simple-dialog-title">{"Von Event " + this.props.dialogTitle.description + " kopieren"}</DialogTitle>
                        <DialogContent className={"height-dialog"}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" column={"true"}>
                                    {this.state.types.map((c, index) => (
                                        <FormControlLabel
                                            key={c.id}
                                            value="end"
                                            control={<Checkbox
                                                id={c.toString()}
                                                onChange={(e) => this.onChange(index, e)}
                                                name={c.name}
                                                checked={c.checked}
                                            />}
                                            label={c.name}
                                            labelPlacement="end"
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>

                        </DialogContent>
                        {this.props.saveDialog ? (<DialogActions>
                            <Button onClick={() => this.handleClose(false)} color="primary">
                                Abbrechen
                            </Button>
                            <Button onClick={() => this.handleNext()} color="primary">
                                Next
                            </Button>
                        </DialogActions>) : null}
                    </Dialog_mat>
                    : null}
                {this.state.step === 2 ?
                    <Dialog_mat aria-labelledby="simple-dialog-title" maxheight={'md'}
                                open={this.props.open} onClose={this.props.onClose}>
                        <DialogTitle id="simple-dialog-title">{"Nach Event kopieren"}</DialogTitle>
                        <DialogContent className={"height-dialog"}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="event" name="event" value={this.state.selected}
                                            onChange={this.handleChange}>
                                    {this.state.events.map((c, index) => (
                                        this.isEnabled(c) === true ?
                                            null :
                                            <FormControlLabel
                                                key={c.event.id}
                                                value="end"
                                                control={<Radio
                                                    id={c.toString()}
                                                    value={c.event.id}
                                                    name={c.event.description}
                                                />}
                                                label={c.event.description}
                                                labelPlacement="end"
                                            />
                                    ))}
                                    <FormControlLabel
                                        key={-1}
                                        value="end"
                                        control={<Radio
                                            id={"newEvent"}
                                            value={JSON.stringify("newEvent")}
                                            name={"newEvent"}
                                        />}
                                        label={"Neues Event"}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>

                        </DialogContent>
                        {this.props.saveDialog ? (<DialogActions>
                            <Button onClick={() => this.handleClose(false)} color="primary">
                                Abbrechen
                            </Button>
                            <Button onClick={() => this.handleClose(true)} color="primary">
                                Speichern
                            </Button>
                        </DialogActions>) : null}
                    </Dialog_mat> : null}
            </div>

        );
    }

    handleNext = () => {
        this.setState({step: this.state.step + 1})
    }
    handleText = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleNewEvent = () => {
        this.setState({
            newEventOpen: !this.state.newEventOpen
        })
    }
    handleChange = (e) => {
        this.setState({
            selected: e.target.value
        })
    }

    handleClose = (bol) => {
        if (bol) {
                this.props.saveDialog(this.state)
        } else {
            this.props.onClose()
        }
        this.setState({
            types: []
        })
    }

}

export default DialogCopyEvent