import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CreatableSelect from 'react-select/creatable';
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";

class AddUserDialog extends Component {
    state = {
        username: "",
        password: "",
        showPassword: false
    }

    render() {
        return (
            <div>
                <Dialog PaperProps={{style: {overflowY: 'visible'}}} open={this.props.open}
                        onClose={this.props.onClose}>
                    <DialogTitle id="simple-dialog-title">Benutzer hinzufügen</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <div className={"m-3"} style={{display: 'flex', flexFlow: 'column'}}>
                            <div className="form-group m-1 ">
                                <Input
                                    id={'username'}
                                    className={'form-control'}
                                    type={'text'}
                                    autoFocus={true}
                                    autoComplete={"off"}
                                    required={true}
                                    placeholder={'Username'}
                                    disableUnderline={true}
                                    value={this.state.username}
                                    onBlur={this.props.setPageValue}
                                    onChange={this.onTextChange}
                                    name={'username'}
                                />
                            </div>
                            <div className="form-group m-1">
                                <Input
                                    id="password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.password}
                                    onChange={this.onTextChange}
                                    placeholder={"Passwort"}
                                    className={'form-control'}
                                    onBlur={this.props.setPageValue}
                                    name={'password'}
                                    disableUnderline={true}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </div>
                            <div className="form-group m-1">
                                <CreatableSelect
                                    onChange={this.props.handleChange}
                                    onCreateOption={this.props.handleCreate}
                                    options={this.props.select_option}
                                    value={this.props.selection_role}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">
                            Schließen
                        </Button>
                        <Button onClick={this.onSave} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.props.onClose();
        this.setState({
            username: "",
            password: "",
            showPassword: false
        })
    }
    onSave = () => {
        this.props.onSave();
        this.setState({
            username: "",
            password: "",
            showPassword: false
        })
    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    onTextChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }
}

export default AddUserDialog;