import React, {Component} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {CardHeader} from "@material-ui/core";
import {CWidgetIcon, CCol, CRow, CWidgetBrand, CWidgetDropdown, CWidgetSimple} from "@coreui/react"
import ChartLineSimple from '../../../charts/ChartLineSimple';
import CIcon from '@coreui/icons-react'
import {CChartBar} from "@coreui/react-chartjs";


class WaiterEvent extends Component {

    render() {
        if (!this.props.show) {
            return (<div/>);
        }
        try {
            this.props.bestWaiter.price = this.props.bestWaiter.price.replace(",", "")
        } catch (e) {

        }
        return (
            <div>
                <div className={"m-1 mt-2 pb-5"}>
                    <h2 className={"text-left ml-2"}>Kellnerübersicht</h2>
                    <div className={"justify-content-center mb-4"}>
                        <CRow>
                            <CCol sm={6}>
                                <Card className={"card_border m-05 m-1"}>
                                    <CardHeader className={"pb-0 ml-4"} title={"Bestellungen pro Kellner"}/>
                                    <CardContent className={"pt-0"}>
                                        <CChartBar
                                            datasets={this.props.data_waiter_amount_ordered.datasets}
                                            labels={this.props.data_waiter_amount_ordered.labels}
                                            options={this.props.options_graph_product}
                                        />
                                    </CardContent>
                                </Card>
                            </CCol>
                            <CCol sm={6}>
                                <Card className={"card_border m-05 m-1"}>
                                    <CardHeader className={"pb-0 ml-4"} title={"Einnahmen pro Tag"}/>
                                    <CardContent className={"pt-0"}>
                                        <CChartBar
                                            datasets={this.props.data_graph_product.datasets}
                                            labels={this.props.data_graph_product.labels}
                                            options={this.props.options_graph_product}
                                        />
                                    </CardContent>
                                </Card>
                            </CCol>
                        </CRow>
                    </div>
                    <CRow>
                        {/*
                        <CCol>

                            <CWidgetIcon text="income" className={"mb-1"} header="$1.999,50" color="primary"
                                         iconPadding={false}>
                                <CIcon width={"24"} name="cil-settings"/>
                            </CWidgetIcon>
                            <CWidgetIcon className={"mt-2"} text="income" header="$1.999,50" color="info"
                                         iconPadding={false}>
                                <CIcon width={"24"} name="cil-laptop"/>
                            </CWidgetIcon>

                        </CCol>
  */}
                        <CCol sm="6" lg="3">
                            <CWidgetBrand
                                color="facebook"
                                rightHeader={"" + this.props.bestWaiter ? this.props.bestWaiter.orderamount : 0}
                                rightFooter="Bestellungen"
                                leftHeader={"" + this.props.bestWaiter ? this.props.bestWaiter.price : 0 + "€"}
                                leftFooter="Einnahmen"
                            >
                                <div style={{height: '70px'}} className={"pt-3"}>
                                    <h3>
                                        {this.props.bestWaiter ? this.props.bestWaiter.user.name : "Keine Bestellungen"}
                                    </h3>
                                </div>
                            </CWidgetBrand>
                        </CCol>
                        {/*
                        <CCol sm="6" lg="3">
                            <CWidgetDropdown
                                color="gradient-warning"
                                header="12"
                                text="Data"
                                footerSlot={
                                    <ChartLineSimple
                                        className="mt-3"
                                        style={{height: '70px'}}
                                        backgroundColor="rgba(255,255,255,.2)"
                                        dataPoints={[78, 81, 80, 45, 34, 12, 40]}
                                        options={{elements: {line: {borderWidth: 2.5}}}}
                                        pointHoverBackgroundColor="warning"
                                        label="Members"
                                        labels="months"
                                    />
                                }
                            >
                            </CWidgetDropdown>
                        </CCol>
                        <CCol sm="4" lg="2">
                            <CWidgetSimple header="title" text="1,123">
                                <ChartLineSimple style={{height: '31px'}} borderColor="primary"/>
                            </CWidgetSimple>
                        </CCol>
                        */}
                    </CRow>
                </div>
            </div>
        );
    }

    test = (ok) => {
        this.props.ok();
    }
}

export default WaiterEvent;