import React, {Component} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HomeIcon from '@material-ui/icons/Home';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import EuroIcon from '@material-ui/icons/Euro';
import Tooltip from "@material-ui/core/Tooltip";
import DeckIcon from "@material-ui/icons/Deck";

class ListItemsDrawer extends Component {
    render() {
        return (<div>
            <Tooltip title="Home" placement="left">
                <ListItem button className={"m-1"} onClick={this.props.openHome}>
                    <ListItemIcon>
                        <HomeIcon fontSize={"large"}/>
                    </ListItemIcon>
                </ListItem>
            </Tooltip>
            <Tooltip title="Bestellungen" placement="left">
                <ListItem button className={"m-1"} onClick={this.props.openOrders}>
                    <ListItemIcon>
                        <ReceiptIcon fontSize={"large"}/>
                    </ListItemIcon>
                </ListItem>
            </Tooltip>
            <Tooltip title="Kellnerübersicht" placement="left">
                <ListItem button className={"m-1"} onClick={this.props.openWaiter}>
                    <ListItemIcon>
                        <PeopleIcon fontSize={"large"}/>
                    </ListItemIcon>
                </ListItem>
            </Tooltip>
            <Tooltip title="Tischübersicht" placement="left">
                <ListItem button className={"m-1"} onClick={this.props.openTable}>
                    <ListItemIcon>
                        <DeckIcon fontSize={"large"}/>
                    </ListItemIcon>
                </ListItem>
            </Tooltip>
            <Tooltip title="Einnahmen" placement="left">
                <ListItem button className={"m-1"} onClick={this.props.openMoney}>
                    <ListItemIcon>
                        <EuroIcon fontSize={"large"}/>
                    </ListItemIcon>
                </ListItem>
            </Tooltip>
            <Tooltip title="Reports" placement="left">
                <ListItem button className={"m-1"} onClick={this.props.printReports}>
                    <ListItemIcon>
                        <BarChartIcon fontSize={"large"}/>
                    </ListItemIcon>
                </ListItem>
            </Tooltip>
        </div>)
    }
}

export default ListItemsDrawer;