import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import "./ViewOrders.css"
import BackIcon from "@material-ui/icons/ArrowBackIos";
import {Redirect} from "react-router-dom";
import {Divider} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withSnackbar} from "notistack";

let url;

class ViewOrders extends Component {

    state = {
        redirect: false,
        waiter_name: JSON.parse(localStorage.getItem('user')).username,
        products: [],
        order: [],
        openSettings: false,
        printing: false
    }

    componentDidMount() {
        url = "http://192.168.1.115/Backend/";
        this.getOrder(this.props.match.params.id);
    }


    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        return (
            <div className={"w-100 h-100 container"}>
                <Row className={"fixed-top text-center bg-navs pt-1 pb-1"}>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center text-white"}
                         onClick={this.backHome}>
                        <BackIcon className={"text-white"}/> Back
                    </Col>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>
                        <div className={"waiter_name text-white"}> {this.state.waiter_name} </div>
                    </Col>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}
                         onClick={this.openSettingsScreen}>
                        <MenuIcon className={"text-white"} id={"settings"}/>
                    </Col>
                </Row>
                <Menu
                    id="simple-menu"
                    anchorEl={document.getElementById('settings')}
                    keepMounted
                    open={this.state.openSettings}
                    onClose={this.openSettingsScreen}
                >
                    <MenuItem onClick={this.removeOrder}>Bestellung löschen</MenuItem>
                    <MenuItem onClick={this.printOrderNormal}>Bestellung drucken{this.state.printing &&
                    <CircularProgress/>}</MenuItem>
                    <MenuItem onClick={this.printOrder}>Bestellung nochmals ausdrucken {this.state.printing &&
                    <CircularProgress/>}</MenuItem>
                </Menu>
                <div className={"container mt-5 pt-3 w-100"}>
                    <List subheader={<li/>}>
                        {this.state.products.map((c, k) => (
                            <div key={k} className={"m-1 p-0"}>
                                <ListItem button>
                                    <ListItemText
                                        className={"m-1 p-0"}
                                        primary={(<span
                                            className={"fontsize text-body"}>{c.amount + "x " + c.product.name}</span>)}
                                        secondary={this.checkIfExtrasAndCommentExists(c)}
                                    />
                                    <div className="text-right">
                                        <h6>{this.getPrice(c)} €</h6>
                                    </div>
                                    </ListItem>
                                    <Divider/>
                                </div>
                            )
                        )}
                        <ListItem button>
                            <ListItemText
                            />
                            <div className="text-right">
                                <h5>{this.getEntireOrderTotal()} €</h5>
                            </div>
                        </ListItem>
                    </List>

                </div>
            </div>
        )
    }

    checkIfExtrasAndCommentExists = (c) => {
        let arr = [];
        if (c.extras !== undefined) {
            c.extras.map((x, y) => (
                arr.push(<span key={y}
                               className={"comment_text fontsizeExtra text-body"}>{x.description}<br/></span>)))
            if (c.selected.comment !== null) {
                arr.push(<span className={"comment_text fontsizeExtra text-body"}>{c.selected.comment}<br/></span>)
            }
        } else if (c.selected.comment) {
            arr.push(<span className={"comment_text fontsizeExtra text-body"}>{c.selected.comment}<br/></span>)
        }
        if (arr.length !== 0) {
            return arr
        } else {
            return null;
        }
    }
    openSettingsScreen = () => {
        this.setState({
            openSettings: !this.state.openSettings
        })
    }
    printOrderNormal = () => {
        this.setState({
            printing: true
        })
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('order_id', this.props.match.params.id);

        axios({
            method: 'post',
            url: url + '?action=printOrderREST',
            data: params
        }).then((res) => {
            this.setState({
                printing: false
            })
            let arr = res.data;
            if (arr.length === 0) {
                this.props.enqueueSnackbar("Die Bestellung wurde erfolgreich gedruckt!", {
                    variant: 'success',
                    autoHideDuration: 1000
                });
            } else {
                if (arr.length !== 0) {
                    arr.forEach(element =>
                        this.props.enqueueSnackbar("Der Drucker " + element.printer.description + " mit der IP " + element.printer.ip_address + " ist nicht erreichbar!", {
                            variant: 'error',
                            autoHideDuration: 1000
                        }));
                }
            }
        });
    }

    printOrder = () => {
        let active = localStorage.getItem("kassaModus");
        if (active !== "true" || active !== true) {
            this.setState({
                printing: true
            })
            const axios = require('axios');

            const params = new URLSearchParams();
            params.append('order_id', this.props.match.params.id);

            axios({
                method: 'post',
                url: url + '?action=reprintOrder',
                data: params
            }).then((res) => {
                this.setState({
                    printing: false
                })
                let arr = res.data;
                if (arr.length === 0) {
                    this.props.enqueueSnackbar("Die Bestellung wurde erfolgreich gedruckt!", {
                        variant: 'success',
                        autoHideDuration: 1500
                    });
                } else {
                    if (arr.length !== 0) {
                        arr.forEach(element =>
                            this.props.enqueueSnackbar("Der Drucker " + element.printer.description + " mit der IP " + element.printer.ip_address + " ist nicht erreichbar!", {
                                variant: 'error',
                                autoHideDuration: 1500
                            }));
                    }
                }
            });
        } else {
            this.printKassaModeOrder();
        }
    }

    getEntireOrderTotal = () => {
        let arr = this.state.products;
        let calc = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].hasOwnProperty("extras")) {
                calc += arr[i].product.price * arr[i].amount;
                for (let j = 0; j < arr[i].extras.length; j++) {
                    calc += arr[i].extras[j].price * arr[i].amount
                }
            } else {
                calc += (arr[i].product.price * arr[i].amount);
            }
        }
        return calc;
    }
    getPrice = (c) => {
        let help;
        if (c.hasOwnProperty("extras")) {
            help = c.product.price * c.amount;
            for (let i = 0; i < c.extras.length; i++) {
                help += c.extras[i].price * c.amount
            }
        } else {
            help = (c.product.price * c.amount)
        }
        return help;
    }
    getIfIsExtra = (c) => {
        let div = document.createElement("div");
        if (c.hasOwnProperty("extras")) {
            for (let i = 0; i < c.extras.length; i++) {
                let h6 = document.createElement("h6");
                let text = document.createTextNode(c.extras[i].description);
                h6.appendChild(text);
                div.appendChild(h6);
            }
        }
        return div;
    }
    getOrder = (id) => {
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('id', id);

        axios({
            method: 'post',
            url: url + '?action=getEntireOrderById',
            data: params
        }).then((res) => {
            this.setState({
                products: res.data.products,
                order: res.data
            })
        })
    }
    removeOrder = () => {
        let id = this.props.match.params.id;
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('orderId', id);

        axios({
            method: 'post',
            url: url + '?action=deleteOrder',
            data: params
        }).then((res) => {
            this.backHome();
        })
    }

    backHome = () => {
        if (this.props.match.params.searchinput) {
            this.setState({
                redirectToLink: "/loggedin/SearchOrders/" + this.props.match.params.searchinput,
                redirect: true
            })
        } else {
            this.setState({
                redirectToLink: "/loggedin/Home/",
                redirect: true
            })
        }

    }
}

export default withSnackbar(ViewOrders);
