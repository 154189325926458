import React, {Component} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog, Divider, IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

class DialogTableDetailed extends Component {
    state = {
        expanded: ""
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.open}
                        onClose={this.props.onClose}
                        maxWidth={"xs"}
                        fullWidth={true}
                >
                    <DialogTitle id="simple-dialog-title">Tischdetails</DialogTitle>
                    <DialogContent>
                        {
                            this.props.tableData && this.props.tableData.length !== 0 ? (
                                    <div>
                                        <Accordion disabled={true} style={{backgroundColor: "#fff"}}>
                                            <AccordionSummary
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{opacity: 1}}
                                            >
                                                <Typography
                                                    style={{color: "#000"}}>Tischnr. {this.props.tableData.tablenumber}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Tischnr. 120
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion disabled={true} style={{backgroundColor: "#fff"}}>
                                            <AccordionSummary
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                                style={{opacity: 1}}
                                            >
                                                <Typography
                                                    style={{color: "#000"}}>Einnahmen: {this.props.tableData.income + "€"}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Einnahmen: 120€
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion disabled={true} style={{backgroundColor: "#fff"}}>
                                            <AccordionSummary
                                                aria-controls="panel3a-content"
                                                id="panel3a-header"
                                                style={{opacity: 1}}
                                            >
                                                <Typography
                                                    style={{color: "#000"}}>Gesamtanzahl der bestellten
                                                    Produkte: {this.props.tableData.total_products}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Gesamtanzahl 120
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion disabled={true} style={{backgroundColor: "#fff"}}>
                                            <AccordionSummary
                                                aria-controls="panel3a-content"
                                                id="panel3a-header"
                                                style={{opacity: 1}}
                                            >
                                                <Typography
                                                    style={{color: "#000"}}>Gesamtanzahl der
                                                    Bestellungen: {this.props.tableData.amount_of_orders}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Gesamtanzahl 120
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={this.state.expanded === 'panel4'}
                                                   onChange={() => this.handleChange('panel4')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel4bh-content"
                                                id="panel4bh-header"
                                                style={{opacity: 1}}
                                            >
                                                <Typography style={{color: "#000"}}>Produkte</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {
                                                    this.props.detailedTableProducts &&
                                                    <List subheader={<li/>} style={{width: "100%"}}
                                                          className={"padding_toScroll"}>
                                                        <Divider/>
                                                        {
                                                            this.props.detailedTableProducts.map((c, k) => (
                                                                <div key={k}>
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={c ? c.product.name : ""}
                                                                        />
                                                                        <ListItemSecondaryAction>
                                                                            <Typography>{c ? c.amount + " Stk." : ""}</Typography>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                    <Divider/>
                                                                </div>
                                                            ))
                                                        }
                                                    </List>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion expanded={this.state.expanded === 'panel5'}
                                                   onChange={() => this.handleChange('panel5')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel5bh-content"
                                                id="panel5bh-header"
                                                style={{opacity: 1}}
                                            >
                                                <Typography style={{color: "#000"}}>Kellner</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {
                                                    this.props.detailedTableWaiters &&
                                                    <List subheader={<li/>} style={{width: "100%"}}
                                                          className={"padding_toScroll"}>
                                                        <Divider/>
                                                        {
                                                            this.props.detailedTableWaiters.map((c, k) => (
                                                                <div key={k}>
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={c ? c.waiter.name : ""}
                                                                        />
                                                                        <ListItemSecondaryAction>
                                                                            <Typography>{c ? c.amount + " Bestellungen" : ""}</Typography>
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                    <Divider/>
                                                                </div>
                                                            ))
                                                        }
                                                    </List>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                )
                                : <CircularProgress color="secondary"/>

                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.setState({
            expanded: ""
        }, this.props.onClose());
    }

    handleChange = (panel) => {
        if (panel === this.state.expanded) {
            panel = ""
        }
        this.setState({
            expanded: panel
        })
    };
}

export default DialogTableDetailed;