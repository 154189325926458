import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {DataGrid} from "@material-ui/data-grid";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay'
import {CCol, CRow} from "@coreui/react";
import DialogNewPassword from "../Dialog/Dialogs_user/DialogNewPassword";
import ConfirmDialog from "../Dialog/Dialogs_user/ConfirmDialog";
import EditDialog from "../Dialog/Dialogs_user/EditDialog";
import AddUserDialog from "../Dialog/Dialogs_user/AddUserDialog";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

let url;

class Users extends Component {
    state = {
        selection: null,
        delete_confirm: false,
        user_id: null,
        dialogNewPasswordOpen: false,
        username: "",
        password: "",
        newPassword: "",
        dialogEditOpen: false,
        role: [],
        id: 0,
        users: [],
        user_add: false,
        roles_options: [],
        role_selection: null,
        search_field_user: ""
    }

    componentDidMount() {
        url = "http://192.168.1.115/Backend/";
        if (this.props.roles && this.props.users) {
            const newroles = this.props.roles.map(function (row) {
                return {value: row.id, label: row.description}
            });
            this.setState({
                role: this.props.roles,
                roles_options: newroles,
                role_selection: newroles[0],
                users: this.props.users
            });
        }
    }

    render() {
        if (!this.props.open) {
            return (<div/>)
        }
        return (
            <div className={"w-100 h-100 container"}>
                <DialogNewPassword onClose={this.handleCloseNewPassword} open={this.state.dialogNewPasswordOpen}
                                   newPassword={this.state.newPassword}/>
                <ConfirmDialog
                    title="Benutzer löschen?"
                    open={this.state.delete_confirm}
                    setOpen={this.handleOpenConfirm}
                    onConfirm={this.deleteUser}
                    text={"Wollen sie wirklich diesen Benutzer löschen?"}
                />
                <EditDialog
                    onClose={this.handleEditDialog}
                    open={this.state.dialogEditOpen}
                    onSave={this.onEditSave}
                    setPageValue={this.setPageValue}
                    username={this.state.username}
                    role={this.state.role}
                    handleChange={this.handleChange}
                    password={this.state.password}
                    selection_role={this.state.role_selection}
                    select_option={this.state.roles_options}
                />
                <AddUserDialog
                    open={this.state.user_add}
                    username={this.state.username}
                    password={this.state.password}
                    onClose={this.handleAddUser}
                    setPageValue={this.setPageValue}
                    onSave={this.addUser}
                    handleChange={this.handleChange}
                    handleCreate={this.handleCreateUser}
                    selection_role={this.state.role_selection}
                    select_option={this.state.roles_options}
                />
                <div className={"mb-5 pb-3 pt-3 no_select"}>
                    <div style={{
                        padding: "0.2rem",
                        display: 'flex'
                    }}>
                        <div style={{flexGrow: 1}}>
                            <CRow className={"ml-2"}>
                                <h2>Users</h2>
                                <div className={"ml-2"}>
                                    <IconButton aria-label="edit" onClick={this.handleAddUser}>
                                        <AddIcon className={"text-body"}/>
                                    </IconButton>
                                </div>
                                <CCol>
                                    <div style={{float: "right"}}>
                                        <TextField
                                            className={"mb-2 mt-2"}
                                            variant={"outlined"}
                                            id={"search_field_user"}
                                            name={"search_field_user"}
                                            value={this.state.search_field_user}
                                            onChange={this.textChange}
                                            label="Search"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                            <DataGrid
                                rows={this.state.users}
                                columnBuffer={10}
                                density={"standard"}
                                scrollbarSize={10}
                                autoHeight={true}
                                filterModel={{
                                    items: [
                                        {
                                            columnField: 'name',
                                            operatorValue: 'contains',
                                            value: this.state.search_field_user
                                        },
                                    ],
                                }}
                                loading={this.props.loading}
                                onRowSelected={(newSelection) => {
                                    this.setState({
                                        selection: newSelection
                                    });
                                }}
                                columns={
                                    [
                                        {field: 'id', headerName: 'ID', width: 70, headerAlign: "center",},
                                        {
                                            field: 'name',
                                            headerName: 'Name',
                                            width: 130,
                                            headerAlign: "center",
                                            renderCell: (params) => (
                                                <Tooltip title={params.row.name}>
                                                    <span className="table-cell-trucate">{params.row.name}</span>
                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            headerName: 'Role', field: "role",
                                            width: 130,
                                            headerAlign: "center",
                                            valueFormatter: ({value}) => value.description
                                        },
                                        {
                                            field: 'string',
                                            width: 200,
                                            headerAlign: "center",
                                            align: "center",
                                            headerName: 'Löschen/Editieren',
                                            filterable: false,
                                            renderCell: () => (
                                                <div>
                                                    <IconButton aria-label="edit"
                                                                onClick={() => this.setEditData(this.state.selection.data)}>
                                                        <EditIcon fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                    <IconButton aria-label="delete"
                                                                onClick={() => this.setUser(this.state.selection.data.id)}>
                                                        <DeleteIcon fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                    <IconButton aria-label="delete"
                                                                onClick={() => this.resetUser(this.state.selection.data.id)}>
                                                        <ReplayIcon fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                </div>
                                            ),
                                        },
                                    ]
                                }
                                pageSize={5}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    textChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    checkifFilledOut = () => {
        return (this.state.username !== "" && this.state.password !== "");
    }
    getAllUsers = () => {
        const axios = require('axios');

        axios({
            method: 'post',
            url: url + '?action=getAllUsers',
        }).then((res) => {
            //console.log(res.data)
            this.setState({
                users: res.data
            })
        });
    }

    setText = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    setPageValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    getAllRoles = () => {
        const axios = require('axios');

        axios({
            method: 'post',
            url: url + '?action=getAllRoles',
        }).then((res) => {
            //console.log(res.data)
            const newroles = res.data.map(function (row) {
                return {value: row.id, label: row.description}
            });
            this.setState({roles_options: newroles, role_selection: newroles[0]});
        });
    }
    handleAddUser = () => {
        this.setState({
            user_add: !this.state.user_add,
            username: "",
            password: ""
        })
    }


    handleCreateUser = (inputValue) => {
        let options = this.state.roles_options;
        let newOption = this.createOption(inputValue, inputValue)
        options.push(newOption)
        this.setState({
            roles_options: options,
            role_selection: newOption,
        });

    };
    handleChange = (selectedOption) => {
        this.setState({
            role_selection: selectedOption
        })
    }
    createOption = (value, label) => ({
        label: label,
        value: value,
    });
    setUser = (id) => {
        this.setState({
            user_id: id
        })
        this.handleOpenConfirm();
    }
    handleOpenConfirm = () => {
        this.setState({
            delete_confirm: !this.state.delete_confirm
        })
    }
    addUser = () => {
        if (this.checkifFilledOut()) {

            let role = this.state.role_selection;
            const axios = require('axios');
            const param = new URLSearchParams();
            param.append('event_id', this.props.event.id);
            param.append('description', role.label);

            axios({
                method: 'post',
                url: url + '?action=addRole',
                data: param
            }).then((response) => {
                const axios = require('axios');

                const params = new URLSearchParams();
                params.append('roleId', response.data.id);
                params.append('event_id', this.props.event.id)
                params.append('name', this.state.username);
                params.append('password', this.state.password);

                axios({
                    method: 'post',
                    url: url + '?action=editOrMakeUser',
                    data: params
                }).then((res) => {
                    this.refreshUsers(res.data)
                    this.handleAddUser()
                });
            });
        } else {
            alert("nicht alles ausgefüllt")
        }
    }
    refreshUsers = (user_in) => {
        this.setState({
            users: user_in
        })
    }
    addRole = (role) => {

    }
    setEditData = (data) => {
        let role = this.getRoleOfUser(data)
        this.setState({
            id: data.id,
            username: data.name,
            password: data.password,
            role_selection: role
        })
        this.handleEditDialog();
    }
    getRoleOfUser = (data) => {
        let options = this.state.roles_options;
        return options.find(element => parseInt(element.value) === parseInt(data.role_id));
    }
    removeUserFromArray = (id) => {
        this.setState({
            users: this.state.users.filter(row => row.id !== id),
        });
    }
    handleEditDialog = () => {
        this.setState({
            dialogEditOpen: !this.state.dialogEditOpen
        })
    }
    handleCloseNewPassword = () => {
        this.setState({
            dialogNewPasswordOpen: !this.state.dialogNewPasswordOpen
        })
    }
    onEditSave = () => {
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('event_id', this.props.event.id)
        params.append('id', this.state.id);
        params.append('name', this.state.username);
        params.append('password', this.state.password);
        params.append('roleId', this.state.role_selection.value);

        axios({
            method: 'post',
            url: url + '?action=editOrMakeUser',
            data: params
        }).then((res) => {
            this.setState({users: res.data})
            this.handleEditDialog();
        });
    }
    resetUser = (id) => {
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('id', id);

        axios({
            method: 'post',
            url: url + '?action=resetPassword',
            data: params
        }).then((res) => {
            this.setState({
                dialogNewPasswordOpen: true,
                newPassword: window.atob(res.data.password)
            })
        });
    }
    deleteUser = () => {
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('id', this.state.user_id);

        axios({
            method: 'post',
            url: url + '?action=deleteUser',
            data: params
        }).then((res) => {
            this.removeUserFromArray(this.state.user_id);
        });
    }
}

export default Users;