import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {CircularProgress} from "@material-ui/core";
import ConfirmDialog from "../AdminScreens/AdminHome/Dialog/Dialogs_user/ConfirmDialog";

class DialogMenu extends Component {
    state = {
        print_confirm: false
    }

    render() {
        return (
            <div>
                <ConfirmDialog
                    title="Nochmals drucken"
                    open={this.state.print_confirm}
                    setOpen={this.handleOpenPrintConfirm}
                    onConfirm={this.props.l4_onClick}
                    text={"Wollen sie wirklich die Bestellung nochmals ausdrucken!!?"}
                />
                <Dialog open={this.props.open} className={"no_select"} onClose={this.props.onClose}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle className={"text-center"} id="form-dialog-title">Menü</DialogTitle>
                    <DialogContent>
                        <div className={"w-100 text-center d-flex justify-content-center"}>
                            <List>
                                <ListItem button onClick={this.props.l1_onClick}>
                                    <ListItemText primary={"Später bezahlen"}/>
                                </ListItem>
                                <ListItem button onClick={this.props.l2_onClick}>
                                    <ListItemText primary="Weiter bestellen"/>
                                </ListItem>
                                {this.getNotAdmin()}
                                <ListItem button onClick={this.handleOpenPrintConfirm}>
                                    <ListItemText primary="Nochmals Drucken"/>{this.props.tryingPrinting &&
                                <CircularProgress/>}
                                </ListItem>
                                <ListItem button onClick={this.props.l5_onClick}>
                                    <ListItemText primary="Bestellung löschen"/>
                                </ListItem>
                            </List>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    handleOpenPrintConfirm = () => {
        this.setState({
            print_confirm: !this.state.print_confirm
        })
    }
    getNotAdmin = () => {
        let active = localStorage.getItem("kassaModus");
        if (active !== "true" || active !== true) {
            return <ListItem button onClick={this.props.l3_onClick}>
                <ListItemText primary="Drucken"/>{this.props.tryingPrinting && <CircularProgress/>}
            </ListItem>
        }
    }


}

export default DialogMenu;