import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class EditCategorie extends Component {
    state = {
        categorie_name: null,
        errorMessage: ""
    }


    render() {
        return (
            <div>
                <Dialog PaperProps={{style: {overflowY: 'visible'}}} open={this.props.open}
                        onClose={this.onClose}>
                    <DialogTitle id="simple-dialog-title">Kategorie editieren</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <div className={"m-3"} style={{display: 'flex', flexFlow: 'column'}}>
                            <div className="form-group m-1 ">
                                <TextField className={"m-1 w-100"}
                                           type={"text"}
                                           required={true}
                                           autoFocus={true}
                                           autoComplete={"off"}
                                           value={this.state.categorie_name === null ? this.props.categorie_name : this.state.categorie_name}
                                           id={'categorie_name'}
                                           onChange={this.onInputChange}
                                           name={'categorie_name'}
                                           label="Name der Kategorie"
                                           variant="outlined"/>
                            </div>
                        </div>
                        {this.state.errorMessage ? <span style={{color: "red"}}>{this.state.errorMessage}</span> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">
                            Schließen
                        </Button>
                        <Button onClick={this.checkIfAllFieldsFilledOut} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.props.onClose();
        this.setState({
            categorie_name: null
        })
    }
    checkIfAllFieldsFilledOut = () => {
            if (this.state.categorie_name !== "") {
                this.props.onSave(this.props.categorie_id, this.state.categorie_name, this.props.categorie_name)
                this.setState({errorMessage: null, categorie_name: null})
            } else {
                this.setState({errorMessage: "Kein Kategorie-Namen eingegeben"})
            }
    }


    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }
}

export default EditCategorie;