import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class EditProduct extends Component {

    state = {
        product_name: null,
        product_price: null,
        product_amount: null,
        amount_enabled: true,
        errorMessage: ""
    }

    render() {
        return (
            <div>
                <Dialog PaperProps={{style: {overflowY: 'visible'}}} open={this.props.open}
                        onClose={this.onClose}>
                    <DialogTitle id="simple-dialog-title">Produkt editieren</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <div className={"m-3"} style={{display: 'flex', flexFlow: 'column'}}>
                            <div className="form-group m-1 ">
                                <TextField className={"m-1 w-100"}
                                           type={"text"}
                                           required={true}
                                           autoComplete={"off"}
                                           value={this.state.product_name === null ? this.props.product_name : this.state.product_name}
                                           id={'product_name'}
                                           onChange={this.onInputChange}
                                           name={'product_name'}
                                           label="Name des Produktes"
                                           variant="outlined"/>
                            </div>
                            <div className="form-group m-1 ">
                                <TextField className={"m-1 w-100"}
                                           type={"number"}
                                           required={true}
                                           InputProps={{inputProps: {min: 0}}}
                                           value={this.state.product_price === null ? this.props.product_price : this.state.product_price}
                                           id={'product_price'}
                                           onChange={this.onInputChange}
                                           name={'product_price'}
                                           label="Preis des Produktes"
                                           variant="outlined"/>
                            </div>
                            <div className="form-group m-1 ">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.amount_enabled}
                                            onChange={this.handleCheckBox}
                                            name="amount_enabled"
                                            id={"amount_enabled"}
                                            color="primary"
                                        />
                                    }
                                    label="Lagerbestand prüfen"
                                />
                            </div>
                            {this.state.amount_enabled ? (<div className="form-group m-1 ">
                                <TextField className={"m-1 w-100"}
                                           type={"number"}
                                           required={true}
                                           value={this.state.product_amount === null ? this.props.product_amount : this.state.product_amount}
                                           id={'product_amount'}
                                           onChange={this.onInputChange}
                                           name={'product_amount'}
                                           label="Lagerbestand des Produktes"
                                           variant="outlined"/>
                            </div>) : <div/>}
                        </div>
                        {this.state.errorMessage ? <span style={{color: "red"}}>{this.state.errorMessage}</span> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">
                            Schließen
                        </Button>
                        <Button onClick={this.checkIfAllFieldsFilledOut} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.setState({
            product_name: null,
            product_price: null,
            product_amount: 9999,
            amount_enabled: true,
            errorMessage: null
        })
        this.props.onClose();
    }
    checkIfAllFieldsFilledOut = () => {
        if (this.state.product_name !== "" && this.state.product_price !== "") {
            this.setState({errorMessage: null})
            if (this.state.amount_enabled) {
                if (this.state.product_amount === "" || !this.props.product_amount) {
                    this.setState({errorMessage: "Kein Lagerbestand eingeben "})
                } else {
                    this.setState({
                        errorMessage: null, product_name: null,
                        product_price: null,
                        product_amount: null,
                        amount_enabled: true,
                    })
                    this.props.onSave(this.props.product_id, this.state.product_name, this.props.product_name, this.state.product_price, this.props.product_price, this.state.product_amount, this.props.product_amount, true)
                }
            } else {
                this.setState({
                    errorMessage: null, product_name: null,
                    product_price: null,
                    product_amount: null,
                    amount_enabled: true,
                })
                this.props.onSave(this.props.product_id, this.state.product_name, this.props.product_name, this.state.product_price, this.props.product_price, this.state.product_amount, this.props.product_amount, false)
            }

        } else {
            this.setState({errorMessage: "Kein Produktname und/oder keinen Preis eingegeben"})
        }
    }
    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }
    handleCheckBox = () => {
        this.setState({amount_enabled: !this.state.amount_enabled})
    }
}

export default EditProduct;