import React, {Component} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import {Dialog as Dialog_mat} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DialogExtras from "./DialogExtras";

let longtouch;
let timer;
let touchduration = 500;

class DialogSingleProduct extends Component {
    state = {
        value: {},
        isOpen: false,
        selectedProduct: [],
        modalData: []
    }
    handleChange = (event) => {
        this.setState({
            value: JSON.parse(event.target.value)
        }, () => {
            this.handleClose(true)
        });
    };


    render() {
        return (
            <div>
                <DialogExtras dialogTitle={"Extras für " + this.state.selectedProduct.name}
                              onClose={this.closeDialog}
                              open={this.state.isOpen}
                              onChange={this.setExtraChecked} data={this.state.modalData}
                              saveDialog={this.saveDialog} DialogAction={true}
                />
                <Dialog_mat aria-labelledby="simple-dialog-title" maxheight={'md'}
                            open={this.props.open} onClose={this.props.onClose}>
                    <DialogTitle id="simple-dialog-title">{this.props.dialogTitle}</DialogTitle>
                    <DialogContent className={"height-dialog"}>
                        <FormControl component="fieldset">
                            <RadioGroup value={JSON.stringify(this.state.value)} className={"no_select"}
                                        onChange={this.handleChange}>
                                {this.props.data.map((c, index) =>
                                    parseInt(c.isEnabled) === 1 ?
                                        (
                                            <FormControlLabel
                                                key={c.id}
                                                value="end"
                                                className={"no_select"}
                                                onTouchStart={this.touchstart}
                                                onTouchEnd={() => this.touchend(c)}
                                                onMouseDown={this.touchstart}
                                                onMouseUp={() => this.touchend(c)}
                                                control={
                                                    <Radio
                                                        id={c.name}
                                                        value={JSON.stringify(c)}
                                                        name={c.name}
                                                    />}
                                                label={c.name}
                                                labelPlacement="end"
                                            />
                                        ) : (<div/>))}
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    {this.props.saveDialog ? (<DialogActions>
                        <Button onClick={() => this.handleClose(false)} color="primary">
                            {this.props.closeText ? this.props.closeText : "Abbrechen"}
                        </Button>
                        <Button onClick={() => this.handleClose(true)} color="primary">
                            {this.props.saveText ? this.props.saveText : "Speichern"}
                        </Button>
                    </DialogActions>) : null}
                </Dialog_mat>
            </div>
        );
    }

    closeDialog = () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    touchstart = (e) => {
        e.preventDefault();
        longtouch = false;

        timer = setTimeout(function () {
            longtouch = true;
            timer = null
        }, touchduration);
    }
    touchend = (product) => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        if (longtouch) {
            //console.log(product)
            longtouch = false;
            let data = []
            let extrasStates = []
            if (product.extras.length !== 0) {
                for (let i = 0; i < product.extras.length; i++) {
                    extrasStates.push(
                        {
                            extras: product.extras[i],
                            checked: false
                        }
                    )
                }
                data.push({
                    product: product,
                    extras: extrasStates
                })
                this.setState({
                    isOpen: true,
                    modalData: data,
                    selectedProduct: product
                })
            }
        } else {
            //console.log("no long")
        }
    }
    setExtraChecked = (key, e) => {
        let modalData = this.state.modalData;
        modalData[key].extras[e.target.id].checked = modalData[key].extras[e.target.id].checked !== true;
        this.setState({
            modalData: modalData
        })
    }
    saveDialog = () => {
        this.props.saveDialogExtras(this.state.modalData);
        this.closeDialog();
        this.props.onClose()
        this.setState({
            value: {},
            isOpen: false,
            selectedProduct: [],
            modalData: []
        })
    }

    handleClose = (bol) => {
        if (bol) {
            this.props.saveDialog(this.state.value)
            this.setState({
                value: {},
                isOpen: false,
                selectedProduct: [],
                modalData: []
            })
        } else {
            this.props.onClose()
            this.setState({
                value: {},
                isOpen: false,
                selectedProduct: [],
                modalData: []
            })
        }
    }
}

export default DialogSingleProduct;