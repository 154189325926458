//Imports
import Login from "./Components/Login/Login";
import Home from "./Components/WaiterSreens/Home/Home";
import AdminHome from "./Components/AdminScreens/AdminHome/AdminHome";
import Chat from "./Components/WaiterSreens/Chat/Chat";
import Order from "./Components/WaiterSreens/Order/Order";
import SetOrderFieldProduct from "./Components/AdminScreens/SetOrderFieldForProduct/SetOrderFieldProduct";
import PayOrderScreen from "./Components/WaiterSreens/Order/PayOrderScreen";
import EventAnalysis from "./Components/AdminScreens/eventAnalysis/eventAnalysis";
import ViewOrders from "./Components/WaiterSreens/Order/ViewOrders";
import SearchOrders from "./Components/WaiterSreens/SearchOrders/SearchOrders";
import Event from "./Components/AdminScreens/AdminHome/SubComponents/Event";
import AddProductsToCategories from "./Components/AdminScreens/AdminHome/SubComponents/SubCompOfEvent/AddProductsToCategorie";


import React, {Component} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@coreui/icons/css/all.css';
import './index.css'
import './App.css';
import './scss/style.scss';
import AdminLogin from "./Components/Login/AdminLogin";
import {withSnackbar} from "notistack";

require('dotenv').config();
const axios = require('axios');
const url = "http://192.168.1.115/Backend/";
localStorage.setItem('url', url)

if (localStorage.token) {
    loadApiKeyIntoHeaders();
}

function loadApiKeyIntoHeaders(token) {
    let localToken;
    if (token) {
        localToken = token;
    } else if (localStorage.token) {
        localToken = localStorage.token;
    }
    if (localToken) {
        // Add a request interceptor
        axios.interceptors.request.use(function (config) {
            config.headers["Auth-Token"] = localToken;
            config.headers["Cache-Control"] = "max-age=200";
            config.headers["Date-Sync"] = new Date().toUTCString();
            return config;
        });
    }
}

function removeApiKeyFromHeaders() {
    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        config.headers["Auth-Token"] = "";
        config.headers["Cache-Control"] = "max-age=200";
        config.headers["Date-Sync"] = "";
        return config;
    });

}


let printer = [];



checkTokenAndPrinters(true)

let interval = setInterval(() => checkTokenAndPrinters(), 10000);

function checkTokenAndPrinters(reload = false) {
    let local_url = url;
    local_url += "?action=checkToken";
    axios({
        method: 'post',
        url: local_url
    }).then((res) => {
        if (res.data.error === "#708") {
            localStorage.clear();
            if (reload && !window.location.pathname.includes('login')) {
                window.location.reload(false);
            }
        }
    })
}

class App extends Component {
    state = {
        currentUser: JSON.parse(localStorage.getItem('user')),
        token: localStorage.getItem('token'),
        navOpen: true,
        logout: false,
        printers: []
    };

    componentDidMount() {
        localStorage.setItem('url', url);
        if (localStorage.getItem("kassaModus") === "null") {
            localStorage.setItem("kassaModus", false);
        }
        if (localStorage.getItem("kassaPrinter") === "null") {
            localStorage.setItem("kassaPrinter", "192.168.1.110");
        }
        if (localStorage.token && localStorage.token !== "null") {
            let jwtDecode = require('jwt-decode');

            let expDate = jwtDecode(localStorage.token).exp;

            //Token check
            if (expDate < Date.now() / 1000) {
                //Token expired
                this.logout();
            } else {
                // Token Valid
                this.setState({token: localStorage.getItem('token')});
            }
        }
        this.getAllPrinters();
        this.interval = setInterval(this.getAllPrinters, 60000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
        clearInterval(interval);
    }

    setUser = (us, token) => {
        localStorage.setItem('user', JSON.stringify(us));
        this.setState({currentUser: us});

        localStorage.setItem('token', token);
        this.setState({token: token});

        loadApiKeyIntoHeaders(token);

        window.location.reload(false);
    };
    getAllPrinters = () => {
        axios({
            method: 'post',
            url: url + '?action=checkPrinters',
        }).then((res) => {
            if (res.data.length !== 0) {
                this.checkIfPrinterStateChanged(res.data)
            }
            printer = res.data
        });
    }
    checkIfPrinterStateChanged = (printers) => {
        if (printers.length === printer.length) {
            for (let i = 0; i < printer.length; i++) {
                if (printer[i].success !== printers[i].success) {
                    this.sendNot(printers[i].success, printers[i].printer.description)
                }
            }
        }
    }
    sendNot = (success, printer_name) => {
        if (!success) {
            this.props.enqueueSnackbar("Drucker " + printer_name + " ist nicht mehr erreichbar!!", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                autoHideDuration: 3000
            });
        } else {
            this.props.enqueueSnackbar("Drucker " + printer_name + " ist wieder erreichbar!!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                autoHideDuration: 3000
            });
        }

    }
    logout = () => {
        localStorage.clear();
        removeApiKeyFromHeaders()
        localStorage.setItem('url', process.env.REACT_APP_API_URL)

        this.setState({currentUser: null, token: null, logout: true, url: process.env.REACT_APP_API_URL});
        // window.location.reload(false);
    };


    render() {
        if (this.state.logout) {
            this.setState({logout: false})
            return (<Router><Redirect to={"/"}/></Router>)
        }

        if (window.location.pathname === "/od_login" || window.location.pathname === "/od_login/" || window.location.pathname === "od_login") {
            if (this.state.currentUser == null) {
                return (<Router>
                    <Redirect to='/od_login/'/>
                    <div className={"w-100 mr-2"}>
                        <Route exact path={'/login'} component={() =>
                            <Login currentUser={this.state.currentUser} setUser={this.setUser}/>
                        }/>
                        <Route exact path={'/od_login'} component={() =>
                            <AdminLogin currentUser={this.state.currentUser} setUser={this.setUser}/>
                        }/>
                    </div>
                </Router>)
            }
        }
        if (this.state.currentUser == null) {
            return (
                <Router>
                    <Redirect to='/login/'/>
                    <div className={"w-100 mr-2"}>
                        <Route exact path={'/login'} component={() =>
                            <Login currentUser={this.state.currentUser} setUser={this.setUser}/>
                        }/>
                        <Route exact path={'/od_login'} component={() =>
                            <AdminLogin currentUser={this.state.currentUser} setUser={this.setUser}/>
                        }/>
                    </div>
                </Router>)
        }

        return (
            <div>
                <div>
                    <Router>
                        {!this.state.currentUser.root ? (<div>
                            <Switch>
                                <Route exact path={'/loggedin/Home'} component={() =>
                                    <Home logout={this.logout} printers={printer}
                                          currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/PayAfterOrder/:ordernumber/'} render={(props) =>
                                    <PayOrderScreen {...props} currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/PayOrder/:ordernumber/:searchInput'} render={(props) =>
                                    <PayOrderScreen {...props} currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/Chat'} component={() =>
                                    <Chat currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/SearchOrders/:searchInput'} render={(props) =>
                                    <SearchOrders {...props} currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/SearchOrders/'} render={(props) =>
                                    <SearchOrders {...props} currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/Order/:tablenumber'} render={(props) =>
                                    <Order {...props} currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/OrderMore/:ordernumber'} render={(props) =>
                                    <Order {...props} currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/ViewOrder/:id/:searchinput'} render={(props) =>
                                    <ViewOrders {...props} currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/ViewAfterOrder/:id'} render={(props) =>
                                    <ViewOrders {...props} currentUser={this.state.currentUser}/>
                                }/>
                                <Route exact path={'/loggedin/SetOrderFieldProduct/'} component={() =>
                                    <SetOrderFieldProduct currentUser={this.state.currentUser}/>
                                }/>

                                <Route exact path={'/loggedin/EventAnalysis'} component={() =>
                                    <EventAnalysis currentUser={this.state.currentUser}/>
                                }/>
                                <Route>
                                    <Redirect to="/loggedin/Home"/>
                                    <Home logout={this.logout} currentUser={this.state.currentUser}/>
                                </Route>
                            </Switch>
                        </div>) : this.state.currentUser.root === true ? (
                                <div>
                                    <Switch>
                                        <Route exact path={'/loggedin/AdminHome/Event/:id'} render={(props) =>
                                            <Event {...props} currentUser={this.state.currentUser}/>
                                        }/>
                                        <Route exact path={'/loggedin/AdminHome/Event/Categories/:id'}
                                               render={(props) =>
                                                   <AddProductsToCategories {...props}
                                                                            currentUser={this.state.currentUser}/>
                                               }/>
                                        <Route>
                                            <Redirect to="/loggedin/AdminHome"/>
                                            <AdminHome logout={this.logout} currentUser={this.state.currentUser}/>
                                        </Route>
                                    </Switch>
                                </div>
                            ) :
                            <Route>
                                <Redirect to="/loggedin/Home"/>
                                <Home logout={this.logout} currentUser={this.state.currentUser}/>
                            </Route>
                        }
                    </Router>
                </div>
            </div>
        );


    }

}

export default withSnackbar(App);
