import React, {Component} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import DialogActions from "@material-ui/core/DialogActions";
import {Dialog as Dialog_mat} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";

class Dialog extends Component {
    render() {
        return (
            <div>
                <Dialog_mat aria-labelledby="simple-dialog-title" maxheight={'md'}
                            open={this.props.open} onClose={this.props.onClose}>
                    <DialogTitle id="simple-dialog-title">{this.props.dialogTitle}</DialogTitle>
                    <DialogContent className={"height-dialog"}>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" column={"true"}>
                                <TextField
                                    id={this.props.VariableName}
                                    className={'form-control'}
                                    type={this.props.type}
                                    autoFocus={true}
                                    autoComplete={"off"}
                                    onKeyPress={this.props.onKeyPress ? this.props.onKeyPress : null}
                                    placeholder={this.props.placeholder ? this.props.placeholder : "Wert eingeben"}
                                    value={this.props.value}
                                    onChange={this.props.onChange}
                                    name={this.props.VariableName}
                                />
                            </FormGroup>
                        </FormControl>
                    </DialogContent>
                    {this.getDialogActions()}
                </Dialog_mat>
            </div>
        );
    }

    getDialogActions = () => {
        if (this.props.saveDialog) {
            return (
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        {this.props.closeText ? this.props.closeText : "Abbrechen"}
                    </Button>
                    <Button onClick={this.props.saveDialog} color="primary">
                        {this.props.saveText ? this.props.saveText : "Speichern"}
                    </Button>
                </DialogActions>
            )
        }
    }
}

export default Dialog;