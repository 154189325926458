import React, {Component} from 'react';
import {Dialog, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker, DatePicker,
} from '@material-ui/pickers';
import Select from "react-select";
import {withSnackbar} from "notistack";

class DialogDayAnalysis extends Component {
    state = {
        selectedDate: new Date(),
        selectedPrinter: null
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.open}
                        onClose={this.props.onClose} PaperProps={{style: {overflowY: 'visible'}}}>
                    <DialogTitle id="simple-dialog-title">Datum auswählen</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                key={"-1"}
                                autoOk={true}
                                disableFuture
                                variant="inline"
                                format="dd.MM.yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                value={this.state.selectedDate}
                                onChange={this.handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </MuiPickersUtilsProvider>
                        <Select key={"-2"} onChange={this.SelectChange} value={this.state.selectedPrinter}
                                options={this.formatOptions()}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            Abbrechen
                        </Button>
                        <Button onClick={this.onSave} color="primary">
                            Weiter
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    formatOptions = () => {
        if (this.props.printerData) {
            let options = [];
            this.props.printerData.forEach(printer => printer.success !== false ? options.push({
                value: printer.printer.id,
                label: printer.printer.description
            }) : options.push({value: printer.printer.id, label: printer.printer.description, isDisabled: true}))
            return options;
        } else {
            return []
        }
    }
    SelectChange = (item, e) => {
        this.setState({
            selectedPrinter: item
        })
    }

    handleDateChange = (date) => {
        this.setState({
            selectedDate: date
        })
    }
    onSave = () => {
        if (this.state.selectedPrinter) {
            let printer = this.props.printerData.find(item => parseInt(item.printer.id) === parseInt(this.state.selectedPrinter.value));
            this.props.onSave(this.state.selectedDate, printer);
        } else {
            this.props.enqueueSnackbar("Keiner der Drucker ist angeschlossen oder erreichbar!!", {
                variant: 'error',
                autoHideDuration: 1400
            });
        }

    }
}

export default withSnackbar(DialogDayAnalysis);