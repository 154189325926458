import React, {Component} from 'react';
import {Checkbox, CircularProgress, Dialog, FormControlLabel, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Validations from "js-textfield-validation/src/Validation";
import {validateIPAddress} from "js-textfield-validation";

class DialogKassa extends Component {
    state = {
        errorMessage: null,
        ip_address: null,
        active: null
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.open}
                        onClose={this.props.onClose} PaperProps={{style: {overflowY: 'visible'}}}>
                    <DialogTitle id="simple-dialog-title">Kassamodus aktivieren</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <form id={"my-form"} onSubmit={this.onSubmit}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.active === null ? this.formatActive(localStorage.getItem("kassaModus")) : this.state.active}
                                        onChange={this.handleChange}
                                        name="kassaModus"
                                        color="primary"
                                    />
                                }
                                label="Kassamodus aktivieren"
                            />
                            <TextField className={"m-1 w-100"}
                                       type={"text"}
                                       required={true}
                                       autoComplete={"off"}
                                       value={this.state.ip_address === null ? localStorage.getItem("kassaPrinter") : this.state.ip_address}
                                       id={'ip_address'}
                                       onChange={this.handleChangeIP}
                                       name={'ip_address'}
                                       label="IP-Adresse des Druckers"
                                       placeholder={"192.168.1.100"}
                                       variant="outlined"/>
                        </form>
                        <div className="m-1 ">
                            <div className={"m-1 ml-5 w-100"}>
                                {this.state.errorMessage ?
                                    <span style={{color: "red"}}>{this.state.errorMessage}</span> : null}
                            </div>
                        </div>
                        {this.state.reload && <CircularProgress/>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            Abbrechen
                        </Button>
                        <Button type={"submit"} form="my-form" color="primary">
                            Abschicken
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    formatActive = (status) =>{
        if(status === "true" || status === true){
            return true;
        }else {
            return false;
        }
    }

    handleChangeIP = (e) => {
        let newIP = new Validations(e.target.value).noSpace().value;
        const isValidIP = validateIPAddress(newIP);
        if (e.target.value !== "") {
            if (!isValidIP) {
                if (!isNaN(e.target.value) || e.target.value.indexOf('.') > -1) {
                    this.setState({ip_address: e.target.value, errorMessage: "Keine Valide IP-Adresse"});
                }
            } else {
                if (!isNaN(e.target.value) || e.target.value.indexOf('.') > -1) {
                    this.setState({ip_address: e.target.value, errorMessage: null});
                }
            }
        } else {
            this.setState({ip_address: "", errorMessage: null})
        }
    }
    handleChange = (event) => {
        this.setState({
            active: event.target.checked
        })
    }
    onSubmit = (e) => {
        e.preventDefault();
        let ip = this.state.ip_address;
        if (ip === null) {
            this.setState({ip_address: localStorage.getItem("kassaPrinter")});
            ip = localStorage.getItem("kassaPrinter");
        }
        let status = this.state.active;
        if (status === null) {
            this.setState({active : localStorage.getItem("kassaModus")});
            status = localStorage.getItem("kassaModus");
        }
        //console.log("state",this.state.ip_address,this.state.active,localStorage.getItem("kassaPrinter"),localStorage.getItem("kassaModus"))
        let newIP = new Validations(ip.toString()).noSpace().value;
        const isValidIP = validateIPAddress(newIP);
        if (!isValidIP) {
            this.setState({errorMessage: "Keine Valide IP-Adresse"});
        } else {
            localStorage.setItem("kassaModus", status);
            localStorage.setItem("kassaPrinter", ip)
            this.setState({errorMessage: null,ip_address:null,active:null});
            this.props.onClose();
        }
        //console.log(localStorage.getItem("kassaModus"),localStorage.getItem("kassaPrinter"))
    }
}

export default DialogKassa;