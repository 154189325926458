import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Select from 'react-select'
import DialogContent from "@material-ui/core/DialogContent";

class EditDialog extends Component {
    state = {
        username: null,
        password: ""
    }

    render() {
        return (
            <div>
                <Dialog PaperProps={{style: {overflowY: 'visible'}}} open={this.props.open}>
                    <DialogTitle id="simple-dialog-title">Benutzer editieren</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <div className={"m-3"} style={{display: 'flex', flexFlow: 'column'}}>
                            <div className="form-group m-1 ">
                                <input
                                    style={{textAlign: 'center'}}
                                    id={'username'}
                                    className={'form-control'}
                                    type={'text'}
                                    placeholder={'Username'}
                                    value={this.state.username === null ? this.props.username : this.state.username}
                                    onBlur={this.props.setPageValue}
                                    onChange={this.setText}
                                    name={'username'}
                                />
                            </div>
                            <div className="form-group m-1">
                                <input
                                    style={{textAlign: 'center'}}
                                    id={'password'}
                                    className={'form-control'}
                                    type={'password'}
                                    placeholder={'Password'}
                                    value={this.state.password}
                                    onBlur={this.props.setPageValue}
                                    onChange={this.setText}
                                    name={'password'}
                                />
                            </div>
                            <div className="form-group m-1">
                                <Select
                                    onChange={this.props.handleChange}
                                    options={this.props.select_option}
                                    value={this.props.selection_role}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            Schließen
                        </Button>
                        <Button onClick={this.props.onSave} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        );
    }

    setText = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

}

export default EditDialog;