import React, {Component} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import DialogActions from "@material-ui/core/DialogActions";
import {Dialog as Dialog_mat} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {logo} from "../../assets/icons/logo";

class DialogExtras extends Component {


    componentDidMount() {
    }

    displayExtras = (extras, key) => {
        let temp = []
        extras.map((c, k) => (
            temp.push(<FormControlLabel
                key={k}
                value="end"
                control={<Checkbox
                    id={k.toString()}
                    onChange={(e) => this.props.onChange(key, e)}
                    name={c.extras.id}
                    checked={c.checked}
                />}
                label={c.extras.description}
                labelPlacement="end"
            />)
        ))
        return temp
    }

    render() {
        return (
            <div>
                <Dialog_mat aria-labelledby="simple-dialog-title" maxheight={'md'}
                            open={this.props.open} onClose={this.props.onClose}>
                    <DialogTitle id="simple-dialog-title">{this.props.dialogTitle}</DialogTitle>
                    <DialogContent className={"height-dialog"}>
                        {this.props.data.map((c, index) => (
                            <div>
                                <h5>{c.product.name}</h5>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" column={"true"}>
                                        {this.displayExtras(c.extras, index)}
                                    </FormGroup>
                                </FormControl>
                            </div>
                        ))}
                    </DialogContent>
                    {this.props.saveDialog ? (<DialogActions>
                        <Button onClick={() => this.handleClose(false)} color="primary">
                            {this.props.closeText ? this.props.closeText : "Abbrechen"}
                        </Button>
                        <Button onClick={() => this.handleClose(true)} color="primary">
                            {this.props.saveText ? this.props.saveText : "Speichern"}
                        </Button>
                    </DialogActions>) : null}
                </Dialog_mat>
            </div>
        );
    }


    handleClose = (bol) => {
        if (bol) {
            this.props.saveDialog()
        } else {
            this.props.onClose()
        }
    }

}

export default DialogExtras;