import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import "./event.css"
import {CCol, CRow} from "@coreui/react";
import SetOrderFieldProduct from "../../SetOrderFieldForProduct/SetOrderFieldProduct";
import Users from "./Users";
import Categories from "./SubCompOfEvent/Categories";
import {Redirect} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DialogProfile from "./SubCompOfEvent/DialogProfile";

let url;

class Event extends Component {

    state = {
        event: null,
        redirectToLink: "",
        redirect: false
    }

    componentDidMount() {
        url = "http://192.168.1.115/Backend/";
        if (this.props.match.params.id) {
            this.getEventData();
        }
    }

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }

        return (
            <div>
                {this.state.event ? (<div>
                        <AppBar position="static" className={"mb-3"} style={{margin: 0}}>
                            <Toolbar>
                                <IconButton edge={"start"} aria-label="edit" onClick={this.backHandler}>
                                    <ArrowBackIcon fontSize={"large"} className={"text-white"}/>
                                </IconButton>
                                <Typography variant="h6" align={"center"} style={{flexGrow: 1}}>
                                    Event Verwaltung
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <div className={"m-3"}>
                            <CRow className={"d-flex justify-content-center align-items-center"}>
                                <CCol sm={3}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div><TextField className={"m-1 w-100"} id="ID" label="ID"
                                                            value={this.state.event.event.id}
                                                            disabled={true}
                                                            variant="outlined"/>
                                                <TextField className={"m-1 w-100"} id="Event-Name"
                                                           value={this.state.event.event.description} disabled={true}
                                                           label="Event-Name"
                                                           variant="outlined"/>
                                                <TextField className={"m-1 w-100"} id="Startdatum"
                                                           value={this.getDataInRightFormat(this.state.event.event.startDate)}
                                                           disabled={true}
                                                           label="Startdatum"
                                                           variant="outlined"/>
                                                <TextField className={"m-1 w-100"} id="Enddatum"
                                                           value={this.getDataInRightFormat(this.state.event.event.endDate)}
                                                           disabled={true}
                                                           label="Enddatum"
                                                           variant="outlined"/></div>
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm={9}>
                                    <div className="card">
                                        <div className="card-body">
                                            <SetOrderFieldProduct event={this.state.event.event}
                                                                  reload={this.getEventData}
                                                                  orderfield={this.state.event.orderfield}
                                                                  products={this.state.event.products}/>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                            <CRow className={"d-flex"}>
                                <CCol sm={6}>
                                    <div className="card">
                                        <div className="card-body">
                                            <Users open={true} event={this.state.event.event} roles={this.state.event.roles}
                                                   users={this.state.event.users}/>
                                        </div>
                                    </div>
                                </CCol>
                                <CCol sm={6}>
                                    <div className="card">
                                        <div className="card-body">
                                            <Categories event={this.state.event.event}
                                                        categories={this.state.event.categories}/>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow></div>
                    </div>) :
                    <Backdrop open={true} docked="false">
                        <CircularProgress color="secondary"/>
                        <h2 className={"text-center ml-3"}>Loading...</h2>
                    </Backdrop>
                }
            </div>
        );
    }

    getDataInRightFormat = (dateFalse) => {
        let dateRight = new Date(dateFalse);
        return ('0' + dateRight.getDate()).slice(-2) + '.'
            + ('0' + (dateRight.getMonth() + 1)).slice(-2) + '.'
            + dateRight.getFullYear();
    }

    getEventData = () => {
        let id = this.props.match.params.id;
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append("event_id", id);

        axios({
            method: 'post',
            url: url + '?action=getEventById',
            data: params
        }).then((res) => {
            this.setState({
                event: res.data
            })
        });
    }

    backHandler = () => {
        this.setState({
            redirectToLink: "/loggedin/AdminHome/",
            redirect: true
        })
    }
}

export default Event;