const xlsx = require('xlsx');
const path = require('path');


class ExportService {
    makeNewFile = () => {
        return xlsx.utils.book_new();
    }

    download = (workBook, filePath) => {
        xlsx.writeFile(workBook, path.resolve(filePath));
    }

    exportExcel = (data, workSheetColumnNames, workSheetName, workBook) => {
        const workSheetData = [
            workSheetColumnNames,
            ...data
        ];
        const workSheet = xlsx.utils.aoa_to_sheet(workSheetData);
        xlsx.utils.book_append_sheet(workBook, workSheet, workSheetName);
        return workBook;
    }

    exportUsersToExcel = (data, workSheetColumnNames, workSheetName, workBook) => {
        return exportExcel(data, workSheetColumnNames, workSheetName, workBook);
    }
}

export const {makeNewFile, download, exportExcel, exportUsersToExcel} = new ExportService()
