import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

class DialogSettingsProduct extends Component {

    state = {
        product_name: null,
        product_amount: null,
        amount_enabled: null,
        errorMessage: ""
    }

    render() {
        return (
            <div>
                <Dialog PaperProps={{style: {overflowY: 'visible'}}} open={this.props.open}
                        onClose={this.props.onClose}>
                    <DialogTitle id="simple-dialog-title">Produkteinstellungen</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <h4 style={{textAlign: "center"}}>{this.props.product_name}</h4>
                        <div className={"m-3"} style={{display: 'flex', flexFlow: 'column'}}>
                            <form>
                                <div className="form-group m-1 ">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.amount_enabled === null ? this.props.amount_enabled : this.state.amount_enabled}
                                                onChange={this.handleCheckBox}
                                                name="amount_enabled"
                                                id={"amount_enabled"}
                                                color="primary"
                                            />
                                        }
                                        label="Lagerbestand prüfen"
                                    />
                                </div>
                                {(this.state.amount_enabled === null ? this.props.amount_enabled : this.state.amount_enabled) ? (
                                    <div className="form-group m-1 ">
                                        <TextField className={"m-1 w-100"}
                                                   type={"number"}
                                                   required={true}
                                                   value={this.state.product_amount === null ? this.props.product_amount : this.state.product_amount}
                                                   id={'product_amount'}
                                                   onChange={this.onInputChange}
                                                   name={'product_amount'}
                                                   label="Lagerbestand des Produktes"
                                                   variant="outlined"/>
                                    </div>) : <div/>}
                            </form>
                        </div>
                        {this.state.errorMessage ? <span style={{color: "red"}}>{this.state.errorMessage}</span> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">
                            Schließen
                        </Button>
                        <Button onClick={this.checkIfAllFieldsFilledOut} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.setState({
            product_amount: null,
            amount_enabled: null,
            errorMessage: null
        })
        this.props.onClose();
    }
    checkIfAllFieldsFilledOut = () => {
            this.setState({errorMessage: null})
            if (this.state.amount_enabled || this.props.amount_enabled) {
                if (this.state.product_amount === "" || !this.props.product_amount) {
                    this.setState({errorMessage: "Kein Lagerbestand eingeben "})
                } else {
                    this.setState({
                        errorMessage: null,
                        product_amount: null,
                        amount_enabled: true,
                    })
                    this.props.onSave(this.props.product_id, this.state.product_amount, this.props.product_amount, this.state.amount_enabled, this.props.amount_enabled)
                }
            } else {
                this.setState({
                    errorMessage: null,
                    product_amount: null,
                    amount_enabled: true,
                })
                this.props.onSave(this.props.product_id, this.state.product_amount, this.props.product_amount, this.state.amount_enabled, this.props.amount_enabled)
            }
    }
    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }
    handleCheckBox = () => {
        this.setState({amount_enabled: !this.state.amount_enabled})
    }
}

export default DialogSettingsProduct;