import React, {Component} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import DialogActions from "@material-ui/core/DialogActions";
import {Dialog as Dialog_mat} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";

class DialogComment extends Component {
    state = {
        comment: ""
    }

    render() {
        return (
            <div>
                <Dialog_mat aria-labelledby="simple-dialog-title" maxheight={'md'}
                            open={this.props.open} onClose={this.props.onClose}>
                    <DialogTitle id="simple-dialog-title">{this.props.dialogTitle}</DialogTitle>
                    <DialogContent className={"height-dialog"}>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" column={"true"}>
                                <TextField
                                    id="standard-multiline-flexible"
                                    label="Kommentar"
                                    autoComplete='off'
                                    aria-autocomplete={"none"}
                                    autoFocus='on'
                                    inputProps={{ pattern: "[a-z]" }}
                                    className={"w-100"}
                                    placeholder={"Füge ein Kommentar hinzu"}
                                    value={this.props.comment}
                                    onChange={this.onlyLetters}
                                />
                            </FormGroup>
                        </FormControl>
                    </DialogContent>
                    {this.getDialogActions()}
                </Dialog_mat>
            </div>
        );
    }
    onlyLetters = (event) => {
        if(/^[a-zA-Z0-9" "]+$/.test(event.target.value)){
            this.props.handleChange(event)
        }
    }

    handleClose = (bol) => {
        if (this.props.dialogTitle === "Kommentar für Bestellung") {
            if (bol) {
                this.props.saveDialogOrder()
            } else {
                this.props.onClose()
            }
        } else {
            if (bol) {
                this.props.saveDialog()
            } else {
                this.props.onClose()
            }
        }
    }

    getDialogActions = () => {
        if (this.props.saveDialog) {
            return (
                <DialogActions>
                    <Button onClick={() => this.handleClose(false)} color="primary">
                        {this.props.closeText ? this.props.closeText : "Abbrechen"}
                    </Button>
                    <Button onClick={() => this.handleClose(true)} color="primary">
                        {this.props.saveText ? this.props.saveText : "Speichern"}
                    </Button>
                </DialogActions>
            )
        }
    }
}

export default DialogComment;