import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class AddExtra extends Component {
    state = {
        extraName: "",
        price: "",
        errorMessage: ""
    }

    render() {
        return (
            <div>
                <Dialog PaperProps={{style: {overflowY: 'visible'}}} open={this.props.open}
                        onClose={this.onClose}>
                    <DialogTitle id="simple-dialog-title">Extra hinzufügen</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <div className={"m-3"} style={{display: 'flex', flexFlow: 'column'}}>
                            <div className="form-group m-1 ">
                                <TextField className={"m-1 w-100"}
                                           type={"text"}
                                           required={true}
                                           autoFocus={true}
                                           autoComplete={"off"}
                                           value={this.state.extraName}
                                           id={'extraName'}
                                           onChange={this.onInputChange}
                                           name={'extraName'}
                                           label="Name des Extras"
                                           variant="outlined"/>
                            </div>
                            <div className="form-group m-1 ">
                                <TextField className={"m-1 w-100"}
                                           type={"number"}
                                           required={true}
                                           InputProps={{inputProps: {min: 0}}}
                                           value={this.state.price}
                                           id={'price'}
                                           onChange={this.onInputChange}
                                           name={'price'}
                                           label="Preis des Extras"
                                           variant="outlined"/>
                            </div>
                        </div>
                        {this.state.errorMessage ? <span style={{color: "red"}}>{this.state.errorMessage}</span> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">
                            Schließen
                        </Button>
                        <Button onClick={this.checkIfAllFieldsFilledOut} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }
    onClose = () => {
        this.setState({
            extraName: "",
            price: ""
        })
        this.props.onClose();
    }
    checkIfAllFieldsFilledOut = () => {
        if (this.state.extraName !== "" && this.state.price !== "") {
            this.setState({errorMessage: null})
            this.props.onSave(this.state.extraName, this.state.price)
            this.onClose();
        } else {
            this.setState({errorMessage: "Kein Namen oder Preis eingegeben"})
        }
    }
}

export default AddExtra;