import React, {Component} from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {CCol, CRow} from "@coreui/react";
import IconButton from "@material-ui/core/IconButton";
import AddEventDialog from "../Dialog/Dialog_home/DialogCEEvent";
import {Redirect} from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyEvent from "../Dialog/Dialog_home/DialogCopyEvent"
import ImportExportIcon from '@material-ui/icons/ImportExport';
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import {withSnackbar} from "notistack";

let url;

class Home extends Component {
    state = {
        eventAddOpen: false,
        event_name: "",
        start_date: undefined,
        end_date: undefined,
        events: [],
        selection: null,
        redirect: false,
        redirectToLink: "",
        editEventOpen: false,
        copyEventOpen: false,
        search_field_event: ""
    }

    componentDidMount() {
        url = "http://192.168.1.115/Backend/";
        this.getAllEvents();
    }


    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        if (!this.props.open) {
            return (<div/>)
        }
        return (
            <div>

                {this.state.eventAddOpen === true ?
                    <AddEventDialog open={this.state.eventAddOpen} title={"Event Hinzufügen"} setText={this.setText}
                                    name={this.state.event_name}
                                    onClose={this.handleAddEvent} setPageValue={this.setPageValue}
                                    setStartDay={this.setStartDay}
                                    onSave={this.addEvent} setEndDay={this.setEndDay} start_date={this.state.start_date}
                                    end_date={this.state.end_date}/> :
                    this.state.editEventOpen === true ?
                        <AddEventDialog open={this.state.editEventOpen} title={"Event Ändern"} setText={this.setText}
                                        name={this.state.event_name}
                                        onClose={this.handleChangeEvent} setPageValue={this.setPageValue}
                                        setStartDay={this.setStartDay}
                                        onSave={this.changeEvent} setEndDay={this.setEndDay}
                                        start_date={this.state.start_date}
                                        end_date={this.state.end_date}/> :
                        this.state.copyEventOpen === true ?
                            <CopyEvent open={this.state.copyEventOpen} title={"Event Kopieren"}
                                       onClose={this.closeCopyEvent}
                                       saveDialog={this.saveCopy} DialogAction={true}
                                       dialogTitle={this.state.selection.data} events={this.state.events}
                            /> : null}

                <div className="card">
                    <div className="card-body">
                        <div style={{flexGrow: 1}} className={"no_select"}>
                            <CRow className={"ml-2"}>
                                <h2>Events</h2>
                                <div className={"ml-2"}>
                                    <IconButton aria-label="edit" onClick={this.handleAddEvent}>
                                        <AddIcon className={"text-body"}/>
                                    </IconButton>
                                </div>
                                <CCol style={{overflow: "hidden"}}>
                                    <div style={{float: "right"}}>
                                        <TextField
                                            className={"mb-2 mt-2"}
                                            variant={"outlined"}
                                            id={"search_field_event"}
                                            name={"search_field_event"}
                                            value={this.state.search_field_event}
                                            onChange={this.onTextChange}
                                            label="Search"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                            <div style={{height: 400, width: '100%'}}>
                                <DataGrid
                                    rows={this.state.events}
                                    filterModel={{
                                        items: [
                                            {
                                                columnField: 'description',
                                                operatorValue: 'contains',
                                                value: this.state.search_field_event
                                            },
                                        ],
                                    }}
                                    onRowSelected={(newSelection) => {
                                        this.setState({
                                            selection: newSelection
                                        });
                                    }} pageSize={5}
                                    columns={[
                                        {
                                            field: 'id',
                                            headerName: 'ID',
                                            width: 70,
                                            headerAlign: "center",
                                            filterable: false,
                                            sortable: false,
                                        },
                                        {
                                            field: 'description',
                                            headerName: 'Name',
                                            width: 130,
                                            headerAlign: "center",
                                            renderCell: (params) => (
                                                <Tooltip title={params.row.description}>
                                                    <span className="table-cell-trucate">{params.row.description}</span>
                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            field: 'startDate',
                                            headerName: 'Von',
                                            width: 160,
                                            headerAlign: "center",
                                            valueGetter: (params) => this.getDataInRightFormat(params.value)
                                        },
                                        {
                                            field: 'endDate',
                                            headerName: 'Bis',
                                            width: 160,
                                            headerAlign: "center",
                                            valueGetter: (params) => this.getDataInRightFormat(params.value)
                                        },
                                        {
                                            field: 'string',
                                            width: 200,
                                            headerAlign: "center",
                                            align: "center",
                                            headerName: 'View/Editieren/Kopieren/Exportieren',
                                            filterable: false,
                                            renderCell: () => (
                                                <div>
                                                    <IconButton aria-label="open" onClick={() => this.openEvent()}>
                                                        <VisibilityIcon fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                    <IconButton aria-label="edit" onClick={this.openEditEvent}>
                                                        <EditIcon fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                    <IconButton aria-label="edit" onClick={this.openCopyEvent}>
                                                        <FileCopyIcon fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                    <IconButton aria-label="edit" onClick={this.exportEvent}>
                                                        <ImportExportIcon fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <input type="file" onChange={this.onFileChange}/>
            </div>
        );
    }

    onFileChange = (event) => {
        // this.setState({ selectedFile: event.target.files[0] });
        let selectedFile = event.target.files[0]
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "myFile",
            selectedFile,
            selectedFile.name
        );

        // Details of the uploaded file

        const reader = new FileReader();
        reader.addEventListener('progress', (event) => {
            if (event.loaded && event.total) {
                const percent = (event.loaded / event.total) * 100;
            }
        });
        reader.readAsText(selectedFile);
        reader.onload = this.importEvent
    };
    importEvent = (data) => {

        this.props.enqueueSnackbar("Die Daten werden verarbeitet!", {
            variant: 'success',
            autoHideDuration: 3500
        });

        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('data', data.target.result);
        axios({
            method: 'post',
            url: url + '?action=importEvent',
            data: params
        }).then((res) => {
    
        });
    }


    closeCopyEvent = () => {
        this.setState({
            copyEventOpen: false
        })
    }
    saveCopy = (types) => {
        this.setState({
            copyEventOpen: false
        })
        this.sendCopyPartEvents(types)
    }

    sendCopyPartEvents = (types) => {

        let events = [{event: this.state.events.find(e => e.id === types.selected), checked: true}]

        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('old_event_id', this.state.selection.data.id);
        if (JSON.parse(types.selected) !== "newEvent") {
            params.append('new_event_ids', JSON.stringify(events));
        } else {
            params.append('new_event_ids', JSON.stringify([{event: this.state.selection.data, checked: true, new: true}]));
        }
        params.append('types', JSON.stringify(types.types));
        axios({
            method: 'post',
            url: url + '?action=copySelectedEvent',
            data: params
        }).then((res) => {
            this.getAllEvents();
        });

    }

    onTextChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    ;
    getDataInRightFormat = (dateFalse) => {
        let dateRight = new Date(dateFalse);
        return ('0' + dateRight.getDate()).slice(-2) + '.'
            + ('0' + (dateRight.getMonth() + 1)).slice(-2) + '.'
            + dateRight.getFullYear();
    }

    getAllEvents = () => {
        const axios = require('axios');

        axios({
            method: 'post',
            url: url + '?action=getAllEvents&sort=desc',
        }).then((res) => {
            this.setState({
                events: res.data
            })
        });

    }

    handleAddEvent = () => {
        this.setState({
            eventAddOpen: !this.state.eventAddOpen,
            event_name: ""
        })
    }
    handleChangeEvent = () => {
        this.setState({
            editEventOpen: !this.state.editEventOpen,
            start_date: null,
            end_date: null,
            event_name: "",
            event_id: null
        })
    }
    setStartDay = (inputDate) => {
        this.setState({
            start_date: inputDate
        })
    }
    setEndDay = (inputDate) => {
        this.setState({
            end_date: inputDate
        })
    }
    setText = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    setPageValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    addEvent = () => {
        if (this.checkifFilledOut()) {
            const axios = require('axios');

            const params = new URLSearchParams();


            params.append('description', this.state.event_name);
            params.append('startdate', formatDate(this.state.start_date));
            params.append('enddate', formatDate(this.state.end_date));
            axios({
                method: 'post',
                url: url + '?action=addEvent',
                data: params
            }).then((res) => {
                this.getAllEvents();
                this.handleAddEvent();
            });
        }

        function formatDate(date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        }
    }
    changeEvent = () => {
        if (this.checkifFilledOut() && this.state.event_id) {
            const axios = require('axios');

            const params = new URLSearchParams();
            params.append('event_id', this.state.event_id);
            params.append('description', this.state.event_name);
            params.append('startdate', formatDate(this.state.start_date));
            params.append('enddate', formatDate(this.state.end_date));
            axios({
                method: 'post',
                url: url + '?action=editEvent',
                data: params
            }).then((res) => {
                this.getAllEvents();
                this.handleChangeEvent();
            });
        }

        function formatDate(date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        }
    }
    checkifFilledOut = () => {
        return !!(this.state.start_date && this.state.event_name !== "" && this.state.end_date);
    }
    openEvent = () => {

        this.setState({
            redirectToLink: "/loggedin/AdminHome/Event/" + this.state.selection.data.id,
            redirect: true
        })
    }
    openEditEvent = () => {

        this.setState({
            editEventOpen: true,
            end_date: this.state.selection.data.endDate,
            start_date: this.state.selection.data.startDate,
            event_name: this.state.selection.data.description,
            event_id: this.state.selection.data.id
        })
    }
    openCopyEvent = (e) => {
        this.setState({
            copyEventOpen: true
        })
    }

    exportEvent = () => {
        this.props.enqueueSnackbar("Der Export wird vorbereitet!", {
            variant: 'success',
            autoHideDuration: 3500
        });

        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('event_id', this.state.selection.data.id);
        axios({
            method: 'post',
            url: url + '?action=exportEvent',
            data: params
        }).then((res) => {
            if (!res.data.error) {
                var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(res.data));
                var downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute("href", dataStr);
                downloadAnchorNode.setAttribute("download", this.state.selection.data.description + ".json");
                document.body.appendChild(downloadAnchorNode);
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            }
        });
    }


    closeEditEvent = () => {
        this.setState({
            editEventOpen: true
        })
    }

    getAllOrganisations = () => {

    }
}

export default withSnackbar(Home);