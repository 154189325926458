import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

class DialogCEEvent extends Component {
    state = {
        event_name: ""
    }

    componentDidMount() {
        if (this.props.title === "Event Ändern") {
            this.setState({
                event_name: this.props.name
            })
        }

    }


    render() {
        return (
            <div>
                <Dialog PaperProps={{style: {overflowY: 'visible'}}} open={this.props.open}
                        onClose={this.props.onClose}>
                    <DialogTitle id="simple-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <div className={"m-3"} style={{display: 'flex', flexFlow: 'column'}}>
                            <div className="form-group m-1 ">
                                <input
                                    style={{textAlign: 'center'}}
                                    id={'event_name'}
                                    className={'form-control'}
                                    type={'text'}
                                    autoComplete={"off"}
                                    required={true}
                                    placeholder={'Event-Name'}
                                    value={this.state.event_name}
                                    onBlur={this.onInputBlur}
                                    onChange={this.onInputChange}
                                    name={'event_name'}
                                />
                            </div>
                            <div className="form-group m-1">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        margin="normal"
                                        id="date-picker-start"
                                        label="Start-Datum"
                                        format="dd.MM.yyyy"
                                        maxDate={this.props.end_date}
                                        value={this.props.start_date}
                                        onChange={this.props.setStartDay}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="form-group m-1">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        autoOk
                                        margin="normal"
                                        id="date-picker-end"
                                        label="End-Datum"
                                        format="dd.MM.yyyy"
                                        minDate={this.props.start_date}
                                        value={this.props.end_date}
                                        onChange={this.props.setEndDay}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            Schließen
                        </Button>
                        <Button onClick={this.props.onSave} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onInputBlur = (e) => {
        this.props.setPageValue(e);
    }
    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }


}

export default DialogCEEvent;