import React, {Component} from 'react';
import {CCol, CRow, CWidgetSimple} from "@coreui/react";
import Card from "@material-ui/core/Card";
import {CardHeader} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {CChart, CChartBar} from "@coreui/react-chartjs";
import ChartLineSimple from "../../../charts/ChartLineSimple";
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

class OrdersEvent extends Component {
    state = {
        date: new Date(2021, 2, 5),
        activeDataSet: [1, 1, 1],
        maxDate: new Date(2021, 2, 5),
        minDate: Date.now(),
        DataSet: [],
        openSnackbar: false,
        toDisable: null
    }

    componentDidMount() {
        this.setFirstDataSetDefault();
    }

    render() {
        const doughnut = {
            responsive: true,
            tooltips: {
                enabled: true,
                mode: 'label',
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']] + " Bestellung/en";
                    }
                },
            },
            legend: {
                display: true,
                position: 'top',
            },
            plugins: {
                labels: {
                    render: function (args) {
                        return args.value;
                    },
                    fontColor: "#fff",
                    arc: true
                }
            }
        };
        if (!this.props.show) {
            return (<div/>);
        }
        return (
            <div>

                <div className={"m-1 mt-2 pb-5"}>
                    <h2 className={"text-left ml-2"}>Bestellungsübersicht</h2>
                    <div className={"justify-content-center mb-4"}>
                        <CRow>
                            <CCol sm={6}>
                                <Card className={"card_border m-05 m-1"}>
                                    <CardHeader className={"pb-0 ml-4"} title={"Bestellungen pro Tag"}/>
                                    <CardContent className={"pt-0"}>
                                        <CChartBar
                                            datasets={this.props.data_graph_order ? this.props.data_graph_order.datasets : []}
                                            labels={this.props.data_graph_order ? this.props.data_graph_order.labels : []}
                                            options={this.props.options_graph_product}
                                        />
                                    </CardContent>
                                </Card>
                            </CCol>
                            <CCol sm={6}>
                                <Card className={"card_border m-05 m-1"}>
                                    <CRow>
                                        <CCol sm={8}>
                                            <CardHeader className={"pb-0 ml-4"} title={"Bestellungen pro Stunde"}/>
                                        </CCol>
                                        <CCol sm={4}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    autoOk
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    minDate={this.state.minDate}
                                                    maxDate={this.state.maxDate}
                                                    format="dd-MM-yyyy"
                                                    value={this.state.date}
                                                    onChange={this.setSelectedDay}
                                                    shouldDisableDate={this.disableDay}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </CCol>
                                    </CRow>
                                    <CardContent className={"pt-0"}>

                                        <CChart type="doughnut"
                                                datasets={this.state.DataSet.length !== 0 ? this.state.DataSet.datasets : []}
                                                labels={this.state.DataSet.length !== 0 ? this.state.DataSet.labels : []}
                                                options={doughnut}/>
                                    </CardContent>
                                </Card>
                            </CCol>
                        </CRow>
                    </div>
                    <CRow>
                        <CCol sm="4" lg="2">
                            <CWidgetSimple header="Bestellungen" text={this.props.amount_orders}>
                                <ChartLineSimple style={{height: '31px'}} borderColor="primary"/>
                            </CWidgetSimple>
                        </CCol>
                        <CCol sm>

                        </CCol>
                    </CRow>
                </div>
            </div>
        );
    }

    getDaysArray = (start, end) => {
        let arr = [];
        let dt = new Date(start);
        for (; dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };
    disableDatesBetween = () => {
        let data = this.props.data_orders_hours;
        if (data.length !== 0) {
            let date = data[0].date.split("-");
            let minDate = new Date(date[2], date[1] - 1, date[0]);
            date = data[data.length - 1].date.split("-");
            let maxDate = new Date(date[2], date[1] - 1, date[0]);
            let datesBetween = this.getDaysArray(maxDate, minDate);
            data.forEach(c => {
                let date = c.date.split("-");
                date = new Date(date[2], date[1] - 1, date[0]);
                for (let i = 0; i < datesBetween.length; i++) {
                    if (datesBetween[i].toString() === date.toString()) {
                        datesBetween.splice(i, 1);
                    }

                }
            })
            this.setState({
                toDisable: datesBetween
            })
        } else {
            this.setState({
                toDisable: []
            })
        }
    }
    checkIfExits = (date) => {
        let disable = this.state.toDisable;
        let help = true;
        if (disable) {
            disable.forEach(c => {
                if (c.toString() === date.toString()) {
                    help = false
                }
            })
        }
        return help;
    }
    disableDay = (date) => {
        return !this.checkIfExits(date)
    }

    setFirstDataSetDefault = () => {
        let data = this.props.data_orders_hours;
        // //console.log(data)
        if (data.length !== 0) {
            let date = data[0].date.split("-");
            let minDate = new Date(date[2], date[1] - 1, date[0]);
            date = data[data.length - 1].date.split("-");
            let maxDate = new Date(date[2], date[1] - 1, date[0]);

            let labels = [];
            let dataDataSet = [];
            data[0].orders.forEach(c => {
                labels.push(c.hour + " Uhr")
                dataDataSet.push(c.amount)
            });
            let doughnut = {
                labels: labels,
                datasets: [
                    {
                        data: dataDataSet,
                        backgroundColor: [
                            "#003f5c",
                            "#2f4b7c",
                            "#665191",
                            "#a05195",
                            "#d45087",
                            "#f95d6a",
                            "#ff7c43",
                            "#ffa600",
                            '#FF6384',
                            '#36A2EB',
                            '#FFCE56',
                            "#003f5c",
                            "#2f4b7c",
                            "#665191",
                            "#a05195",
                            "#d45087",
                            "#f95d6a",
                            "#ff7c43",
                            "#ffa600",
                            '#FF6384',
                            '#36A2EB',
                            '#FFCE56',
                        ],
                        borderColor: [
                            "#fff"
                        ],
                        hoverBackgroundColor: [
                            "#003f5c",
                            "#2f4b7c",
                            "#665191",
                            "#a05195",
                            "#d45087",
                            "#f95d6a",
                            "#ff7c43",
                            "#ffa600",
                            '#FF6384',
                            '#36A2EB',
                            '#FFCE56',
                            "#003f5c",
                            "#2f4b7c",
                            "#665191",
                            "#a05195",
                            "#d45087",
                            "#f95d6a",
                            "#ff7c43",
                            "#ffa600",
                            '#FF6384',
                            '#36A2EB',
                            '#FFCE56',
                        ],
                    }],
            };
            this.disableDatesBetween();
            this.setState({
                minDate: maxDate,
                maxDate: minDate,
                date: minDate,
                DataSet: doughnut
            })
        } else {
            this.setState({
                minDate: Date.now(),
                maxDate: Date.now(),
                date: Date.now(),
                DataSet: []
            })
        }
    }
    findWithAttr = (array, attr, value) => {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    getDataOfSelectedDay = (inputDate) => {
        function str_pad(n) {
            return String("00" + n).slice(-2);
        }

        let data = this.props.data_orders_hours;
        if (data.length !== 0) {
            this.setState({
                DataSet: []
            })
            let date = new Date(inputDate)
            let month = date.getMonth() + 1;
            let help = str_pad(date.getDate()) + "-" + str_pad(month) + "-" + date.getUTCFullYear();
            // console.log(help, data)
            let index = this.findWithAttr(data, "date", help)
            if (index !== -1) {
                let labels = [];
                let dataDataSet = [];
                data[index].orders.forEach(c => {
                    labels.push(c.hour + " Uhr")
                    dataDataSet.push(c.amount)
                });
                let doughnut = {
                    labels: labels,
                    datasets: [
                        {
                            data: dataDataSet,
                            backgroundColor: [
                                "#003f5c",
                                "#2f4b7c",
                                "#665191",
                                "#a05195",
                                "#d45087",
                                "#f95d6a",
                                "#ff7c43",
                                "#ffa600",
                                '#FF6384',
                                '#36A2EB',
                                '#FFCE56',
                                "#003f5c",
                                "#2f4b7c",
                                "#665191",
                                "#a05195",
                                "#d45087",
                                "#f95d6a",
                                "#ff7c43",
                                "#ffa600",
                                '#FF6384',
                                '#36A2EB',
                                '#FFCE56',
                            ],
                            hoverBackgroundColor: [
                                "#003f5c",
                                "#2f4b7c",
                                "#665191",
                                "#a05195",
                                "#d45087",
                                "#f95d6a",
                                "#ff7c43",
                                "#ffa600",
                                '#FF6384',
                                '#36A2EB',
                                '#FFCE56',
                                "#003f5c",
                                "#2f4b7c",
                                "#665191",
                                "#a05195",
                                "#d45087",
                                "#f95d6a",
                                "#ff7c43",
                                "#ffa600",
                                '#FF6384',
                                '#36A2EB',
                                '#FFCE56',
                            ],
                        }],
                };
                this.setState({
                    DataSet: doughnut
                })
            }
        } else {
            this.setState({
                DataSet: []
            })
        }
    }
    setSelectedDay = (inputDate) => {
        this.setState({
            date: inputDate
        })
        // console.log(inputDate)
        this.getDataOfSelectedDay(inputDate)
    }
}

export default OrdersEvent;