import React, {Component} from 'react';
import jwt_decode from "jwt-decode";
import {Link, Redirect} from "react-router-dom";

let url;
const axios = require('axios');

class AdminLogin extends Component {
    state = {
        name: "",
        password: "",
        loggedIn: false
    }

    componentDidMount() {
        //console.log("admin")
        url = "http://192.168.1.115/Backend/"
    }

    render() {
        if (this.props.currentUser != null || this.state.loggedIn) {
            return <Redirect to='/'/>
        }
        return (
            <div>
                <div style={{
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: "#ccc"
                }}
                     className={"d-flex main-content flex-column align-items-center justify-content-around"}>

                    <div>

                        <div style={{width: "350px"}} className={"p-4 bg-white"}>
                            <div>
                                <div className={"d-flex flex-column  align-items-center "}>

                                    <h4 className={"pt-2 pb-4"}>Admin-Login</h4>
                                </div>
                            </div>
                            <div className={"d-flex m-1 justify-content-center"}>
                                <form onSubmit={this.loginAjax} className="login"
                                      style={{display: 'flex', flexFlow: 'column'}}>
                                    <div className="form-group">
                                        <input
                                            id={'name'}
                                            autoComplete={"username"}
                                            autoFocus={true}
                                            className={'form-control rounded-pill'}
                                            type={'text'}
                                            placeholder={'Benutzername'}
                                            value={this.state.name}
                                            onChange={this.textChange}
                                            name={'name'}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            autoComplete={"current-password"}
                                            id={'passwordAdminLogin'}
                                            className={'form-control rounded-pill'}
                                            type={'password'}
                                            placeholder={'Password'}
                                            value={this.state.password}
                                            onChange={this.textChange}
                                            name={'password'}
                                        />
                                    </div>
                                    <Link className={"text-body"} style={{textDecoration: "none"}}
                                          to="/login/">Login</Link>
                                    {this.state.errMsg ?? <p>{this.state.errMsg}</p>}
                                    <input type={'submit'} value={"Login"} className={'btn btn-primary rounded-pill'}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    textChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loginAjax = (e) => {
        e.preventDefault();

        const params = new URLSearchParams();

        params.append('username', this.state.name);
        params.append('password', this.state.password);

        this.setState({loading: true})
        axios({
            method: 'post',
            url: url + '?action=loginAdmin',
            data: params
        }).then((res) => {
            //console.log(res.data)
            if (res.data.error !== "#710" && res.data) {
                this.login(res.data.token);
            } else {
                this.setState({errMsg: "Passwort ist inkorrekt", password: ""})
            }
            this.setState({loading: false})
        })
    }
    login = (token) => {
        let decoded = jwt_decode(token);
        this.props.setUser(decoded.data, token)
        this.setState({loggedIn: true})
    }
}

export default AdminLogin;