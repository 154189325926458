import React, {Component} from 'react';
import {CCol, CRow} from "@coreui/react";
import IconButton from "@material-ui/core/IconButton";
import {DataGrid} from "@material-ui/data-grid";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {Visibility} from "@material-ui/icons";
import {Redirect} from "react-router-dom";
import AddCategorieToEvent from "../../Dialog/Dialog_CategoriesProducts/AddCategorieToEvent";
import EditCategorie from "../../Dialog/Dialog_CategoriesProducts/EditCategorie";
import ConfirmDialog from "../../Dialog/Dialogs_user/ConfirmDialog";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

let url;
const axios = require('axios');


class Categories extends Component {
    state = {
        redirect: false,
        redirectToLink: "",
        selection: null,
        categorie_id: 0,
        categorie_name: "",
        categories: [],
        openAddCategories: false,
        openEditCategorie: false,
        delete_confirm: false,
        search_field_cat: ""
    }

    componentDidMount() {
        url = localStorage.getItem('url');
        this.getEventData(this.props.event.id);
    }

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        return (
            <div>
                {/*
                <AddSubcategorieToCategorie open={this.state.openAddCategories} onSave={this.saveCategorie}
                                            onClose={this.handleAddCategoriesDialog}/>*/}
                <ConfirmDialog
                    title="Kategorie löschen?"
                    open={this.state.delete_confirm}
                    setOpen={this.handleOpenConfirm}
                    onConfirm={this.deleteCategorie}
                    text={"Wollen sie wirklich diese Kategorie löschen?"}
                />
                <AddCategorieToEvent open={this.state.openAddCategories} onSave={this.saveCategorie}
                                     onClose={this.handleAddCategoriesDialog}/>
                <EditCategorie open={this.state.openEditCategorie} categorie_id={this.state.categorie_id}
                               categorie_name={this.state.categorie_name} onSave={this.editCategorie}
                               onClose={this.handleEditCategoriesDialog}/>
                <div className={"mb-5 pb-3 pt-3 no_select"}>
                    <div style={{
                        padding: "0.2rem",
                        display: 'flex'
                    }}>
                        <div style={{flexGrow: 1}}>
                            <CRow className={"ml-2"}>
                                <h2>Kategorien</h2>
                                <div className={"ml-2"}>
                                    <IconButton aria-label="edit" onClick={this.handleAddCategoriesDialog}>
                                        <AddIcon className={"text-body"}/>
                                    </IconButton>
                                </div>
                                <CCol style={{overflow: "hidden"}}>
                                    <div style={{float: "right"}}>
                                        <TextField
                                            className={"mb-2 mt-2"}
                                            variant={"outlined"}
                                            id={"search_field_cat"}
                                            name={"search_field_cat"}
                                            value={this.state.search_field_cat}
                                            onChange={this.textChange}
                                            label="Search"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                            <DataGrid
                                rows={this.state.categories}
                                style={{overflow: "scroll"}}
                                columnBuffer={10}
                                density={"standard"}
                                scrollbarSize={10}
                                autoHeight={true}
                                filterModel={{
                                    items: [
                                        {
                                            columnField: 'description',
                                            operatorValue: 'contains',
                                            value: this.state.search_field_cat
                                        },
                                    ],
                                }}
                                onRowSelected={(newSelection) => {
                                    this.setState({
                                        selection: newSelection
                                    });
                                }}
                                columns={
                                    [
                                        {field: 'id', headerName: 'ID', width: 70, headerAlign: "center",},
                                        {
                                            field: 'description', headerName: 'Name', width: 130, headerAlign: "center",
                                            renderCell: (params) => (
                                                <Tooltip title={params.row.description} >
                                                    <span className="table-cell-trucate">{params.row.description}</span>
                                                </Tooltip>
                                            ),},
                                        {
                                            field: 'string',
                                            width: 200,
                                            headerAlign: "center",
                                            align: "center",
                                            headerName: 'Löschen/Editieren',
                                            filterable: false,
                                            renderCell: () => (
                                                <div>
                                                    <IconButton aria-label="edit"
                                                                onClick={this.openAddProductsToCategories}>
                                                        <Visibility fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                    <IconButton aria-label="delete"
                                                                onClick={() => this.openEditCategorie(this.state.selection.data)}>
                                                        <EditIcon fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                    <IconButton aria-label="delete"
                                                                onClick={() => this.setCategorie(this.state.selection.data.id)}>
                                                        <DeleteIcon fontSize="small" className={"text-body"}/>
                                                    </IconButton>
                                                </div>
                                            ),
                                        },
                                    ]
                                }
                                pageSize={5}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    textChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveCategorie = (name) => {
        //console.log(name)
        const params = new URLSearchParams();
        params.append('event_id', this.props.event.id);
        params.append('description', name);

        axios({
            method: 'post',
            url: url + '?action=addCategories',
            data: params
        }).then((res) => {
            this.getEventData(this.props.event.id);
            this.handleAddCategoriesDialog();
            //console.log(res.data)
        });
    }

    handleAddCategoriesDialog = () => {
        this.setState({
            openAddCategories: !this.state.openAddCategories
        })
    }
    handleOpenConfirm = () => {
        this.setState({
            delete_confirm: !this.state.delete_confirm
        })
    }
    openEditCategorie = (data) => {
        this.setState({
            categorie_id: data.id,
            categorie_name: data.description
        })
        this.handleEditCategoriesDialog();
    }
    handleEditCategoriesDialog = () => {
        this.setState({
            openEditCategorie: !this.state.openEditCategorie,
        })
    }
    editCategorie = (id, name, prop_name) => {

        if (!name) {
            name = prop_name
        }

        const params = new URLSearchParams();
        params.append("category_id", id);
        params.append("description", name);
        params.append("event_id", this.props.event.id);
        axios({
            method: 'post',
            url: url + '?action=editCategories',
            data: params
        }).then((res) => {
            //console.log(res.data)
            this.getEventData(this.props.event.id);
            this.handleEditCategoriesDialog();
        });

    }
    deleteCategorie = () => {
        const params = new URLSearchParams();
        params.append("category_id", this.state.categorie_id);

        axios({
            method: 'post',
            url: url + '?action=deleteCategories',
            data: params
        }).then((res) => {
            this.getEventData(this.props.event.id)
        });
    }
    setCategorie = (id) => {
        this.setState({
            categorie_id: id
        })
        this.handleOpenConfirm();
    }
    getEventData = (id) => {


        const params = new URLSearchParams();
        params.append("event_id", id);

        axios({
            method: 'post',
            url: url + '?action=getEventById',
            data: params
        }).then((res) => {
            //console.log(res.data)
            this.setState({
                event: res.data,
                categories: res.data.categories
            })
        });
    }

    saveSubCategorie = (categorie_name, ip_address, printer_description) => {
        const params = new URLSearchParams();
        params.append('event_id', this.props.event.id)
        params.append("name", categorie_name);
        params.append("ip_address", ip_address);
        params.append("printer_description", printer_description);
        params.append("categories_id", printer_description);


        axios({
            method: 'post',
            url: url + '?action=addCategorie',
            data: params
        }).then((res) => {
            this.getEventData(this.props.match.params.id);
            this.handleAddCategoriesDialog();
            //console.log(res.data)
        });
    }

    openAddProductsToCategories = () => {
        this.setState({
            redirectToLink: "/loggedin/AdminHome/Event/Categories/" + this.state.selection.data.id,
            redirect: true
        })
    }
}


export default Categories;