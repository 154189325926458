import React, {Component} from 'react';
import BackIcon from '@material-ui/icons/ArrowBackIos';

import {Col, Row} from "react-bootstrap"
import "./Chat.css"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Redirect} from "react-router-dom";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

let url;

class Chat extends Component {
    state = {
        waiter_name: JSON.parse(localStorage.getItem('user')).username,
        waiter_id: JSON.parse(localStorage.getItem('user')).id,
        redirect: false,
        chat: null,
        displayMessagesArr: [],
        message: "",
        importance: 0,
        dialogOpen: false,
        styleField: "hidden",
        clickedMessageObj: null,
        clickedMessage: "",
        clickedImportance: 0
    }

    componentDidMount() {
        url = localStorage.getItem('url');
        this.getAllMessages();
        this.interval = setInterval(() => this.getNewMassages(), 2500);
    }


    render() {

        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        return (
            <div className={"mw-100 container bg_chat_all"}>
                <Row className={"fixed-top text-center bg-navs pt-1 pb-1"}>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center text-white"}
                         onClick={this.backHome}>
                        <BackIcon/> Back
                    </Col>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>
                        <div className={"waiter_name text-white"}> {this.state.waiter_name} </div>
                    </Col>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>

                    </Col>
                </Row>
                <div className={" mt-5 mb-5 pb-2 pt-3 w-100 h-100 bg_chat"}>
                    {this.state.displayMessagesArr}
                    <div id={"messagesEnd"} style={{float: "left", clear: "both"}}>
                    </div>

                    <Dialog aria-labelledby="simple-dialog-title" open={this.state.dialogOpen}>
                        <DialogTitle id="simple-dialog-title">Nachrichten löschen/editieren</DialogTitle>
                        <DialogContent>
                            <List>
                                <ListItem button onClick={this.deleteMessage}>
                                    <ListItemAvatar>
                                        <DeleteIcon/>
                                    </ListItemAvatar>
                                    <ListItemText primary={"Nachricht löschen"}/>
                                </ListItem>
                                <ListItem button onClick={this.setVisible}>
                                    <ListItemAvatar>
                                        <EditIcon/>
                                    </ListItemAvatar>
                                    <ListItemText primary={"Nachricht editieren"}/>
                                </ListItem>
                                <div className="input-group h-100" style={{visibility: [this.state.styleField]}}>
                                    <input
                                        id={'clickedMessage'}
                                        className={'form-control'}
                                        type={'text'}
                                        autoComplete={"off"}
                                        onKeyPress={this.sendMessage}
                                        placeholder={'Nachricht eingeben'}
                                        value={this.state.clickedMessage}
                                        onChange={this.textChange}
                                        name={'clickedMessage'}
                                    />
                                    <span>
                                        <select className={"h-100"} id="clickedImportance" name={"clickedImportance"}
                                                value={this.state.clickedImportance} onChange={this.SelectChange}>
                                            <option className={"importance_0"} value="0">Keine</option>
                                            <option className={"importance_1_out"} value="1">Niedrig</option>
                                            <option className={"importance_2_out"} value="2">Mittel</option>
                                            <option className={"importance_3_out"} value="3">Hoch</option>
                                        </select>
                                    </span>
                                </div>
                            </List>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Abbrechen
                            </Button>
                            <Button onClick={this.editMessage} color="primary">
                                Speichern
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div className={"mt-3"}/>
                <Row className={"mt-5 fixed-bottom text-center bg-navs"}>
                    <div className="form-group w-100 ml-4 mr-4 mb-2 mt-2">
                        <div className="input-group h-100">
                            <input
                                id={'message'}
                                className={'form-control'}
                                type={'text'}
                                 autoComplete={"off"}
                                onKeyPress={this.sendMessage}
                                placeholder={'Nachricht eingeben'}
                                value={this.state.message}
                                onChange={this.textChange}
                                name={'message'}
                            />
                            <span>
                                        <select className={"h-100"} name="importance" id="importance"
                                                value={this.state.importance} onChange={this.SelectChange}>
                                            <option className={"importance_0"} value="0">Keine</option>
                                            <option className={"importance_1_out"} value="1">Niedrig</option>
                                            <option className={"importance_2_out"} value="2">Mittel</option>
                                            <option className={"importance_3_out"} value="3">Hoch</option>
                                        </select>
                                    </span>
                        </div>
                    </div>
                </Row>


            </div>
        )
    }

    setVisible = () => {
        if (this.state.styleField === "visible") {
            this.setState({
                styleField: "hidden"
            })
        } else {
            this.setState({
                styleField: "visible"
            })
        }
    }
    handleClose = () => {
        this.setState({
            dialogOpen: false,
            clickedMessageObj: null,
            clickedMessage: "",
            clickedImportance: 0
        })
    }
    handleOpen = (c) => {
        this.setState({
            dialogOpen: true,
            clickedMessageObj: c,
            clickedImportance: c.message.importance,
            clickedMessage: c.message.message
        })
    }


    SelectChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }
    textChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    };

    scrollToBottom = () => {
        let el = document.getElementById("messagesEnd");
        if (this.state.chat.length > 10) {
            el.scrollIntoView({behavior: "smooth"});
        }
    }


    displayMessages = () => {
        let that = this;
        let counter = 0;

        function getImportanceMe(id, c) {
            if (parseInt(c.message.importance) === 0) {
                return "bg_chat_out menu  p-2 mb-1 mt-1 importance_0";
            } else if (parseInt(c.message.importance) === 1) {
                return "bg_chat_out v p-2 mb-1 mt-1 importance_1_out";
            } else if (parseInt(c.message.importance) === 2) {
                return "bg_chat_out menu  p-2 mb-1 mt-1 importance_2_out";
            } else if (parseInt(c.message.importance) === 3) {
                return "bg_chat_out menu  p-2 mb-1 mt-1 importance_3_out";
            }
        }

        function getImportanceOthers(id, c) {
            if (parseInt(c.message.importance) === 0) {
                return "bg_chat_in display-table p-2 mb-1 mt-1 importance_in_0";
            } else if (parseInt(c.message.importance) === 1) {
                return "bg_chat_in display-table p-2 mb-1 mt-1 importance_in_1";
            } else if (parseInt(c.message.importance) === 2) {
                return "bg_chat_in display-table p-2 mb-1 mt-1 importance_in_2";
            } else if (parseInt(c.message.importance) === 3) {
                return "bg_chat_in display-table p-2 mb-1 mt-1 importance_in_3";
            }
        }

        function getIfOwnMessage(c) {
            counter++;
            if (parseInt(c.user.id) === parseInt(that.state.waiter_id)) {
                return (<div className={getImportanceMe(c.user.id, c)}>
                    <div className={"box"} onClick={() => that.handleOpen(c)}>
                        <ExpandMoreIcon className={"icon"}/>
                    </div>
                    <div>
                        <div className={"pr-2"}>
                            <span>{c.message.message}</span>
                        </div>
                    </div>
                </div>)
            } else {
                return (<div className={getImportanceOthers(c.user.id, c)}>
                    <div className={"text-left pl-2 pt-1 font-weight-bold"}>
                        {c.user.name}
                    </div>
                    <div className={"pl-2 "}>
                        <span>{c.message.message}</span>
                    </div>
                </div>)
            }
        }

        if (this.state.chat != null) {
            let chatMessages = [];

                this.state.chat.map(c => (
                    chatMessages.push(
                        (
                            <div key={counter} className={"colorTextChat mb-2 mt-2"}>
                                {getIfOwnMessage(c)}
                            </div>
                        ))
                ));
                counter++;
                chatMessages.push(
                    <div key={counter} className={"colorTextChat opacityMax mb-3 mt-3 "}>
                        <div className={"bg_chat_in display-table p-2 mb-3 mt-3 importance_in_0"}>
                            <div className={"text-left pl-2 pt-1 font-weight-bold"}>
                                {}
                            </div>
                            <div className={"pl-2 "}>
                                <span>{}</span>
                            </div>
                        </div>
                    </div>
                )

            this.setState({
                displayMessagesArr: chatMessages
            })
            this.scrollToBottom()
        }
    }
    getAllMessages = () => {
        const axios = require('axios');

        axios({
            method: 'post',
            url: url + '?action=getAllMessages',
        }).then((res) => {
            if(!res.data.error){
                let arr = res.data.messages;
                if (arr.length === 0 || res.data.error === "#772222") {
                    arr = [];
                }
                this.setState({
                    chat: arr
                })
                this.displayMessages();
            }
        })
    }
    getNewMassages = () => {
        const axios = require('axios');

        const params = new URLSearchParams();
        let chatsArray = this.state.chat
        let highestId = this.findHighestId(chatsArray);
        params.append('lastMessageId', highestId);

        axios({
            method: 'post',
            url: url + '?action=getNewChatMessages',
            data: params
        }).then((res) => {
            let arr = res.data
            let messageArr;
            if (highestId !== 0) {
                messageArr = this.getIfNew(arr);
            } else {
                messageArr = res.data.messages
            }
            this.setState({
                chat: messageArr
            })
            if (typeof arr.messages !== 'undefined') {
                this.displayMessages();
            }

        })
    }

    getIfNew = (arr) => {
        let messageArr;
        if (parseInt(arr.getAll) === 1) {
            messageArr = arr.messages;
        } else {
            messageArr = this.state.chat;
            if (arr.messages && parseInt(arr.messages.length) !== 0) {
                for (let i = 0; i < arr.messages.length; i++) {
                    if (!this.checkIfMessagedAlreadyInChat(arr.messages[i])) {
                        messageArr.push(arr.messages[i]);
                    }
                }
            }
        }
        return messageArr;
    }
    findHighestId = (chats) => {

        if (chats && chats.length !== 0) {
            let id = chats[0].message.id
            for (let i = 0; i < chats.length; i++) {
                if (parseInt(id) < parseInt(chats[i].message.id)) {
                    id = chats[i].message.id;
                }
            }
            return id;
        } else {
            return 0;
        }
    }


    checkIfMessagedAlreadyInChat = (singlemessage) => {
        let chat = this.state.chat;
        if (chat && chat.length !== 0 && singlemessage !== undefined) {
            for (let i = 0; i < chat.length; i++) {
                if (parseInt(chat[i].message.id) === parseInt(singlemessage.message.id)) {
                    return true;
                }
            }
        }
        return false;

    }

    sendMessage = (e) => {
        if (e.key === 'Enter') {

            if (this.state.message !== "") {
                e.target.blur();
                const axios = require('axios');

                let chatsArray = this.state.chat;
                const params = new URLSearchParams();
                params.append('message', this.state.message);
                params.append('importance', this.state.importance);
                let highestId = this.findHighestId(chatsArray);
                params.append('lastMessageId', highestId);
                axios({
                    method: 'post',
                    url: url + '?action=sendMessages',
                    data: params
                }).then((res) => {
                    let arr = res.data
                    let messageArr;
                    messageArr = this.getIfNew(arr);
                    this.setState({
                        chat: messageArr,
                        message: "",
                        importance: 0
                    })
                    if (parseInt(arr.length) !== 0) {
                        this.displayMessages();
                    }
                })
            }
        }
    }
    deleteMessage = () => {
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('messageId', this.state.clickedMessageObj.message.id);
        this.setState({
            chat: [],
        })
        axios({
            method: 'post',
            url: url + '?action=deleteMessage',
            data: params
        }).then((res) => {
            this.setState({
                chat: res.data.messages,
            })
            this.handleClose();
            this.displayMessages();
        })
    }
    editMessage = () => {
        if (this.state.clickedMessageObj.message.message !== this.state.clickedMessage || this.state.clickedMessageObj.message.importance !== this.state.clickedImportance) {
            const axios = require('axios');

            const params = new URLSearchParams();
            params.append('messageId', this.state.clickedMessageObj.message.id);
            params.append('importance', this.state.clickedImportance);
            params.append('message', this.state.clickedMessage);

            axios({
                method: 'post',
                url: url + '?action=editMessage',
                data: params
            }).then((res) => {
                this.setState({
                    chat: res.data.messages
                })
                this.handleClose();
                this.displayMessages();

            })
        } else {
            this.handleClose();
        }
    }

    backHome = () => {
        clearInterval(this.interval);
        this.setState({
            redirectToLink: "/loggedin/Home/",
            redirect: true
        })
    }
}

export default Chat;