import React, {Component} from 'react';
import {Card, CardContent, Grid, Typography} from "@material-ui/core";

class Table_Event extends Component {
    state = {
        shadow: false
    }
    onMouseOver = () => this.setState({shadow: true});
    onMouseOut = () => this.setState({shadow: false});

    render() {
        if (!this.props.show) {
            return (<div/>);
        }
        return (
            <div>
                <div className={"m-1 mt-2 pb-5"}>
                    <h2 className={"text-left ml-2"}>Tischübersicht</h2>
                    <div className={"justify-content-center mb-4"}>
                        <Grid container justify="center" spacing={1}>
                            {
                                this.props.dataTable && this.props.dataTable.map((c, k) => (
                                    <Grid key={k} item xs={12} sm={2} md={2} lg={2}>
                                        <Card style={{maxWidth: 345}}
                                              onClick={() => this.props.openDetailedTable(c.tablenumber)}>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    Tischnr. {c.tablenumber}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Einnahmen: {c.income}€
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default Table_Event;