import React, {Component} from 'react';
import {Bar, Line} from "react-chartjs-2"
import "chartjs-plugin-labels";
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Card from "@material-ui/core/Card";
import {CardHeader} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

Chart.plugins.unregister(ChartDataLabels);

class MoneyEvent extends Component {

    render() {
        const that = this;
        const options = {
            responsive: true,
            tooltips: {
                enabled: false
            },
            legend: {
                display: true,
            },
            plugins: {
                datalabels: {
                    formatter: function (value) {
                        return value + '€';
                    },
                    backgroundColor: function (context) {
                        return context.dataset.backgroundColor;
                    },
                    borderRadius: 4,
                    color: 'white',
                    font: {
                        weight: 'bold'
                    },
                    padding: 6,
                    listeners: {
                        click: (context) => {
                            that.props.openDialogForDetailData(that.props.data_graph_money.labels[context.dataIndex]);
                        }
                    }
                }
            },
            aspectRatio: 5 / 3,
            layout: {
                padding: {
                    top: 32,
                    right: 16,
                    bottom: 16,
                    left: 8
                }
            },
            elements: {
                line: {
                    fill: false
                }
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            }
        }

        if (!this.props.show) {
            return (<div/>);
        }
        return (
            <div>
                <div className="m-1">
                    <div className="row">
                        <div className="col-sm">
                            <Card className={"card_border m-05 m-1"}>
                                <CardHeader className={"pb-0 ml-4"} title={"Einnahmen pro Tag"}/>
                                <CardContent className={"pt-0"}>
                                    <Line data={this.props.data_graph_money} options={options}
                                          plugins={[ChartDataLabels]}/>
                                </CardContent>
                            </Card>
                        </div>
                        <div className="col-sm">
                            <Card className={"card_border m-05 m-1"}>
                                <CardHeader className={"pb-0 ml-4"} title={"Einnahmen pro Produkte"}/>
                                <CardContent className={"pt-0"}>
                                    <Bar data={this.props.data_graph_money_products}
                                         options={this.props.options_graph_product}/>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default MoneyEvent;