import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./login.css"
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

let url;

class Login extends Component {


    state = {
        username: '',
        password: '',
        waiters: [],
        waitername: "",
        possibleWaiters: [],
        events: [],
        eventName: '',
        loadingEvent: false,
        loadingUsers: false,
        loggedIn: false,
        showSnackbarUser: false,
        showSnackbarEvent: false,
        snackbarMessagesUser: "",
        snackbarMessagesEvent: ""
    };


    componentDidMount() {
        // console.log(localStorage)
        /*if (localStorage.getItem('url') == null) {
            url = process.env.REACT_APP_API_URL
        } else {
            url = localStorage.getItem('url');
        }*/
        url = "http://192.168.1.115/Backend/";
        this.getAllEvents();
        this.getAllUsers();
    }

    textChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    };
    SelectChange = (event) => {
        this.setState({waitername: event.target.value});
    }
    SelectChangeEvent = (event) => {
        this.selectWaitersOfEvent(this.state.waiters, event.target.value);
        this.setState({eventName: event.target.value});
    }

    selectWaitersOfEvent = (waiters, event_id) => {
        let waiter = [];
        for (let i = 0; i < waiters.length; i++) {
            if (waiters[i].event_id === event_id) {
                waiter.push(waiters[i])
            }
        }
        if (waiter.length !== 0) {
            let showname = "";
            if (waiter[0] && waiter[0].name) {
                showname = waiter[0].name
            }
            //console.log(waiters, event_id)
            this.setState({
                possibleWaiters: waiter,
                waitername: showname
            })
        } else {
            this.setState({
                showSnackbarUser: true,
                snackbarMessagesUser: "Es gibt noch keine User für dieses Event, gehe zur Adminsicht um Events anzulegen!",
                possibleWaiters: [],
                waitername: ""
            })
        }

    }

    findEventNameById = (id) => {
        let events = this.state.events
        for (let i = 0; i < events.length; i++) {
            if (events[i].id === id) {
                return events[i].description
            }
        }
        return false
    }

    render() {
        if (this.props.currentUser != null || this.state.loggedIn) {
            return <Redirect to='/'/>
        }

        if (!(this.state.loadingEvent === false && this.state.loadingUsers === false)) {
            return (<Backdrop open={true} docked="false">
                <CircularProgress color="secondary"/>
                <h2 className={"text-center ml-3"}>Loading...</h2>
            </Backdrop>);
        }


        return (
            <div>
                <Snackbar open={this.state.showSnackbarUser} autoHideDuration={null}
                          onClose={this.handleCloseSnackbarUser}>
                    <Alert onClose={this.handleCloseSnackbarUser} variant={"filled"} severity="info">
                        {this.state.snackbarMessagesUser}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.showSnackbarEvent} autoHideDuration={null}
                          onClose={this.handleCloseSnackbarEvent}>
                    <Alert onClose={this.handleCloseSnackbarEvent} variant={"filled"} severity="info">
                        {this.state.snackbarMessagesEvent}
                    </Alert>
                </Snackbar>
                <div style={{
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: "#ccc"
                }}
                     className={"d-flex main-content flex-column align-items-center justify-content-around"}>

                    <div>

                        <div style={{width: "350px"}} className={"p-4 bg-white"}>
                            <div>
                                <div className={"d-flex flex-column  align-items-center "}>

                                    <h4 className={"pt-2 pb-4"}>Kellner-Anmeldung</h4>
                                </div>
                            </div>
                            <div className={"d-flex m-1 justify-content-center"}>
                                <form onSubmit={this.loginAjax} className="login"
                                      style={{display: 'flex', flexFlow: 'column'}}>
                                    <div className="form-group">
                                        <select className="form-control  rounded-pill" id="eventSelect"
                                                onChange={this.SelectChangeEvent} value={this.state.eventName}>
                                            {this.state.events.map(c => (
                                                <option key={c.id} value={c.id}>{c.description}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select className="form-control  rounded-pill" id="waiterSelect"
                                                onChange={this.SelectChange} value={this.state.waitername}>
                                            {this.state.possibleWaiters.map(c => (
                                                <option key={c.id} value={c.name}>{c.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            id={'passwordLogin'}
                                            className={'form-control rounded-pill'}
                                            type={'password'}
                                            placeholder={'Password'}
                                            value={this.state.password}
                                            onChange={this.textChange}
                                            name={'password'}
                                        />
                                    </div>
                                    <Link className={"text-body"} style={{textDecoration: "none"}}
                                          to="/od_login/">Admin</Link>
                                    {this.state.errMsg ?? <p>{this.state.errMsg}</p>}
                                    <input type={'submit'} value={"Login"} className={'btn btn-primary rounded-pill'}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleCloseSnackbarEvent = () => {
        this.setState({
            showSnackbarEvent: !this.state.showSnackbarEvent
        })
    }
    handleCloseSnackbarUser = () => {
        this.setState({
            showSnackbarUser: !this.state.showSnackbarUser
        })
    }
    getAllUsers = () => {
        const axios = require('axios');
        this.setState({
            loadingUsers: true
        })
        axios({
            method: 'post',
            url: url + '?action=getAllUsers',
        }).then((res) => {
            let arr = res.data;
            let arrFinished = [];
            if (arr.length !== 0) {
                arr.map((c) => (
                    arrFinished.push({
                        id: c.id, name: c.name, role: c.role_id, event_id: c.event_id
                    })
                ));
                this.setState({
                    waiters: arrFinished,
                    waitername: arr[0].name,
                    loadingUsers: false
                })
                if (this.state.events.length > 0) {
                    // console.log(this.state.waiters)
                    this.selectWaitersOfEvent(arrFinished, this.state.events[0].id)
                }
            } else {
                this.setState({
                    showSnackbarUser: true,
                    loadingUsers: false,
                    snackbarMessagesUser: "Es gibt noch keine User, gehe zur Adminsicht um Events anzulegen!"
                })
            }
        })
    }
    getAllEvents = () => {
        const axios = require('axios');
        this.setState({
            loadingEvent: true
        })
        axios({
            method: 'post',
            url: url + '?action=getAllEvents&sort=desc',
        }).then((res) => {
            let arr = res.data;
            let arrFinished = [];
            if (arr.length !== 0) {
                arr.map((c) => (
                    arrFinished.push({
                        id: c.id, description: c.description
                    })
                ));
                this.setState({
                    events: arrFinished,
                    eventName: arr[0].id,
                    loadingEvent: false
                })
                if (this.state.waiters.length > 0) {
                    // console.log(this.state.waiters)
                    this.selectWaitersOfEvent(this.state.waiters, arr[0].id)
                }
            } else {
                this.setState({
                    showSnackbarEvent: true,
                    loadingEvent: false,
                    snackbarMessagesEvent: "Es gibt noch keine Events, gehe zur Adminsicht um Events anzulegen!"
                })
            }
        })

    }

    loginAjax = (e) => {
        e.preventDefault();

        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('username', this.state.waitername);
        params.append('password', this.state.password);
        params.append('event_id', this.state.eventName);

        this.setState({loading: true})
        axios({
            method: 'post',
            url: url + '?action=login',
            data: params
        }).then((res) => {
            // console.log(res.data)
            if (res.data.error !== "#710" && res.data) {
                this.login(res.data.token);
            } else {
                this.setState({errMsg: "Passwort ist inkorrekt", password: ""})
            }
            this.setState({loading: false})
        })

    }
    login = (token) => {
        let decoded = jwt_decode(token);
        this.props.setUser(decoded.data, token)
        this.setState({loggedIn: true})
    }
}

export default Login;
