import React, {Component} from 'react';
import {Dialog, ListItem, ListItemSecondaryAction, ListItemText, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

class DialogDayAnalysis extends Component {
    state = {
        selectedDate: new Date()
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.open}
                        onClose={this.props.onClose}
                        maxWidth={"xs"}
                        fullWidth={true}
                >
                    <DialogTitle id="simple-dialog-title">Druckerstatus</DialogTitle>
                    <DialogContent>
                        {this.props.printers && this.props.printers.length !== 0 ?
                            <List subheader={<li/>} className={"padding_toScroll"}>
                                {
                                    this.props.printers.map((c, k) => (
                                        <div>
                                            <ListItem>
                                                <ListItemText
                                                    primary={c ? c.printer.description : ""}
                                                    secondary={c ? c.printer.ip_address : ""}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="delete">
                                                        {c.success !== false ?
                                                            <CheckCircleOutlineIcon style={{color: "green"}}/> :
                                                            <HighlightOffIcon style={{color: "red"}}/>}
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </div>
                                    ))
                                }
                            </List> : <CircularProgress color="secondary"/>
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default DialogDayAnalysis;