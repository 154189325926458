import React, {Component} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";

class DialogProfile extends Component {
    state = {
        showPassword: false,
        username: null,
        password: "",
        confirm_password: "",
        old_password: "",
        error: false,
        error_Message: "",
        showOldPassword: false
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="confirm-dialog"
                maxWidth={"md"}
            >
                <DialogTitle id="confirm-dialog">Profile</DialogTitle>
                <DialogContent>
                    <div className={"m-3"} style={{display: 'flex', flexFlow: 'column'}}>
                        <div className="form-group m-1 ">
                            <TextField type={"text"} className={"w-100"} onChange={this.setText} autoFocus={true}
                                       name={"username"} id={"username"}
                                       value={this.state.username === null ? this.props.username : this.state.username}
                                       label={"Username"} variant={"outlined"}/>
                        </div>
                        <div className="form-group m-1">
                            <TextField type={this.state.showOldPassword ? "text" : "password"} onChange={this.setText}
                                       className={"w-100"} name={"old_password"} id={"old_password"}
                                       value={this.state.old_password}
                                       label={"Altes Passwort"} variant={"outlined"} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickOldPassword}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {this.state.showOldPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            />
                        </div>
                        <div className="form-group m-1">
                            <TextField type={this.state.showPassword ? "text" : "password"} onChange={this.setText}
                                       className={"w-100"} name={"password"} id={"password"} value={this.state.password}
                                       label={"Passwort"} variant={"outlined"} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickPassword}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            />
                        </div>
                        <div className="form-group m-1">
                            <TextField type={this.state.showPassword ? "text" : "password"} onChange={this.setText}
                                       className={"w-100"} name={"confirm_password"} id={"confirm_password"}
                                       value={this.state.confirm_password}
                                       label={"Passwort bestätigen"} variant={"outlined"} InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickPassword}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            />
                        </div>
                        <div className="m-1 ">
                            <div className={"m-1 ml-5 w-100"}>
                                {this.state.error ?
                                    <span style={{color: "red"}}>{this.state.error_Message}</span> : null}
                            </div>
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={this.onSave} color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    setText = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleClickOldPassword = () => {
        this.setState({
            showOldPassword: !this.state.showOldPassword
        })
    }
    handleClickPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }
    onSave = () => {
        let username = this.state.username;
        if (!username) {
            username = this.props.username;
        }
        if (this.state.password !== "" && this.state.confirm_password !== "" && this.state.old_password !== "") {
            if (this.state.password === this.state.confirm_password) {
                this.setState({error: false, error_Message: ""})
                this.props.onSave(username, this.state.password, this.state.old_password)
            } else {
                this.setState({error: true, error_Message: "Passwörter stimmen nicht überein!"})
            }
        } else {
            this.setState({error: true, error_Message: "Passwortfelder nicht ausgefüllt!"})
        }
    }
}

export default DialogProfile;