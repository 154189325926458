import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class ConfirmDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.setOpen}
                aria-labelledby="confirm-dialog"
            >
                <DialogTitle id="confirm-dialog">{this.props.title}</DialogTitle>
                <DialogContent>{this.props.text}</DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={this.props.setOpen}
                        color="secondary"
                    >
                        Nein
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            this.props.setOpen();
                            this.props.onConfirm();
                        }}
                        color="default"
                    >
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default ConfirmDialog;