import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import BackIcon from '@material-ui/icons/ArrowBackIos';
import {Redirect} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import withStyles from "@material-ui/core/styles/withStyles";
import ListItemDrawer from "./ListItemsDrawer"
import "./eventAnalysis.css"
import HomeEvent from "./Subcomponents/Home_Event";
import OrdersEvent from "./Subcomponents/Orders_Event";
import MoneyEvent from "./Subcomponents/Money_Event";
import WaiterEvent from "./Subcomponents/Waiter_Event";
import Table_Event from "./Subcomponents/Table_Event";
import DialogDisplayDetailData from "../../Components/DialogDisplayDetailData";
import DialogWaiterDetailed from "./Subcomponents/WaiterDetailed/DialogWaiterDetailed";
import RefreshIcon from '@material-ui/icons/Refresh';
import DialogTableDetailed from "./Subcomponents/TableDetailed/DialogTableDetailed";
import {ExportService} from "../../ExportToExcel/exportService";

let url;

const styles = theme => ({
    drawerPaperClose: {
        top: "4rem",
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 70,
        [theme.breakpoints.up('sm')]: {
            width: 70,
        },
    },
});


class eventAnalysis extends Component {
    state = {
        redirect: false,
        redirectToLink: "",
        loading: false,
        data: [],
        data_label: [1, 1],
        data_amount: [1, 1],
        data_product: [],
        home_open: true,
        waiter_open: false,
        money_open: false,
        table_open: false,
        orders_open: false,
        waiterDetailedDataOpen: false,
        data_income_label: [1, 1],
        data_income: [1, 1],
        data_order_label: [1, 1],
        data_order: [1, 1],
        data_incomeProduct: [1, 1],
        amount_orders: 12,
        complete_income: 12000,
        amount_products: 1,
        data_label_incomeProduct: [1, 1],
        detailedInfo: [],
        data_product_income: [],
        bestWaiter: null,
        labelsWaiter: [],
        dataWaiter: [],
        orders_hours: [],
        detailedWaiterData: [],
        detailedWaiterDataOne: [],
        detailedWaiterProducts: [],
        detailedWaiterTables: [],
        tableData: [],
        detailedTableDataOne: [],
        detailedTableProducts: [],
        detailedTableOrder: [],
        detailedTableWaiters: [],
        TableDetailedDataOpen: false,
        important_data: []
    }


    componentDidMount() {
        url = "http://192.168.1.115/Backend/";
        this.getEventData();
    }

    render() {
        const data = {
            labels: this.state.data_label,
            datasets: [
                {
                    label: 'Anzahl der bestellten Produkte',
                    data: this.state.data_amount,
                    backgroundColor: "rgba(8, 112, 178, 0.9)",
                    borderColor: "rgba(8, 112, 178, 0.9)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(6,81,128,0.9)",
                    hoverBorderColor: "rgba(6,81,128,0.9)"
                },
            ],
        };
        const data_waiter_amount = {
            labels: this.state.labelsWaiter,
            datasets: [
                {
                    label: 'Anzahl der Bestellungen pro Kellner',
                    data: this.state.dataWaiter,
                    backgroundColor: "#f87979",
                    borderColor: "#f87979",
                    borderWidth: 1,
                },
            ],
        }

        const data_orders = {
            labels: this.state.data_order_label,
            datasets: [
                {
                    label: 'Anzahl der Bestellungen pro Tag',
                    data: this.state.data_order,
                    backgroundColor: "#f87979",
                    borderColor: "#f87979",
                    borderWidth: 1,
                },
            ],
        };
        const data_money = {
            labels: this.state.data_income_label,
            datasets: [
                {
                    label: 'Gesamteinnahmen der Bestellungen pro Tag',
                    data: this.state.data_income,
                    backgroundColor: 'rgba(255, 99, 132, 1)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
            ],
        };
        const data_money_products = {
            labels: this.state.data_label_incomeProduct,
            datasets: [
                {
                    label: 'Gesamteinnahmen der Produkte pro Tag',
                    data: this.state.data_incomeProduct,
                    backgroundColor: 'rgba(8, 112, 178, 1)',
                    borderColor: 'rgba(8, 112, 178, 1)',
                    borderWidth: 1,
                },
            ],
        };
        const options = {
            responsive: true,
            tooltips: {
                enabled: true,
                mode: 'label'
            },
            legend: {
                display: true,
            },
            plugins: {
                labels: {
                    render: 'value',
                    precision: 2,
                    fontSize: 14,
                    fontStyle: 'bold',
                }
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        }
        const options_Money = {
            responsive: true,
            tooltips: {
                enabled: true,
                mode: 'label'
            },
            legend: {
                display: true,
            },
            plugins: {
                labels: {
                    render: 'value',
                    precision: 2,
                    fontSize: 14,
                    fontStyle: 'bold',
                }
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
        }


        const {classes} = this.props;

        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        if (this.state.loading) {
            return (<Backdrop open={true} docked="false">
                <CircularProgress color="secondary"/>
                <h2 className={"text-center ml-3"}>Loading...</h2>
            </Backdrop>);
        }

        return (
            <div className={"bg_darker"}>
                <div className={"w-100 h-100 p-1"}>
                    <Row className={"fixed-top text-center bg-navs pt-1 pb-1"}>
                        <Col
                            className={"mt-3 mb-3 fontsize_header col-4 text-center d-flex justify-content-center text-white"}
                            onClick={this.backHome}>
                            <BackIcon/> Back
                        </Col>
                        <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>
                        </Col>
                        <Col
                            className={"mt-3 mb-3 col-4 fontsize text-center d-flex justify-content-center text-white"}
                            onClick={() => this.getEventData()}>
                            <RefreshIcon/>
                        </Col>
                    </Row>
                </div>
                <DialogDisplayDetailData
                    open={this.state.detailOpen} onClose={this.closeDialogForDetailData}
                    detailedInfo={this.state.detailedInfo}
                />
                <DialogWaiterDetailed
                    open={this.state.waiterDetailedDataOpen} onClose={this.handleDetailedWaiterOpen}
                    waiterData={this.state.detailedWaiterDataOne}
                    detailedWaiterProducts={this.state.detailedWaiterProducts}
                    detailedWaiterTables={this.state.detailedWaiterTables}
                />
                <DialogTableDetailed
                    open={this.state.TableDetailedDataOpen} onClose={this.handleDetailedTableOpen}
                    tableData={this.state.detailedTableDataOne}
                    detailedTableProducts={this.state.detailedTableProducts}
                    detailedTableOrders={this.state.detailedTableOrder}
                    detailedTableWaiters={this.state.detailedTableWaiters}
                />
                <Drawer
                    anchor="left"
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaperClose
                    }}
                    open={false}
                >
                    <List>
                        <ListItemDrawer openMoney={this.openMoney} openWaiter={this.openWaiter}
                                        openOrders={this.openOrders} openGraph={this.openGraphs}
                                        openHome={this.openHome} openTable={this.openTable}
                                        printReports={this.printReports}/>
                    </List>
                    <div style={{"height": "98vh"}}/>
                </Drawer>
                <main className={"w-100 bg_darker  pt-5"}
                      style={{
                          "paddingLeft": "5rem",
                          "paddingRight": "1.5rem",
                          "paddingTop": "1rem",
                          "height": "98vh"
                      }}>
                    <HomeEvent data_graph_product={data} data={this.state.data} options_graph_product={options}
                               show={this.state.home_open} data_graph_order={data_orders}
                               openDetailedWaiter={this.findWaiterDataByWaiter}
                               amount_orders={this.state.amount_orders} comp_income={this.state.complete_income}
                               amount_products={this.state.amount_products}/>
                    <WaiterEvent show={this.state.waiter_open} data_graph_product={data_money_products}
                                 options_graph_product={options} data_waiter_amount_ordered={data_waiter_amount}
                                 bestWaiter={this.state.bestWaiter} openDetailedWaiter={this.findWaiterDataByWaiter}/>
                    <OrdersEvent show={this.state.orders_open} data_graph_order={data_orders}
                                 data_orders_hours={this.state.orders_hours} amount_orders={this.state.amount_orders}
                                 options_graph_product={options}/>
                    <MoneyEvent show={this.state.money_open} options_graph_product={options_Money}
                                data_graph_money={data_money} data_graph_money_products={data_money_products}
                                openDialogForDetailData={this.openDialogForDetailData}/>
                    <Table_Event show={this.state.table_open} dataTable={this.state.tableData}
                                 openDetailedTable={this.findTableDataByTableNumber}/>
                </main>
            </div>
        );

    }


    closeDialogForDetailData = () => {
        this.setState({
            detailOpen: false
        })
    }

    handleDetailedWaiterOpen = () => {
        this.setState({
            waiterDetailedDataOpen: !this.state.waiterDetailedDataOpen
        })
    }
    handleDetailedTableOpen = () => {
        this.setState({
            TableDetailedDataOpen: !this.state.TableDetailedDataOpen
        })
    }
    handleDrawer = () => {
        this.setState({
            drawerOpen: !this.state.drawerOpen
        })
    }
    openDialogForDetailData = (date) => {
        let dates = this.state.data_income_label
        let index = -1;
        for (let i = 0; i < dates.length; i++) {
            if (dates[i] === date) {
                index = i;
            }
        }
        this.setState({
            detailOpen: true,
            detailedInfo: this.state.data_product_income[index]
        })

    }

    getEventData = () => {
        this.setState({
            loading: true
        })
        const axios = require('axios');

        axios({
            method: 'post',
            url: url + '?action=getDataOfOrdersByUser',
        }).then((res) => {
            //console.log(res.data)
            this.setState({data: res.data})
            this.getWaiterAndAmount(res.data);
            this.getOrdersByHour();
            this.getEventDataHours();
            this.getTotalIncome();
            this.getBestWaiter();
            this.getWaiterData();
            this.getTableData();
            this.getEventDataProducts();
            this.getMostImportantData();
        });
    }
    printReports = () => {
        let that = this;
        const ExportService = require('../../ExportToExcel/exportService');
        let pre_data = this.state.important_data
        if (pre_data.length !== 0) {
            let dataWithProducts = []
            dataWithProducts.push(handleOverall(this.state.data_order, this.state.data_order_label, this.state.orders_hours))
            dataWithProducts.push(handleDataWithProducts(pre_data.dataWithProducts))
            dataWithProducts.push(handleOrdersByTable(pre_data.ordersbytable))
            dataWithProducts.push(handleUsers(pre_data.waiterData.users))
            const filePath = 'Event-Daten.xlsx';
            let book = ExportService.makeNewFile();
            for (let i = 0; i < dataWithProducts.length; i++) {
                book = ExportService.exportUsersToExcel(dataWithProducts[i].data, dataWithProducts[i].headers, dataWithProducts[i].workSheetName, book);
            }

            ExportService.download(book, filePath)
        }

        function handleDataWithProducts(data) {
            let arr = {
                "workSheetName": "Produktdaten",
                "headers": ["Name", "Preis", "Einkommen durch Produkt", "Anzahl"],
                "data": []
            }
            for (let i = 0; i < data.length; i++) {
                arr.data.push([data[i].name, data[i].price, data[i].incomeOfProduct + "€", data[i].amountOrdered])
            }
            return arr
        }

        function handleOrdersByTable(data) {
            let arr = {
                "workSheetName": "Tischdaten",
                "headers": ["Tisch", "Bestellungen", "Einkommen", "Verkaufte Produkte"],
                "data": []
            }
            for (let i = 0; i < data.length; i++) {
                arr.data.push([data[i].tablenumber, data[i].amount_of_orders, data[i].income + "€", , data[i].total_products])
            }
            return arr
        }

        function handleUsers(data) {
            let arr = {
                "workSheetName": "Kellnerdaten",
                "headers": ["Name", "Einkommen", "Bestellungen", "Am meisten Bestelltes Produkt"],
                "data": []
            }
            for (let i = 0; i < data.length; i++) {
                arr.data.push([data[i].user.name, data[i].totalIncome + "€", data[i].user_orders, data[i].products[0].product.name + " x " + data[i].products[0].amount])
            }
            return arr
        }

        function handleOverall(data, data_labels, data_hours) {
            let arr = {
                "workSheetName": "Allgemeine Daten",
                "headers": ["Anzahl der Bestellungen", "Gesamteinnahmen durch Bestellungen", "Anzahl der bestellten Produkte", "Tag", "Anzahl der Bestellungen", ""],
                "data": [[that.state.amount_orders, that.state.complete_income, that.state.amount_products]]
            }
            for (let i = 0; i < data_labels.length; i++) {
                if (i === 0) {
                    arr.data[i][3] = data_labels[i][0]
                    arr.data[i][4] = data[i]
                } else {
                    arr.data.push(["", "", "", data_labels[i][0], data[i]])
                }
            }
            for (let i = 0; i < data_hours.length; i++) {
                if (data_hours[i].orders) {
                    let index = arr.headers.length;
                    arr.headers.push([data_hours[i].date], ["Bestellungen"], [""])
                    for (let j = 0; j < data_hours[i].orders.length; j++) {
                        if (j < arr.data.length) {
                            arr.data[j][index] = data_hours[i].orders[j].hour;
                            arr.data[j][index + 1] = data_hours[i].orders[j].amount
                        } else {
                            let temp = [];
                            for (let z = 0; z < index; z++) {
                                temp.push("");
                            }
                            temp.push(data_hours[i].orders[j].hour)
                            temp.push(data_hours[i].orders[j].amount)
                            arr.data.push(temp)
                        }
                    }
                }
            }

            return arr

        }
    }
    getOrdersByHour = () => {
        const axios = require('axios');
        axios({
            method: 'post',
            url: url + '?action=getOrdersByHour',
        }).then((res) => {
            this.setState({
                orders_hours: res.data
            })
        });
    }
    getWaiterData = () => {
        const axios = require('axios');
        axios({
            method: 'post',
            url: url + '?action=getWaiterData',
        }).then((res) => {
            if (res.data.length !== 0) {
                let arr = res.data.users;
                this.setState({
                    detailedWaiterDataOne: arr[0],
                    detailedWaiterData: arr,
                    detailedWaiterProducts: arr[0].products,
                    detailedWaiterTables: arr[0].tables
                })
            }
        });
    }
    getTableData = () => {
        const axios = require('axios');
        axios({
            method: 'post',
            url: url + '?action=getOrdersByTable',
        }).then((res) => {
            if (res.data.length !== 0) {
                let arr = res.data;
                this.setState({
                    tableData: arr,
                    detailedTableDataOne: arr[0],
                    detailedTableProducts: arr[0].products,
                    detailedTableOrder: arr[0].orders_at_time,
                    detailedTableWaiters: arr[0].waiters
                })
            }
        });
    }
    findWaiterDataByWaiter = (id) => {
        let index = this.state.detailedWaiterData.findIndex(element => parseInt(element.user.id) === parseInt(id))
        let arr = this.state.detailedWaiterData;
        if (index !== "-1") {
            this.setState({
                detailedWaiterDataOne: arr[index],
                detailedWaiterProducts: arr[index].products,
                detailedWaiterTables: arr[index].tables
            }, this.handleDetailedWaiterOpen)
        }
    }
    findTableDataByTableNumber = (number) => {
        let index = this.state.tableData.findIndex(element => element.tablenumber === number)
        //console.log(number, index)
        let arr = this.state.tableData;
        if (index !== "-1") {
            this.setState({
                detailedTableDataOne: arr[index],
                detailedTableProducts: arr[index].products,
                detailedTableOrder: arr[index].orders_at_time,
                detailedTableWaiters: arr[index].waiters
            }, this.handleDetailedTableOpen)
        }
    }
    getTotalIncome = () => {
        const axios = require('axios');

        axios({
            method: 'post',
            url: url + '?action=getTotalIncome',
        }).then((res) => {
            if (res.data.length !== 0) {
                let income = res.data.totalIncome;
                try {
                    income = income.replace(",", "")
                } catch (e) {

                }
                this.setState({complete_income: income})
            } else {
                this.setState({complete_income: "Noch keine Bestellungen"})
            }

        });
    }
    getBestWaiter = () => {
        const axios = require('axios');
        axios({
            method: 'post',
            url: url + '?action=getBestUser',
        }).then((res) => {
            if (res.data.length !== 0) {
                this.setState({bestWaiter: res.data})
            } else {
                this.setState({bestWaiter: []})
            }

        });
    }

    getEventDataHours = () => {
        const axios = require('axios');

        axios({
            method: 'post',
            url: url + '?action=getDayData',
        }).then((res) => {
            if (res.data.length !== 0) {
                this.setState({data_hours: res.data})
                this.getIncomeWithDate(res.data);
                this.getOrdersWithDate(res.data);
            } else {
                this.setState({
                    data_hours: [],
                    data_income_label: [],
                    data_income: "Noch keine Bestellungen",
                    data_product_income: [],
                    data_order_label: [],
                    data_order: [],
                    amount_orders: "Noch keine Bestellungen"
                })
            }

        });
    }

    getMostImportantData = () => {
        const axios = require('axios');
        axios({
            method: 'post',
            url: url + '?action=getMostImportantData',
        }).then((res) => {
            if (res.data.length !== 0) {
                this.setState({important_data: res.data})
            }
        });
    }
    getEventDataProducts = () => {
        const axios = require('axios');
        axios({
            method: 'post',
            url: url + '?action=getDataOfProductsWithSingleExtra',
        }).then((res) => {
            if (res.data.length !== 0) {
                this.setState({data_product: res.data})
                this.getAmountWithName(res.data)
                this.getIncomeWithProduct(res.data)
            } else {
                this.setState({
                    data_product: [],
                    data_label: [],
                    data_amount: [],
                    amount_products: "Noch keine Bestellungen",
                    data_label_incomeProduct: [],
                    data_incomeProduct: [],
                    loading: false
                })
            }
        });
    }
    formatLabel = (str, maxwidth) => {
        let sections = [];
        let words = str.split(" ");
        let temp = "";

        words.forEach(function (item, index) {
            if (temp.length > 0) {
                let concat = temp + ' ' + item;

                if (concat.length > maxwidth) {
                    sections.push(temp);
                    temp = "";
                } else {
                    if (index === (words.length - 1)) {
                        sections.push(concat);
                        return;
                    } else {
                        temp = concat;
                        return;
                    }
                }
            }

            if (index === (words.length - 1)) {
                sections.push(item);
                return;
            }

            if (item.length < maxwidth) {
                temp = item;
            } else {
                sections.push(item);
            }

        });

        return sections;
    }
    getWaiterAndAmount = (data) => {
        let labels = [];
        let amountordered = [];

        data.forEach(c => {
            labels.push(this.formatLabel(c.user.name, 30));
            amountordered.push(c.orderamount);
        });
        this.setState({
            labelsWaiter: labels,
            dataWaiter: amountordered
        })
    }
    getAmountWithName = (data_product) => {
        let labels = [];
        let amount = [];
        let count_amount_products = 0;
        data_product.forEach(c => {
            labels.push(this.formatLabel(c.name, 30));
            amount.push(c.amountOrdered);
            count_amount_products += parseInt(c.amountOrdered);
            if (c.extras) {
                let help = c.name + " " + c.extras.description;
                labels.push(this.formatLabel(help, 30));
                amount.push(c.extras.amountOrdered);
                count_amount_products += parseInt(c.extras.amountOrdered)
            }
        })

        this.setState({
            data_label: labels,
            data_amount: amount,
            loading: false,
            amount_products: count_amount_products
        })

    }
    getIncomeWithProduct = (data_product) => {
        let labels = [];
        let amount = [];
        data_product.forEach(c => {
            labels.push(this.formatLabel(c.name, 30));
            amount.push(c.incomeOfProduct);
            if (c.extras) {
                let help = c.name + " " + c.extras.description;
                labels.push(this.formatLabel(help, 30));
                amount.push(c.extras.incomeOfExtra);
            }
        })
        this.setState({
            data_label_incomeProduct: labels,
            data_incomeProduct: amount,
            loading: false
        })
    }
    getIncomeWithDate = (data_product) => {
        let labels = [];
        let amount = [];
        let income = data_product.income;
        let products = [];
        income.forEach(c => {
            let productTemp = [];
            labels.push(this.formatLabel(c.date, 30));
            amount.push(c.income);
            c.products.forEach(element => productTemp.push(element));
            products.push(productTemp)
        })
        this.setState({
            data_income_label: labels,
            data_income: amount,
            data_product_income: products,
        })
    }

    getOrdersWithDate = (data_product) => {
        let labels = [];
        let amount = [];
        let count_orders = 0;

        let orders = data_product.date;
        orders.forEach(c => {
            labels.push(this.formatLabel(c.date, 30));
            amount.push(c.OrderAmount);
            count_orders += parseInt(c.OrderAmount);
        })
        this.setState({
            data_order_label: labels,
            data_order: amount,
            amount_orders: count_orders
        })
    }
    backHome = () => {
        this.setState({
            redirectToLink: "/loggedin/Home/",
            redirect: true
        })
    }
    openMoney = () => {
        this.setState({
            home_open: false,
            waiter_open: false,
            orders_open: false,
            money_open: true,
            table_open: false
        })
    }
    openHome = () => {
        this.setState({
            home_open: true,
            waiter_open: false,
            orders_open: false,
            money_open: false,
            table_open: false
        })
    }
    openTable = () => {
        this.setState({
            home_open: false,
            waiter_open: false,
            orders_open: false,
            money_open: false,
            graphs_open: false,
            table_open: true
        })
    }

    openOrders = () => {
        this.setState({
            home_open: false,
            waiter_open: false,
            orders_open: true,
            money_open: false,
            table_open: false
        })
    }
    openGraphs = () => {
        this.setState({
            home_open: false,
            waiter_open: false,
            orders_open: false,
            money_open: false,
            table_open: false
        })
    }
    openWaiter = () => {
        this.setState({
            home_open: false,
            waiter_open: true,
            orders_open: false,
            money_open: false,
            table_open: false
        })
    }
}


export default withStyles(styles, {withTheme: true})(eventAnalysis);