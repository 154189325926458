import React, {Component} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Validations from "js-textfield-validation/src/Validation";
import {validateIPAddress} from "js-textfield-validation";

class EditSubcategorie extends Component {
    state = {
        subcategorie_name: null,
        ip_address: null,
        printer_description: null,
        errorMessage: ""
    }


    render() {
        return (
            <div>
                <Dialog PaperProps={{style: {overflowY: 'visible'}}} open={this.props.open}
                        onClose={this.onClose}>
                    <DialogTitle id="simple-dialog-title">Unterkategorie editieren</DialogTitle>
                    <DialogContent style={{overflowY: 'visible'}}>
                        <div className={"m-3"} style={{display: 'flex', flexFlow: 'column'}}>
                            <div className="form-group m-1 ">
                                <TextField className={"m-1 w-100"}
                                           type={"text"}
                                           required={true}
                                           autoComplete={"off"}
                                           value={this.state.subcategorie_name === null ? this.props.subcategorie_name : this.state.subcategorie_name}
                                           id={'subcategorie_name'}
                                           onChange={this.onInputChange}
                                           name={'subcategorie_name'}
                                           label="Name der Subkategorie"
                                           variant="outlined"/>
                            </div>
                            <div className="form-group m-1 ">
                                <TextField className={"m-1 w-100"}
                                           type={"text"}
                                           required={true}
                                           autoComplete={"off"}
                                           value={this.state.ip_address === null ? this.props.ip_address : this.state.ip_address}
                                           id={'ip_address'}
                                           onChange={this.handleChangeIP}
                                           name={'ip_address'}
                                           label="IP-Adresse des Druckers"
                                           placeholder={"192.168.1.100"}
                                           variant="outlined"/>
                            </div>
                            <div className="form-group m-1 ">
                                <TextField className={"m-1 w-100"}
                                           type={"text"}
                                           required={true}
                                           autoComplete={"off"}
                                           value={this.state.printer_description === null ? this.props.printer_description : this.state.printer_description}
                                           id={'printer_description'}
                                           onChange={this.onInputChange}
                                           name={'printer_description'}
                                           label="Druckerstandort"
                                           variant="outlined"/>
                            </div>
                        </div>
                        {this.state.errorMessage ? <span style={{color: "red"}}>{this.state.errorMessage}</span> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary">
                            Schließen
                        </Button>
                        <Button onClick={this.checkIfAllFieldsFilledOut} color="primary">
                            Speichern
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.setState({
            subcategorie_name: null,
            ip_address: null,
            printer_description: null,
        })
        this.props.onClose();
    }
    checkIfAllFieldsFilledOut = () => {
        if (this.state.categorie_name !== "" && this.state.ip_address !== "" && this.state.printer_description !== 0) {
            let ip = this.state.ip_address
            if (!ip) {
                ip = this.props.ip_address
            }
            let newIP = new Validations(ip).noSpace().value;
            const isValidIP = validateIPAddress(newIP);
            if (isValidIP) {
                this.props.onClose();
                this.setState({
                    errorMessage: null,
                    subcategorie_name: null,
                    ip_address: null,
                    printer_description: null,
                })
                this.props.onSave(this.props.subcategorie_id, this.state.subcategorie_name, this.props.subcategorie_name, this.state.ip_address, this.props.ip_address, this.state.printer_description, this.props.printer_description)
            } else {
                this.setState({errorMessage: "Keine Valide IP-Addresse!!"})
            }
        } else {
            this.setState({errorMessage: "Nicht alle Felder ausgefüllt!!"})
        }
    }

    handleChangeIP = (e) => {
        let newIP = new Validations(e.target.value).noSpace().value;
        const isValidIP = validateIPAddress(newIP);
        if (e.target.value !== "") {
            if (!isValidIP) {
                if (!isNaN(e.target.value) || e.target.value.indexOf('.') > -1) {
                    this.setState({ip_address: e.target.value, errorMessage: "Keine Valide IP-Adresse"});
                }
            } else {
                if (!isNaN(e.target.value) || e.target.value.indexOf('.') > -1) {
                    this.setState({ip_address: e.target.value, errorMessage: null});
                }
            }
        } else {
            this.setState({ip_address: "", errorMessage: null})
        }
    }

    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }
}

export default EditSubcategorie;