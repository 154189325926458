import React, {Component} from "react";
import "./adminHomeStyle.css"
import "./Sidebar/sidebar_styles.css"
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Home from "./SubComponents/Home";
import Products from "./SubComponents/Products";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {Redirect} from "react-router-dom";
import DialogProfile from "./SubComponents/SubCompOfEvent/DialogProfile";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const axios = require('axios');
let url;

class AdminHome extends Component {
    state = {
        waiter_name: JSON.parse(localStorage.getItem('user')).username,
        users: [],
        home_open: true,
        users_open: false,
        productsOpen: false,
        menu_open: false,
        loadingGrid: false,
        events: [],
        redirect: false,
        dialogProfileOpen: false,
        redirectToLink: "",
        error_snack: false,
        error_severity: "success",
        error_snack_message: ""
    }

    componentDidMount() {
        url = "http://192.168.1.115/Backend/";

    }


    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        return (<div>
            <AppBar position="static" className={"mb-3"} style={{margin: 0}}>
                <Toolbar>
                    {/*
                    <IconButton edge={"start"} aria-label="sidebar">
                        <Sidebar users={this.openUsers} home={this.openHome} product={this.openProduct}
                                 logout={this.props.logout}/>
                    </IconButton>
                    */}
                    <Typography variant="h6" align={"center"} style={{flexGrow: 1}}>
                        Admin-Verwaltung
                    </Typography>
                    <IconButton edge={"end"} aria-label="edit" id={"avatar"} onClick={this.handleOpenMenu}>
                        <PersonOutlineIcon fontSize={"large"} className={"text-white"}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Snackbar open={this.state.error_snack} autoHideDuration={3000} onClose={this.handleCloseSnack}>
                <Alert onClose={this.handleCloseSnack} variant={"filled"} severity={this.state.error_severity}>
                    {this.state.error_snack_message}
                </Alert>
            </Snackbar>
            <DialogProfile open={this.state.dialogProfileOpen} onClose={this.handleDialogProfile}
                           username={this.props.currentUser.username} onSave={this.onSaveDialogProfile}/>
            <div className={"w-100 h-100 container z-index-menu"}>
                <Popper className={"z-index-menu"} open={this.state.menu_open}
                        anchorEl={document.getElementById('avatar')} role={undefined}
                        transition
                        disablePortal>
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleCLoseMenu}>
                                    <MenuList id="menu-list-grow">
                                        <MenuItem onClick={this.handleDialogProfile}>Profile</MenuItem>
                                        <MenuItem onClick={this.props.logout}>Logout</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <main className={"w-100 pt-5"}
                      style={{
                          "height": "98vh"
                      }}>
                    <Home open={this.state.home_open} addEvent={this.handleAddEvent}/>

                    {/*<User open={this.state.users_open} users={this.state.users} loading={this.state.loadingGrid}/>*/}
                    <Products open={this.state.productsOpen}/>
                </main>
            </div>
        </div>);
    }

    handleCloseSnack = () => {
        this.setState({
            error_snack: !this.state.error_snack
        })
    }
    handleDialogProfile = () => {
        this.setState({
            dialogProfileOpen: !this.state.dialogProfileOpen
        })
    }
    onSaveDialogProfile = (username, password, old_password) => {
        const params = new URLSearchParams();
        params.append("old_password", old_password);
        params.append("new_password", password);
        params.append("username", username);

        axios({
            method: 'post',
            url: url + '?action=changeAdminPassword',
            data: params
        }).then((res) => {
            if (res.data.error) {
                this.setState({
                    error_snack: true,
                    error_severity: "error",
                    error_snack_message: "Eingegebene Passwörter stimmen nicht überein!"
                })
            } else {
                this.setState({
                    error_snack: true,
                    error_severity: "success",
                    error_snack_message: "Änderungen waren Erfolgreich!"
                })
                this.handleDialogProfile();
            }
        });
    }

    handleCLoseMenu = () => {
        this.setState({
            menu_open: false
        })
    }
    backHandler = () => {
        this.setState({
            redirectToLink: "/loggedin/",
            redirect: true
        })
    }


    handleOpenMenu = () => {
        this.setState({
            menu_open: true
        })
    }

    openUsers = () => {
        this.setState({
            home_open: false,
            users_open: true,
            productsOpen: false
        })
    }
    openHome = () => {
        this.setState({
            home_open: true,
            users_open: false,
            productsOpen: false
        })
    }
    openProduct = () => {
        this.setState({
            home_open: false,
            users_open: false,
            productsOpen: true
        })
    }

}

export default AdminHome;