import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Col, Row} from "react-bootstrap";
import Alert from "@material-ui/lab/Alert"
import BackIcon from "@material-ui/icons/ArrowBackIos";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import "./Order.css"
import "./PayOrderScreen.css"
import Dialog from "../../Components/Dialog_Menu";
import WechselgeldCalculator from "../../Components/WechselgeldCalculator";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EuroIcon from '@material-ui/icons/Euro';
import Snackbar from '@material-ui/core/Snackbar';
import {withSnackbar} from 'notistack';
import MenuIcon from "@material-ui/icons/Menu";
import {element} from "prop-types";

let url;

class PayOrderScreen extends Component {
    state = {
        redirect: false,
        waiter_name: JSON.parse(localStorage.getItem('user')).username,
        createGridButtons: [],
        grid_data: [],
        products: [],
        selectedProduct: [],
        order: [],
        toPay: [],
        extras: [],
        loading: false,
        table_nr: 0,
        totalPrice: 0,
        productname: "",
        isOpen: false,
        insertedToPay: 0,
        toPayTotal: 0,
        canPressButton: false,
        errorIsOpen: false,
        isOpenSettings: false,
        message: "",
        message_color: "success",
        order_id: 0,
        lastState: 1,
        printing: false
    }

    componentDidMount() {
        url = "http://192.168.1.115/Backend/";
        this.getOrderById();
    }

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        if (this.state.loading) {
            return (<Backdrop open={true} docked="false">
                <CircularProgress color="secondary"/>
                <h2 className={"text-center ml-3"}>Loading...</h2>
            </Backdrop>);
        }
        return (
            <div className={"w-100 h-100 p-1"}>
                <Snackbar open={this.state.errorIsOpen} autoHideDuration={2500}>
                    <Alert onClose={this.handleCloseSnackbar} variant={"filled"} severity={this.state.message_color}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Row className={"fixed-top text-center bg-navs no_select pt-1 pb-1"}>
                    <Col
                        className={"mt-3 mb-3 fontsize_header col-4 text-center d-flex justify-content-center text-white"}
                        onClick={this.backHome}>
                        <BackIcon/> Back
                    </Col>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>
                        <div
                            className={"waiter_name fontsize_header text-white"}>{this.state.table_nr + " | " + this.state.totalPrice.toFixed(2) + "€"} </div>
                    </Col>
                    <Col className={"mt-3 mb-3 col-4 fontsize text-center d-flex justify-content-center"}
                         onClick={this.handleOpenMenu}>
                        <MenuIcon className={" fontsize_header text-white"}/>
                    </Col>
                </Row>
                <WechselgeldCalculator open={this.state.isOpen} onClose={this.handleClose}
                                       toPayTotal={this.state.toPayTotal} onChange={this.onChangeInput}
                                       onKeyDown={this.onEnterDisabledFocus} onSave={this.savePaySelectedItems}
                                       insertedToPay={this.state.insertedToPay}
                />
                <Dialog open={this.state.isOpenSettings} onClose={this.handleCloseMenu} l1_onClick={this.backHome}
                        l2_onClick={this.toOrderScreen} l3_onClick={this.printOrder}
                        l4_onClick={this.reprintOrder} l5_onClick={this.removeOrder}
                        tryingPrinting={this.state.printing}
                />
                <div className={"h-40 margin-top-div pt-5 d-block p-2 no_select"}>
                    <List subheader={<li/>} className={"no_select"}>
                        {this.state.order.map((c, k) => (
                            <div key={k}>
                                <ListItem key={k} button className={"m-1 p-0"}>
                                    <div className={"mr-2 padding-05"}
                                         onClick={() => this.addAllProductToPayOrderList(c)}>
                                        <span className={"fontsize"}>{c.ToPayAmount}</span>
                                    </div>
                                    <ListItemText onKeyDown={this.onEnterDisabledFocus}
                                                  className={"m-1 p-0"}
                                                  primary={(<span
                                                      className={"fontsize text-body"}
                                                      onClick={() => this.addProductToPayList(c)}>{c.product.name}</span>)}
                                                  secondary={this.checkIfExtrasAndCommentExists(c)}
                                    />
                                    <div className={"text-right padding-05"}
                                         onClick={() => this.addProductToPayList(c)}><span
                                        className={"fontsize"}>{c.extras === undefined ? (c.ToPayAmount * c.product.price).toFixed(2) + "€" : this.getPriceForProductWithExtra(c).toFixed(2) + "€"}</span>
                                    </div>
                                </ListItem>
                                    {k + 1 !== this.state.order.length ? <Divider/> : null}
                                </div>
                            )
                        )}
                    </List>
                </div>
                <div className={"h-60 d-block border_payorderscreen p-2 no_select"}>
                    <List subheader={<li/>} className={"no_select"}>
                        {this.state.toPay.map((c, k) => (
                            <div key={k}>
                                <ListItem key={k} button className={"m-1 p-0"}>
                                    <div className={"mr-2 padding-05"} onClick={() => this.addAllProductToOrderList(c)}>
                                        <span className={"fontsize"}>{c.ToPayAmount}</span>
                                    </div>
                                    <ListItemText
                                        onClick={() => this.addProductToOrderList(c)}
                                        className={"m-1 p-0"}
                                        primary={(<span
                                            className={"fontsize text-body"}>{c.product.name}</span>)}
                                        secondary={this.checkIfExtrasAndCommentExists(c)}
                                    />
                                    <div className={"text-right padding-05"}
                                         onClick={() => this.addProductToOrderList(c)}><span
                                        className={"fontsize"}>{c.extras === undefined ? (c.ToPayAmount * c.product.price).toFixed(2) + "€" : this.getPriceForProductWithExtra(c).toFixed(2) + "€"}</span>
                                    </div>
                                </ListItem>
                                {k + 1 !== this.state.order.length ? <Divider/> : null}
                            </div>))}
                    </List>
                </div>
                <Row className={"fixed-bottom text-center bg-navs pt-1 pb-1 no_select"}>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>
                        <div className={"btn-ripple"}>

                        </div>
                    </Col>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>
                        <ImportExportIcon aria-disabled={"true"} onClick={this.allOrderProductsToPayOrder}
                                           className={"text-white"}/>
                    </Col>
                    <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>
                        <EuroIcon onClick={this.payToPayOrder} className={"text-white"}/>
                    </Col>
                </Row>
            </div>
        )
    }

    reprintOrder = () => {
        let active = localStorage.getItem("kassaModus");
        //console.log(active)
        if (active === "false" || active === false) {
            this.setState({
                printing: true
            })
            const axios = require('axios');

            const params = new URLSearchParams();
            params.append('order_id', this.props.match.params.ordernumber);

            axios({
                method: 'post',
                url: url + '?action=reprintOrder',
                data: params
            }).then((res) => {
                this.setState({
                    printing: false
                })
                let arr = res.data;
                if (arr.length === 0) {
                    this.props.enqueueSnackbar("Die Bestellung wurde erfolgreich gedruckt!", {
                        variant: 'success',
                        anchorOrigin: '{ horizontal: left, vertical: top }',
                        autoHideDuration: 1000
                    });
                } else {
                    if (arr.length !== 0) {
                        arr.forEach(element =>
                            this.props.enqueueSnackbar("Der Drucker " + element.printer.description + " mit der IP " + element.printer.ip_address + " ist nicht erreichbar!", {
                                variant: 'error',
                                anchorOrigin: '{ horizontal: left, vertical: top }',
                                autoHideDuration: 1000
                            }));
                    }
                }
            });
        } else {
            this.printKassaModeOrder();
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            errorIsOpen: false
        })
    }

    checkIfExtrasAndCommentExists = (c) => {
        let arr = [];
        if (c.extras !== undefined) {
            c.extras.map((x, y) => (
                arr.push(<span key={y}
                               className={"comment_text fontsizeExtra text-body"}>{x.description}<br/></span>)))
            if (c.selected.comment !== null) {
                arr.push(<span className={"comment_text fontsizeExtra text-body"}>{c.selected.comment}<br/></span>)
            }
        } else if (c.selected.comment) {
            arr.push(<span className={"comment_text fontsizeExtra text-body"}>{c.selected.comment}<br/></span>)
        }
        if (arr.length !== 0) {
            return arr
        } else {
            return null;
        }
    }

    handleClose = () => {
        this.setState({
            isOpen: false
        })
    }
    handleCloseMenu = () => {
        this.setState({
            isOpenSettings: false
        })
    }
    handleOpenMenu = () => {
        this.setState({
            isOpenSettings: true
        })
    }
    onChangeInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        this.canPressKassieren();
    }
    round = (wert, dez) => {
        wert = parseFloat(wert);
        if (!wert) return 0;
        dez = parseInt(dez);
        if (!dez) dez = 0;

        let umrechnungsfaktor = Math.pow(10, dez);

        return Math.round(wert * umrechnungsfaktor) / umrechnungsfaktor;
    }
    onEnterDisabledFocus = (e) => {
        if (e.key === 'Enter') {
            let help = this.state.insertedToPay - this.state.toPayTotal;
            if (help >= 0) {
                e.target.blur()
            }
            if (this.state.insertedToPay >= this.state.toPayTotal) {
                this.savePaySelectedItems();
            }
        }
    }
    seeIfProductExistsInArray = (product, orderArray) => {
        let isTrue = false;
        if (orderArray !== undefined) {
            for (let i = 0; i < orderArray.length; i++) {
                if (orderArray[i].product !== undefined && orderArray[i].product.id === product.product.id) {
                    let extras = orderArray[i].extras;
                    if (extras !== undefined) {
                        if (product.extras && product.extras.length !== 0) {
                            let extraArr = [];
                            if (product.extras.length === extras.length) {
                                for (let k = 0; k < product.extras.length; k++) {
                                    for (let j = 0; j < extras.length; j++) {
                                        if (product.extras[k].id === extras[j].id) {
                                            extraArr[k] = true;
                                        }
                                    }
                                    if (extraArr[k] !== true) {
                                        extraArr[k] = false;
                                    }
                                }
                                let temp = true;
                                for (let j = 0; j < extraArr.length; j++) {
                                    if (extraArr[j] === false) {
                                        temp = false;
                                    }
                                }
                                if (temp === true) {
                                    isTrue = true;
                                    break;
                                }
                            } else {
                                isTrue = false;
                            }
                        }
                    } else {
                        if (!product.extras) {
                            isTrue = true;
                            break;
                        } else {
                            isTrue = false;
                        }
                    }
                }

            }
        }
        return isTrue;
    }
    findIndexOfProductInArray = (product, orderArr) => {
        let index = -1;
        if (orderArr !== undefined) {
            for (let i = 0; i < orderArr.length; i++) {
                if (orderArr[i].product !== undefined && orderArr[i].product.id === product.product.id) {
                    let extras = orderArr[i].extras;
                    if (extras !== undefined) {
                        if (product.extras && product.extras.length !== 0) {
                            let extraArr = [];
                            if (product.extras.length === extras.length) {
                                for (let k = 0; k < product.extras.length; k++) {
                                    for (let j = 0; j < extras.length; j++) {
                                        if (product.extras[k].id === extras[j].id) {
                                            extraArr[k] = true;
                                        }
                                    }
                                    if (extraArr[k] !== true) {
                                        extraArr[k] = false;
                                    }
                                }
                                let temp = true;
                                for (let j = 0; j < extraArr.length; j++) {
                                    if (extraArr[j] === false) {
                                        temp = false;
                                    }
                                }
                                if (temp === true) {
                                    index = i;
                                    break;
                                }

                            } else {
                                index = i;
                            }
                        }
                    } else {
                        if (!product.extras) {
                            index = i;
                            break;
                        }
                    }
                }
            }
        }
        return index;
    }
    addProductToOrderList = (data) => {
        let orderToPay = this.state.toPay;
        let order = this.state.order;
        if (data.product.id) {
            if (this.seeIfProductExistsInArray(data, order)) {
                let order_data = this.findIndexOfProductInArray(data, order);
                if (this.seeIfProductExistsInArray(data, orderToPay)) {
                    let payOrder_data = this.findIndexOfProductInArray(data, orderToPay);
                    if (payOrder_data !== -1) {
                        if (orderToPay[payOrder_data].ToPayAmount <= 1) {
                            orderToPay.splice(payOrder_data, 1);
                        } else {
                            orderToPay[payOrder_data].ToPayAmount -= 1;
                        }
                    }
                    if (order_data !== -1) {
                        order[order_data].ToPayAmount += 1;
                    }
                } else {
                    if (order_data !== -1) {
                        order[order_data].ToPayAmount += 1;
                    }
                }
            } else {
                if (!data.extras) {
                    if (this.seeIfProductExistsInArray(data, orderToPay)) {
                        let payOrder_data = this.findIndexOfProductInArray(data, orderToPay);
                        if (payOrder_data !== -1) {
                            if (orderToPay[payOrder_data].ToPayAmount <= 1) {
                                orderToPay.splice(payOrder_data, 1);
                            } else {
                                orderToPay[payOrder_data].ToPayAmount -= 1;
                            }
                        }
                    }
                    order.push(
                        {
                            product: data.product,
                            ToPayAmount: 1,
                            selected: data.selected
                        }
                    )
                } else {
                    if (this.seeIfProductExistsInArray(data, orderToPay)) {
                        let payOrder_data = this.findIndexOfProductInArray(data, orderToPay);
                        if (payOrder_data !== -1) {
                            if (orderToPay[payOrder_data].ToPayAmount <= 1) {
                                orderToPay.splice(payOrder_data, 1);
                            } else {
                                orderToPay[payOrder_data].ToPayAmount -= 1;
                            }
                        }
                    }
                    order.push(
                        {
                            product: data.product,
                            ToPayAmount: 1,
                            selected: data.selected,
                            extras: data.extras
                        }
                    )
                }
            }
            this.setState({
                order: order
            })
            this.getTotalPriceOfOrder()
        }
    }
    addAllProductToOrderList = (data) => {
        let orderToPay = this.state.toPay;
        let order = this.state.order;
        if (data.product.id) {
            if (this.seeIfProductExistsInArray(data, order)) {
                let order_data = this.findIndexOfProductInArray(data, order);
                if (this.seeIfProductExistsInArray(data, orderToPay)) {
                    let payOrder_data = this.findIndexOfProductInArray(data, orderToPay);
                    if (payOrder_data !== -1) {
                        let ToPayAmountPayOrderList = orderToPay[payOrder_data].ToPayAmount;
                        orderToPay.splice(payOrder_data, 1);

                        if (order_data !== -1) {
                            order[order_data].ToPayAmount += ToPayAmountPayOrderList;
                        }
                    }
                }
            } else {
                if (!data.extras) {
                    if (this.seeIfProductExistsInArray(data, orderToPay)) {
                        let payOrder_data = this.findIndexOfProductInArray(data, orderToPay);
                        let ToPayAmount = orderToPay[payOrder_data].ToPayAmount;
                        if (payOrder_data !== -1) {
                            orderToPay.splice(payOrder_data, 1);
                        }
                        order.push(
                            {
                                product: data.product,
                                ToPayAmount: ToPayAmount,
                                selected: data.selected
                            }
                        )
                    }
                } else {
                    if (this.seeIfProductExistsInArray(data, orderToPay)) {
                        let payOrder_data = this.findIndexOfProductInArray(data, orderToPay);
                        let ToPayAmount = orderToPay[payOrder_data].ToPayAmount;
                        if (payOrder_data !== -1) {
                            orderToPay.splice(payOrder_data, 1);
                        }
                        order.push(
                            {
                                product: data.product,
                                ToPayAmount: ToPayAmount,
                                selected: data.selected,
                                extras: data.extras
                            }
                        )
                    }
                }
            }
            this.setState({
                order: order
            })
            this.getTotalPriceOfOrder()
        }
    }
    addAllProductToPayOrderList = (data) => {
        let orderToPay = this.state.toPay;
        let order = this.state.order;
        if (data.product.id) {
            if (this.seeIfProductExistsInArray(data, order)) {
                let order_data = this.findIndexOfProductInArray(data, orderToPay);
                if (this.seeIfProductExistsInArray(data, orderToPay)) {
                    let payOrder_data = this.findIndexOfProductInArray(data, order);
                    if (payOrder_data !== -1) {
                        let ToPayAmountPayOrderList = order[payOrder_data].ToPayAmount;
                        order.splice(payOrder_data, 1);
                        if (order_data !== -1) {
                            orderToPay[order_data].ToPayAmount += ToPayAmountPayOrderList;
                        }
                    }
                } else {
                    if (!data.extras) {
                        if (this.seeIfProductExistsInArray(data, order)) {
                            let payOrder_data = this.findIndexOfProductInArray(data, order);
                            let ToPayAmount = order[payOrder_data].ToPayAmount;
                            if (payOrder_data !== -1) {
                                order.splice(payOrder_data, 1);
                            }
                            orderToPay.push(
                                {
                                    product: data.product,
                                    ToPayAmount: ToPayAmount,
                                    selected: data.selected
                                }
                            )
                        }
                    } else {
                        if (this.seeIfProductExistsInArray(data, order)) {
                            let payOrder_data = this.findIndexOfProductInArray(data, order);
                            let ToPayAmount = order[payOrder_data].ToPayAmount;
                            if (payOrder_data !== -1) {
                                order.splice(payOrder_data, 1);
                            }
                            orderToPay.push(
                                {
                                    product: data.product,
                                    ToPayAmount: ToPayAmount,
                                    selected: data.selected,
                                    extras: data.extras
                                }
                            )
                        }
                    }
                }
                this.setState({
                    toPay: orderToPay
                })
                this.getTotalPriceOfOrder()
            }
        }
    }
    payToPayOrder = () => {
        if (this.state.toPay.length !== 0) {
            this.canPressKassieren();
            this.setState({
                isOpen: true,
                toPayTotal: this.getTotalOfSelectedProducts()
            })
        } else {
            this.setState({
                errorIsOpen: true,
                message: "Keine Produkte zum Bezahlen ausgewählt",
                message_color: "error"
            })

        }
    }
    getSelectedIdFromToPay = () => {
        let toPay = this.state.toPay;
        let toPaySelectedIds = []
        for (let i = 0; i < toPay.length; i++) {
            toPaySelectedIds.push({
                "selectedId": toPay[i].selected.selected_id,
                "amount": toPay[i].ToPayAmount
            })
        }
        return toPaySelectedIds;
    }
    canPressKassieren = () => {
        let state = false;
        if (this.state.insertedToPay !== 0) {
            if (this.state.insertedToPay >= this.state.toPayTotal) {
                state = true
            }
        } else {
            state = false;
        }
        this.setState({
            canPressButton: state
        })
    }
    getTotalOfSelectedProducts = () => {
        let orders = this.state.toPay;
        let total = 0;
        for (let i = 0; i < orders.length; i++) {
            if (orders[i].extras) {
                total += this.getPriceForProductWithExtra(orders[i]);
            } else {
                total += parseFloat(orders[i].product.price) * orders[i].ToPayAmount;
            }
        }
        return total;
    }
    addProductToPayList = (data) => {
        let orderToPay = this.state.toPay;
        let order = this.state.order;
        if (data.product.id) {
            if (this.seeIfProductExistsInArray(data, orderToPay)) {
                let order_data = this.findIndexOfProductInArray(data, orderToPay);
                if (this.seeIfProductExistsInArray(data, order)) {
                    let payOrder_data = this.findIndexOfProductInArray(data, order);
                    if (payOrder_data !== -1) {
                        if (order[payOrder_data].ToPayAmount <= 1) {
                            order.splice(payOrder_data, 1);
                        } else {
                            order[payOrder_data].ToPayAmount -= 1;
                        }
                    }
                    if (order_data !== -1) {
                        orderToPay[order_data].ToPayAmount += 1;
                    }
                } else {
                    if (order_data !== -1) {
                        orderToPay[order_data].ToPayAmount += 1;
                    }
                }
            } else {
                if (!data.extras) {
                    if (this.seeIfProductExistsInArray(data, order)) {
                        let payOrder_data = this.findIndexOfProductInArray(data, order);
                        if (payOrder_data !== -1) {
                            if (order[payOrder_data].ToPayAmount <= 1) {
                                order.splice(payOrder_data, 1);
                            } else {
                                order[payOrder_data].ToPayAmount -= 1;
                            }
                        }
                    }
                    orderToPay.push(
                        {
                            product: data.product,
                            ToPayAmount: 1,
                            selected: data.selected
                        }
                    )
                } else {
                    if (this.seeIfProductExistsInArray(data, order)) {
                        let payOrder_data = this.findIndexOfProductInArray(data, order);
                        if (payOrder_data !== -1) {
                            if (order[payOrder_data].ToPayAmount <= 1) {
                                order.splice(payOrder_data, 1);
                            } else {
                                order[payOrder_data].ToPayAmount -= 1;
                            }
                        }
                    }
                    orderToPay.push(
                        {
                            product: data.product,
                            ToPayAmount: 1,
                            selected: data.selected,
                            extras: data.extras
                        }
                    )
                }
            }
            this.setState({
                toPay: orderToPay
            })
            this.getTotalPriceOfOrder()
        }
    }
    getOrderById = () => {
    this.setState({loading: true})
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('orderId', this.props.match.params.ordernumber);

        axios({
            method: 'post',
            url: url + '?action=getProductsThatNeedToBePaid',
            data: params
        }).then((res) => {
            this.setState({order: res.data.products, table_nr: res.data.order.tablenumber, loading: false})
            this.getTotalPriceOfOrder();
        });
    }
    savePaySelectedItems = () => {
            let items = this.getSelectedIdFromToPay();
            this.sendPaySelectedItems(items);
            this.handleClose();
            this.setState({
                toPay: []
            })
    }
    sendPaySelectedItems = (items) => {

        const axios = require('axios');

        const params = new URLSearchParams();

        params.append('selectedIds', JSON.stringify(items));
        params.append('order_id', this.props.match.params.ordernumber)

        axios({
            method: 'post',
            url: url + '?action=paySelectedItem',
            data: params
        }).then((res) => {
            let active = localStorage.getItem("kassaModus");
            console.log(active);
            if (res.data.order) {
                if (active === "true" || active === true) {
                    this.openToOrder();
                }else{
                    this.backHome();
                }
                
            }
        });
    }
    getTotalPriceOfOrder = () => {
        let total = 0;
        let orders = this.state.order;
        for (let i = 0; i < orders.length; i++) {
            if (orders[i].extras) {
                total += this.getPriceForProductWithExtra(orders[i]);
            } else {
                total += parseFloat(orders[i].product.price) * orders[i].ToPayAmount;
            }
        }
        this.setState({
            totalPrice: total
        })
    }
    getPriceForProductWithExtra = (product) => {
        let totalForProduct = parseFloat(product.product.price);
        for (let i = 0; i < product.extras.length; i++) {
            totalForProduct += parseFloat(product.extras[i].price);
        }
        totalForProduct = totalForProduct * parseInt(product.ToPayAmount);
        return totalForProduct;
    }
    allOrderProductsToPayOrder = () => {
        if (this.state.lastState === 1) {
            let item = this.checkIfArrIsEmptyReturn(this.state.toPay)
            this.setState({
                toPay: this.state.order,
                order: [],
                lastState: 0
            }, () => {
                if (item.length !== 0) {
                    let order = this.state.toPay;
                    item.forEach(element => {
                        let index = order.findIndex(e=> e.selected.selected_id === element.selected.selected_id );
                        if(index !== -1){
                            order[index].ToPayAmount += element.ToPayAmount
                        }else {
                            order.push(element)
                        }

                    });
                    this.setState({
                        toPay: order
                    }, this.getTotalPriceOfOrder());
                }
            });
        } else {
            let item = this.checkIfArrIsEmptyReturn(this.state.order)
            this.setState({
                order: this.state.toPay,
                toPay: [],
                lastState: 1
            }, () => {
                if (item.length !== 0) {
                    let order = this.state.order;
                    item.forEach(element => {
                        let index = order.findIndex(e=> e.selected.selected_id === element.selected.selected_id );
                        if(index !== -1){
                            order[index].ToPayAmount += element.ToPayAmount
                        }else {
                            order.push(element)
                        }
                    });
                    this.setState({
                        order: order
                    }, this.getTotalPriceOfOrder());

                }
            });
        }

    }
    checkIfArrIsEmptyReturn = (arr) => {

        let items = [];
        if (arr && arr.length !== 0) {
            arr.forEach(element => {
                    items.push(element)
                }
            )
        }
        return items;
    }
    backHome = () => {
        let active = localStorage.getItem("kassaModus");
        console.log(active);
        if (active !== "true" || active !== true) {
        if (this.props.match.params.searchInput) {
            this.setState({
                redirectToLink: "/loggedin/SearchOrders/" + this.props.match.params.searchInput,
                redirect: true
            })
        } else {
                this.setState({
                    redirectToLink: "/loggedin/Home/",
                    redirect: true
                })
        }
    }
}
   
    openToOrder = () => {
        let tablenr = 1;
            this.setState({
                redirectToLink: "/loggedin/Order/" + tablenr,
                redirect: true
            })
        }
    toOrderScreen = () => {
        this.setState({
            redirectToLink: "/loggedin/OrderMore/" + this.props.match.params.ordernumber,
            redirect: true
        })
    }
    printOrder = () => {
        let active = localStorage.getItem("kassaModus");
        if (active === "false" || active === false || !active) {
            this.setState({
                printing: true
            })
            const axios = require('axios');

            const params = new URLSearchParams();
            params.append('order_id', this.props.match.params.ordernumber);

            axios({
                method: 'post',
                url: url + '?action=printOrderREST',
                data: params
            }).then((res) => {
                this.setState({
                    printing: false
                })
                let arr = res.data;
                if (arr.length === 0) {
                    this.props.enqueueSnackbar("Die Bestellung wurde erfolgreich gedruckt!", {
                        variant: 'success',
                        autoHideDuration: 1000
                    });
                } else {
                    if (arr.length !== 0) {
                        arr.forEach(element =>
                            this.props.enqueueSnackbar("Der Drucker " + element.printer.description + " mit der IP " + element.printer.ip_address + " ist nicht erreichbar!", {
                                variant: 'error',
                                autoHideDuration: 1000
                            }));
                    }
                }
            });
        }else {
            this.printKassaModeOrder();
        }
    }
    removeOrder = () => {
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('orderId', this.props.match.params.ordernumber);

        axios({
            method: 'post',
            url: url + '?action=deleteOrder',
            data: params
        }).then((res) => {
            this.backHome();
        })
    }
    printKassaModeOrder = () => {
        this.setState({
            printing: true
        })
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('order_id', this.props.match.params.ordernumber);
        params.append('printer_ip', localStorage.getItem("kassaPrinter"));
        axios({
            method: 'post',
            url: url + '?action=printOrderKassaREST',
            data: params
        }).then((res) => {
            this.setState({
                printing: false
            })
            let arr = res.data;
            if (arr.length === 0) {
                this.props.enqueueSnackbar("Die Bestellung wurde erfolgreich gedruckt!", {
                    variant: 'success',
                    autoHideDuration: 1000
                });
            } else {
                if (arr.length !== 0) {
                    this.props.enqueueSnackbar("Der Drucker mit der IP " + localStorage.getItem("kassaPrinter") + " ist nicht erreichbar!", {
                        variant: 'error',
                        autoHideDuration: 1000
                    });
                }
            }
        });
    }
}


export default withSnackbar(PayOrderScreen);

