import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputAdornment from "@material-ui/core/InputAdornment";
import {withStyles} from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";

const stylesFn = () => ({
    focused: {
        "background-color": "white"
    }
});

class WechselgeldCalculator extends Component {
    render() {
        return (
            <Dialog open={this.props.open} className={"no_select"} fullWidth={true} maxWidth={'xs'}
                    onClose={this.props.onClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Wechselgeldrechner</DialogTitle>
                <DialogContent>
                    <div className={"w-100 text-center d-flex justify-content-center"}>
                        <List id={"wechselgeld"}>
                            <ListItem>
                                <ListItemText primary={"Zu bezahlen: " + this.props.toPayTotal + "€"}/>
                            </ListItem>
                            <ListItem button>
                                <TextField
                                    variant={"outlined"}
                                    type={"number"}
                                    className={"input_width_order w-100"} name={"insertedToPay"}
                                    autoFocus={true}
                                    onChange={this.props.onChange}
                                    onKeyDown={this.props.onKeyDown} data-mini="true"
                                    placeholder={this.props.toPayTotal + "€"}
                                    endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    InputProps={{
                                        classes: {
                                            focused: this.props.classes.focused,
                                            hover: this.props.classes.focused,
                                            active: this.props.classes.focused
                                        },
                                    }}
                                    labelWidth={0}
                                />

                            </ListItem>
                            <ListItem>
                                <b><ListItemText primary={"Zurück: " + this.getBackIfEnteredToWechselGeld()}/></b>
                            </ListItem>
                        </List>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={this.props.onSave}
                            color="primary">
                        Kassieren
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    getBackIfEnteredToWechselGeld = () => {
        if (this.props.insertedToPay !== 0) {
            let help = this.props.insertedToPay - this.props.toPayTotal;
            if (help >= 0) {
                return Math.round((help + Number.EPSILON) * 100) / 100 + "€"
            } else {
                return "Zu wenig"
            }

        } else {
            return "?"
        }
    }
}

export default withStyles(stylesFn)(WechselgeldCalculator);