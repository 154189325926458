import React, {Component} from 'react';
import "./SearchOrders.css"
import {Col, Row} from "react-bootstrap";
import SettingsIcon from "@material-ui/icons/Settings";
import InfiniteScroll from "react-infinite-scroll-component";
import {Divider} from "@material-ui/core";
import FinishedIcon from "@material-ui/icons/CheckCircleOutline";
import EuroIcon from "@material-ui/icons/Euro";
import PrintIcon from "@material-ui/icons/Print";
import {Redirect} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ScrollToTop from "react-scroll-to-top";

let url;
let longtouch;
let timer;
let touchduration = 500;

class SearchOrders extends Component {

    state = {
        orders: [],
        offset: 0,
        limit: 20,
        canLoad: true,
        redirect: false,
        redirectToLink: "",
        loading: false
    }

    componentDidMount() {
        url = localStorage.getItem('url');
        this.setState({loading: true})
        this.getAllOrdersBySearchInput();
    }

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirectToLink}/>;
        }
        if (this.state.loading) {
            return (<Backdrop open={true} docked="false">
                <CircularProgress color="secondary"/>
                <h2 className={"text-center ml-3"}>Loading...</h2>
            </Backdrop>);
        }
        return (<div className={"w-100 h-100 container"}>
            <Row className={"fixed-top text-center bg-navs pt-1 pb-1"}>
                <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center text-white"}
                     onClick={this.backHome}>
                    <BackIcon/> Back
                </Col>
                <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>
                </Col>
                <Col className={"mt-3 mb-3 col-4 text-center d-flex justify-content-center"}>
                </Col>
            </Row>
            <ScrollToTop smooth component={<ArrowUpwardIcon/>}/>
            <div className={"container mt-5 mb-5 pb-3 pt-3 w-100"}>
                <div className={"mt-2 mb-2"}>
                    <InfiniteScroll
                        dataLength={this.state.orders.length} //This is important field to render the next data
                        next={this.getAllOrdersBySearchInput}
                        hasMore={this.state.canLoad}
                        loader={<div/>}
                        endMessage={
                            <p style={{textAlign: 'center'}}>
                                <b>Keine weiteren Bestellung gefunden!</b>
                            </p>
                        }
                    >
                        {
                            this.state.orders.length !== 0 ? this.state.orders.map((c, k) => (
                                <div key={k} className={"w-100"}>
                                    <Row className={"mt-2 mb-2 ml-1 mr-1 justify-content-center"}>
                                        <Col className={"col-1 my-auto "} onClick={() => {
                                            parseInt(c.notPaid) !== 0 ? this.openPayOrder(c.order.id) : this.openViewToOrder(c.order.id)
                                        }}>
                                            {this.getIconforState(c.state.description)}
                                        </Col>
                                        <Col className={"col-6"} onClick={() => {
                                            parseInt(c.notPaid) !== 0 ? this.openPayOrder(c.order.id) : this.openViewToOrder(c.order.id)
                                        }}>
                                            <h5>Tisch {c.order.tablenumber}</h5>
                                            <h6> {c.user.name}</h6>
                                        </Col>
                                        <Col className={"col-xs-auto my-auto "} onClick={() => {
                                            this.openViewToOrder(c.order.id)
                                        }}>
                                            <div className="text-right">
                                                <h5>{parseInt(c.notPaid) !== 0 ? c.notPaid + "€ | " + c.price + "€" : c.price + "€"}</h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    {k + 1 !== this.state.orders.length ? <Divider/> : null}
                                </div>
                            )) : <h5>Noch keine Bestellung gemacht</h5>}
                    </InfiniteScroll>
                </div>
            </div>
        </div>)
    }

    backHome = () => {
        this.setState({
            redirectToLink: "/loggedin/Home/",
            redirect: true
        })
    }
    openPayOrder = (id) => {
        this.setState({
            redirectToLink: "/loggedin/PayOrder/" + id + "/" + this.props.match.params.searchInput,
            redirect: true
        })
    }
    openViewToOrder = (id) => {
        this.setState({
            redirectToLink: "/loggedin/ViewOrder/" + id + "/" + this.props.match.params.searchInput,
            redirect: true
        })
    }
    touchstart = (e) => {
        e.preventDefault();
        longtouch = false;

        timer = setTimeout(function () {
            longtouch = true;
            timer = null
        }, touchduration);
    }
    touchend = (order) => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        if (longtouch) {
            if (parseInt(order.state_id) !== 1 && parseInt(order.state_id) !== 3) {
                this.openPayOrder(order.id)
            } else {
                this.setState({
                    messageOpen: true,
                    message: "Diese Bestellung wurde bereits bezahlt!!",
                    message_color: "info"
                })
            }
            longtouch = false;
        } else {
            this.openViewToOrder(order.id);
        }
    }


    getIconforState = (input) => {
        let help = null;
        if (input === "Bezahlt") {
            help = (<EuroIcon style={{color: 'green'}}/>);
        } else if (input === "Gedruckt") {
            help = (<PrintIcon style={{color: 'green'}}/>);
        } else if (input === "Fertig") {
            help = (<FinishedIcon style={{color: 'green'}}/>);
        } else if (input === "Erstellt") {
            help = (<EuroIcon style={{color: 'red'}}/>);
        } else if (input === "Wird Gedruckt") {
            help = (<PrintIcon style={{color: 'black'}}/>);
        } else if (input === "Wartet auf Drucker") {
            help = (<PrintIcon style={{color: 'black'}}/>);
        } else if (input === "Problem beim Drucken") {
            help = (<PrintIcon style={{color: 'red'}}/>);
        }
        return help;
    }

    getAllOrdersBySearchInput = () => {
        const axios = require('axios');

        const params = new URLSearchParams();
        params.append('limit', this.state.limit);
        let search = ""
        try {
            if (this.props.match.params.searchInput) {
                search = this.props.match.params.searchInput;
            } else {

            }
        } catch (error) {

        }

        params.append('searchinput', search);
        params.append('offset', this.state.offset);

        axios({
            method: 'post',
            url: url + '?action=searchForOrdersWithLimitAndOffset',
            data: params
        }).then((res) => {
            let arr = res.data.orders;
            if (arr === undefined || res.data.error === "#717") {
                arr = [];
            }
            let canScroll = true;
            if (arr.length < this.state.limit) {
                canScroll = false;
            }
            this.setState({
                orders: this.state.orders.concat(arr),
                offset: res.data.offset,
                loading: false,
                canLoad: canScroll
            })
        })
    }
}

export default SearchOrders;